import { differenceInMinutes, isAfter } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { I18n } from 'i18n/types'

export const getDateDistance = (i18n: I18n, ref: Date, d: string, timezoneSite: string) => {
  const date = new Date(d)

  const zonedCurrentDate = utcToZonedTime(ref, timezoneSite)
  const zonedTransportDate = utcToZonedTime(date, timezoneSite)

  const minuteDiff = differenceInMinutes(zonedTransportDate, zonedCurrentDate)

  if (Math.abs(minuteDiff) < 1) {
    return i18n.t('screens.transport.now')
  }

  if (Math.abs(minuteDiff) < 2 && minuteDiff > 0) {
    return i18n.t('screens.transport.next')
  }

  if (minuteDiff >= 2) {
    if (minuteDiff < 60) {
      return i18n.t('screens.transport.duration', { count: minuteDiff })
    } else {
      const hours = Math.floor(minuteDiff / 60)
      const minutes = minuteDiff % 60
      if (minutes === 0) {
        return i18n.t('screens.transport.durationHours', { hours })
      }
      return i18n.t('screens.transport.durationHourMinutes', { hours, minutes })
    }
  }

  return ''
}

export const getDistances = (i18n: I18n, current: Date, timezoneSite: string, station?: TransportStation) =>
  station
    ? station.schedules
        .filter(Boolean)
        .slice(0, 5)
        .sort((a, b) => {
          const date1 = new Date(a.time)
          const date2 = new Date(b.time)
          return isAfter(date1, date2) ? 1 : -1
        })
        .map((s) => getDateDistance(i18n, current, s.time, timezoneSite))
        .filter(Boolean)
    : []
