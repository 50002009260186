import * as React from 'react'
import styled from 'theme/styled-components'
import Theme from 'theme/Theme'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import api from 'intervention/api'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import Loader from 'react-loader-spinner'
import { findLevelWording, sortedFloors } from '../utils'

interface Props {
  buildingMode?: boolean
  setSelectedFloor: (l: APILocation) => void
  buildingSelected?: APILocation
  goBack?: () => void
}

const FloorSelector = ({ setSelectedFloor, buildingMode, buildingSelected, goBack }: Props) => {
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)

  const [floors, setFloors] = React.useState<APILocation[]>([])
  const [buildingsList, setBuildingsList] = React.useState<string[]>([])
  const [researchStatus, setResearchStatus] = React.useState<'loading' | 'error' | 'ok'>('ok')

  const hasMultipleBuildings = React.useMemo(() => Array.from(new Set(floors.map((f) => f.buildingId))).length > 1, [
    floors,
  ])

  const filteredFloors = React.useMemo(
    () => (!!buildingSelected ? floors.filter((f) => f.buildingId === buildingSelected.buildingId) : floors),
    [floors, buildingSelected]
  )

  React.useEffect(() => {
    if (user && site) {
      setResearchStatus('loading')
      api
        .getAllFloorLocations(site.id, user.type)
        .then((res) => {
          setFloors(sortedFloors(res.locations))
          const buildingsList = Array.from(new Set(res.locations.map((f) => f.buildingId)))
          setBuildingsList(buildingsList)
          setResearchStatus('ok')
        })
        .catch((err) => {
          setResearchStatus('error')
          Logger.error(err)
        })
    }
  }, [])

  const selectFloor = (floor: APILocation) => {
    setSelectedFloor(floor)
    Modal.close()
  }

  const renderFloor = (floor: APILocation, index: number, list: APILocation[]) => {
    const isLast = list.length === index + 1

    return (
      <FloorContainer>
        <FloorText onClick={() => selectFloor(floor)}>
          {hasMultipleBuildings && !buildingSelected && !!floor.buildingName && `${floor.buildingName} - `}
          {findLevelWording(floor.level, i18n, floor.levelCustomName)}
        </FloorText>
        {!isLast && <BlueLineBreak role="presentation" />}
      </FloorContainer>
    )
  }

  const renderBuilding = (buildingId: string, index: number) => {
    const isLast = buildingsList.length === index + 1
    const findLocation = floors.find((f) => f.buildingId === buildingId)

    if (!findLocation) {
      return null
    }

    return (
      <FloorContainer>
        <FloorText onClick={() => selectFloor(findLocation)}>{findLocation.buildingName}</FloorText>
        {!isLast && <BlueLineBreak role="presentation" />}
      </FloorContainer>
    )
  }

  return (
    <MainContainer>
      <TitleContainer>
        {!!goBack && (
          <CrossContainer
            onClick={() => {
              goBack()
              Modal.close()
            }}>
            <Icons name="chevron_left" size={25} color={Theme.colors.blue} />
          </CrossContainer>
        )}

        <Title>{i18n.t(`screens.incident.form.which${buildingMode ? 'Building' : 'Floor'}`)}</Title>

        <CrossContainer onClick={() => Modal.close()} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" size={25} color={Theme.colors.blue} />
        </CrossContainer>
      </TitleContainer>

      <ListContainer>
        {researchStatus === 'loading' ? (
          <LoaderContainer>
            <Loader type="TailSpin" color={Theme.colors.blue} />
          </LoaderContainer>
        ) : researchStatus === 'ok' ? (
          buildingMode && buildingsList.length > 0 ? (
            <CategoryListContainer>{buildingsList.map(renderBuilding)}</CategoryListContainer>
          ) : filteredFloors.length > 0 ? (
            <CategoryListContainer>{filteredFloors.map(renderFloor)}</CategoryListContainer>
          ) : (
            <ErrorMessageContainer>
              <ErrorMessage>{i18n.t('errors.incident.noResult')}</ErrorMessage>
            </ErrorMessageContainer>
          )
        ) : (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('errors.incident.searchError')}</ErrorMessage>
          </ErrorMessageContainer>
        )}
      </ListContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  width: 444px;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 90vw;
  }
`
const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px 30px 10px;
  }
`

const CrossContainer = styled('button')`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`
const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 40px 40px;
  overflow-y: scroll;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px 30px;
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ErrorMessageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
`
const CategoryListContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 0px;
`
const FloorContainer = styled('li')`
  flex: 1;
  justify-content: center;
  list-style: none;
`

// TEXTES

const Title = styled('h1')`
  display: flex;
  flex: 1;
  margin: 0px;
  ${(props) => props.theme.fonts.title};
  overflow-wrap: anywhere;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`
const FloorText = styled('h2')`
  cursor: pointer;
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
  margin: 15px 0px;
`

export default FloorSelector
