import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'
import OccupationComponent from './OccupationComponent'

import api from '../api/restaurantsApi'

import useReducer from 'store/useReducer'
import * as UserStore from 'store/user/user'
import * as SitesStore from 'sites/store/siteStore'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import { isWithinInterval } from 'date-fns'
import equal from 'fast-deep-equal'
import { windowSizeBreakpoints } from 'utils/breakpoints'

interface Props {
  navigation: Navigation
}

const RestaurantHomeContent = ({ navigation }: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()

  const now = new Date()

  const user = useReducer(UserStore.store, (s) => s.user)
  const currentSite = useReducer(SitesStore.store, (s) => s.site)
  const [restaurants, setRestaurants] = React.useState<Restaurant[]>([])

  const [openedRestaurants, setOpenedRestaurants] = React.useState<Restaurant[]>([])

  const fetchRestaurants = () => {
    if (!!currentSite && !!user) {
      api
        .getAllRestaurants(user.type, currentSite.id)
        .then((res) => setRestaurants(res.restaurants))
        .catch(Logger.error)
    }
  }

  React.useEffect(() => {
    const newOpenedRestaurants = restaurants.filter((r) => {
      const start = new Date(i18n.t('screens.catering.openingTimes.dateFormat', { date: now }) + 'T' + r.openingTime)
      const end = new Date(i18n.t('screens.catering.openingTimes.dateFormat', { date: now }) + 'T' + r.closingTime)

      return (
        isWithinInterval(new Date(), { start, end }) &&
        r.status === 'OPEN' &&
        r.currentCapacity !== undefined &&
        !!r.maxCapacity &&
        r.currentCapacity >= 0 &&
        r.maxCapacity > 0
      )
    })

    if (!equal(openedRestaurants, newOpenedRestaurants)) {
      setOpenedRestaurants(newOpenedRestaurants)
    }
  }, [restaurants])

  React.useEffect(() => {
    const interval_time = 1000 * 60

    fetchRestaurants()

    // Pour mettre à jour la liste des restaurants ouverts chaque minute pour trigger un rerender quand il y en a qui ouvrent ou ferment
    const interval = setInterval(fetchRestaurants, interval_time)

    // Clear the interval when the component unmounts
    return () => clearInterval(interval)
  }, [])

  const renderRestaurantItem = (item: Restaurant) => {
    const { name, status, openingTime, closingTime, restaurantType } = item

    const openingDate = new Date(i18n.t('screens.restaurants.dateFormat', { date: now }) + 'T' + openingTime)
    const closingDate = new Date(i18n.t('screens.restaurants.dateFormat', { date: now }) + 'T' + closingTime)

    const accessibilityLabel = i18n.t(
      `accessibility.restaurant.${restaurantType === 'RESTAURANT' ? 'restaurant' : 'cafetaria'}Details`,
      { name, status: i18n.t(`screens.restaurants.${status}`), openingDate, closingDate }
    )

    return (
      <RestaurantContainer aria-label={accessibilityLabel} onClick={() => navigation.push(`/catering/${item.id}`)}>
        <RestaurantContentContainer>
          <RestaurantLeftContainer>
            <ItemTitle>{name}</ItemTitle>
            <StatusContainer>
              <IconContainer>
                <StatusDot />
              </IconContainer>
              <StatusText>
                {i18n.t(`screens.catering.openingTimes.cafeteria`, { time1: openingTime, time2: closingTime })}
              </StatusText>
            </StatusContainer>
          </RestaurantLeftContainer>
          {(!!item.currentCapacity || item.currentCapacity === 0) && item.currentCapacity !== -1 && !!item.maxCapacity && (
            <RestaurantRightContainer>
              <OccupationComponent currentCapacity={item.currentCapacity} maxCapacity={item.maxCapacity} />
              <StatusContainer>
                <IconContainer>
                  <Icons name="users-wm" color={theme.colors.blue} size={16} />
                </IconContainer>
                <OccupationContainer>
                  <OccupationText>{`${item.currentCapacity}/${item.maxCapacity}`}</OccupationText>
                </OccupationContainer>
              </StatusContainer>
            </RestaurantRightContainer>
          )}
        </RestaurantContentContainer>
        <Icons name="chevron_right" size={20} />
      </RestaurantContainer>
    )
  }

  if (openedRestaurants.length === 0) {
    return null
  }

  return (
    <Container>
      <Separator />
      <MainContainer>{openedRestaurants.map((r) => renderRestaurantItem(r))}</MainContainer>
    </Container>
  )
}

export default RestaurantHomeContent

const Container = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
`

const MainContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
`

const Separator = styled('div')`
  height: 3px;
  width: 50px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.blue};
`

const RestaurantContainer = styled('button')`
  display: flex;
  flex: 0 0.5 calc(((100% - 40px) / 3));
  min-width: 500px;
  ${(props) => props.theme.constants.shadow.card};
  elevation: 4;
  shadow-offset: 0px 0px;
  background-color: ${(props) => props.theme.colors.white};
  flex-direction: row;
  padding: 20px 40px;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    min-width: auto;
    flex: 1 1 100%;
  }
`

const RestaurantContentContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 2px;
`

const ItemTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 2px;
`

const StatusContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`

const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
`

const StatusDot = styled('div')`
  height: 5px;
  width: 5px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.turquoise};
  margin: 0px;
`

const StatusText = styled('p')`
  ${(props) => props.theme.fonts.label};
  margin-left: 5px;
  color: ${(props) => props.theme.colors.turquoise};
  margin: 0px;
`

const OccupationContainer = styled('div')`
  flex-direction: row;
  align-items: center
  flex-wrap: wrap;
  margin-left: 5px;
  column-gap: 5px;
`

const OccupationText = styled('p')`
  ${(props) => props.theme.fonts.label};
  margin: 0;
`

const RestaurantLeftContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 20px;
`

const RestaurantRightContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 12px;
`
