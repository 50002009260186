import React, { useState, useMemo } from 'react'
import useI18n from 'i18n/useI18n'
import utils from 'utils/strings'
import {
  findPriorityError,
  getMessageError,
  getBookingReservationTime,
  getReservationTimes,
  getUserListFiltered,
  isDayItemExcluded,
  getDayTimezone,
  utcDateToTimezoneSite,
} from '../utils'
import { findSharvyStatusColor, findStatusColor, isSamePlaceBookedSharvy } from 'parking/utils'
import useNavigation from 'utils/navigation/useNavigation'

import * as dateUtils from 'date-fns'

import config from 'core/src/config'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Icons from 'components/icons/Icons'
import PicturesList from '../components/PicturesList'
import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import Modal from 'components/modal/Modal'
import DeleteModal from './DeleteModal'
import FavoriteZoneDetails from './FavoriteZoneDetails'
import BookingDetails from './BookingDetails'
import OccupationComponent from 'restaurants/screens/OccupationComponent'
import TandemInfosModal from './TandemInfosModal'

import api from '../api'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as planningStore from '../planningStore'
import * as featureStore from 'sites/store/featureStore'

import Loader from 'react-loader-spinner'
import BarclapScreen from '../barclap/BarclapScreen'
import { isToday, isSameDay, differenceInDays, startOfDay } from 'date-fns'

type Status = 'loading' | 'ok' | 'error'
interface ZoneBookingInfos {
  zone: ZapfloorZone
  booking: ZapfloorBooking
}

const FULL_ZONE = 100
const ALMOST_FULL_ZONE = 70

interface Props {
  weekDate: Date
  bookings: ZapfloorBooking[]
  barclapBooking?: BarclapBooking
  parkingBooking?: ParkingSpotRequest
  fetchBarclapBookings: () => void
  apiStatus: Status
  getZoneInfos: (zoneBookingsInfos: ZoneBookingInfos[]) => void
  onDeleteBooking: (bookingId: string) => void
  onDeleteParking: (bookingId: string) => void
  onDeleteSharvyBooking: (sharvyBooking: SharvyBookingRequest[]) => void
  refreshData: () => void
  setSelectedUser: () => void
  setSelectedVisitor: () => void
  noDisplay?: boolean
  setIsReserving: (
    isReserving: boolean,
    isReservingParking?: boolean,
    isReservingVamosParking?: boolean,
    isReservingSharvyParking?: boolean
  ) => void
  setReservationDate: React.Dispatch<React.SetStateAction<Date>>
  restaurants: Restaurant[]
  poisInfos: AreaLight[]
  parkingMode: boolean
  vamosParkingBooking?: VamosBooking
  vamosUserInfos?: VamosDataUser
  sharvyInfos?: SharvyDataUser
}

const DayPlanning = ({
  weekDate,
  bookings,
  fetchBarclapBookings,
  apiStatus,
  getZoneInfos,
  onDeleteBooking,
  onDeleteParking,
  refreshData,
  setIsReserving,
  setReservationDate,
  setSelectedUser,
  setSelectedVisitor,
  noDisplay,
  barclapBooking,
  restaurants,
  parkingBooking,
  poisInfos,
  parkingMode,
  vamosParkingBooking,
  vamosUserInfos,
  sharvyInfos,
  onDeleteSharvyBooking,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const favoriteUsers = useReducer(userStore.store, (s) => s.favoriteUsers)
  const features: Feature[] = useReducer(featureStore.store, (f) => f.features)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const favoriteLocation = useReducer(planningStore.store, (s) => s.favoriteLocation)
  const navigation = useNavigation()
  const favZoneCurrentSite = useMemo(
    () =>
      !!currentSite && !!favoriteLocation && favoriteLocation[currentSite.id]
        ? favoriteLocation[currentSite.id]
        : undefined,
    [favoriteLocation, currentSite]
  )
  const userZapfloorId = useReducer(planningStore.store, (s) => s.zapfloorUserId)

  const [status, setStatus] = useState<Status>('ok')
  const [favZone, setFavZone] = useState<ZapfloorZone>()
  const [zone, setZone] = useState<ZapfloorZone>()
  const [zoneSecondSlot, setZoneSecondSlot] = useState<ZapfloorZone>()
  const [zoneParking, setZoneParking] = useState<ZapfloorZone>()
  const [zoneParkingSecondSlot, setZoneParkingSecondSlot] = useState<ZapfloorZone>()
  const [deskZones, setDeskZones] = React.useState<ZapfloorZone[][]>([])
  const [parkingZones, setParkingZones] = React.useState<ZapfloorZone[][]>([])

  const barclapRestaurant = React.useMemo(
    () => (!!barclapBooking ? restaurants.find((r) => r.barclapId === barclapBooking.module_uuid) : undefined),
    [restaurants, barclapBooking]
  )

  const hasParkingCommuty = React.useMemo(
    () =>
      !!currentSite &&
      !!currentSite.features.find((f) => f.type === 'PARKING_COMMUTY') &&
      !!features.find((f) => f.type === 'PARKING_COMMUTY'),
    [currentSite, features]
  )

  const currentSiteHasZapfloorParking = React.useMemo(
    () => !!features.find((feature) => feature.type === 'ZAPFLOOR_PARKING'),
    [features]
  )

  const currentSiteHasVamosParking = React.useMemo(() => features.find((feature) => feature.type === 'VAMOS_PARKING'), [
    features,
  ])

  const currentSiteHasSharvyParkingFeature = features.find((feature) => feature.type === 'SHARVY_PARKING')

  const currentSiteHasSharvyParking = React.useMemo(
    () =>
      features.find((feature) => feature.type === 'SHARVY_PARKING') &&
      !!sharvyInfos &&
      !!sharvyInfos.hasParkingAccess &&
      !!sharvyInfos.parkingInfo &&
      !!sharvyInfos.parkingInfo.workingDays &&
      !isDayItemExcluded(weekDate, sharvyInfos.parkingInfo.workingDays),
    [features, sharvyInfos, weekDate]
  )

  const deskBookings = React.useMemo(
    () =>
      currentSiteHasZapfloorParking
        ? bookings.filter(
            (booking) =>
              booking.attributes.hot_desk_items[0].unit_type_access_group_id === zone?.attributes.access_group_id ||
              booking.attributes.hot_desk_items[0].unit_type_access_group_id ===
                zoneSecondSlot?.attributes.access_group_id
          )
        : bookings,
    [bookings, currentSiteHasZapfloorParking, zone, zoneSecondSlot]
  )

  const sharvyBookings = React.useMemo(
    () =>
      !!sharvyInfos && !!sharvyInfos.bookings
        ? sharvyInfos.bookings.filter((booking) => isSameDay(weekDate, getDayTimezone(booking.reservationDate || '')))
        : [],
    [sharvyInfos, weekDate]
  )

  const parkingBookings = React.useMemo(
    () =>
      currentSiteHasZapfloorParking
        ? bookings.filter(
            (booking) =>
              booking.attributes.hot_desk_items[0].unit_type_access_group_id ===
                zoneParking?.attributes.access_group_id ||
              booking.attributes.hot_desk_items[0].unit_type_access_group_id ===
                zoneParkingSecondSlot?.attributes.access_group_id
          )
        : bookings,
    [bookings, currentSiteHasZapfloorParking, zoneParking, zoneParkingSecondSlot]
  )

  const hasBarclap = React.useMemo(() => !!currentSite?.barclapId && isToday(weekDate), [currentSite])

  const barclapMaxBookingDate = dateUtils.addDays(dateUtils.setHours(dateUtils.setMinutes(new Date(), 59), 23), 7)

  const usersListFiltered = React.useMemo(
    () => (!!favZone ? getUserListFiltered(favZone.attributes.hot_desk_bookings, favoriteUsers) : []),
    [favoriteUsers, favZone]
  )

  const favZoneAuthorized = React.useMemo(
    () => !!favZone && favZone.attributes.max_days_in_future >= differenceInDays(weekDate, startOfDay(new Date())),
    [favZone, weekDate]
  )

  const peopleList = useMemo(
    () =>
      !!zone
        ? zone.attributes.hot_desk_bookings.map((deskBooking) => {
            return {
              id: deskBooking.user_id,
              firstname: deskBooking.user_firstname,
              lastname: deskBooking.user_lastname,
              email: deskBooking.user_email,
              timeFrom: deskBooking.time_from,
              timeTo: deskBooking.time_to,
              desk: poisInfos.find((poi) => poi.zapfloorId === deskBooking.desk_id),
              teamId: userZapfloorId,
              zone: zone,
              initialDate: getDayTimezone(deskBooking.date_from),
            }
          })
        : usersListFiltered.map((deskBooking) => {
            return {
              id: deskBooking.user_id,
              firstname: deskBooking.user_firstname,
              lastname: deskBooking.user_lastname,
              email: deskBooking.user_email,
              timeFrom: deskBooking.time_from,
              timeTo: deskBooking.time_to,
              desk: poisInfos.find((poi) => poi.zapfloorId === deskBooking.desk_id),
              teamId: userZapfloorId,
              zone: zone,
              initialDate: getDayTimezone(deskBooking.date_from),
            }
          }),
    [zone, usersListFiltered]
  )

  const peopleListSecondSlot = useMemo(
    () =>
      !!zoneSecondSlot
        ? zoneSecondSlot.attributes.hot_desk_bookings.map((deskBooking) => {
            return {
              id: deskBooking.user_id,
              firstname: deskBooking.user_firstname,
              lastname: deskBooking.user_lastname,
              email: deskBooking.user_email,
              timeFrom: deskBooking.time_from,
              timeTo: deskBooking.time_to,
              desk: poisInfos.find((poi) => poi.zapfloorId === deskBooking.desk_id),
            }
          })
        : [],
    [zoneSecondSlot]
  )

  React.useEffect(() => {
    const zoneInfos: ZoneBookingInfos[] = []
    if (deskBookings.length > 0 && deskZones.length > 0) {
      deskZones.map((res, index) => {
        if (!!deskBookings[index]) {
          zoneInfos.push({
            zone: res[0],
            booking: deskBookings[index],
          })
        }
      })
    }
    if (parkingBookings.length > 0 && parkingZones.length > 0) {
      parkingZones.map((res, index) => {
        if (!!parkingBookings[index]) {
          zoneInfos.push({
            zone: res[0],
            booking: parkingBookings[index],
          })
        }
      })
    }
    getZoneInfos(zoneInfos)
  }, [deskBookings, parkingBookings, deskZones, parkingZones])

  React.useEffect(() => {
    if (!!user && !!userZapfloorId && !!currentSite && !!currentSite.locationId) {
      setStatus('loading')
      const promises = bookings.map((booking) =>
        api.zapfloor.getAvailableZones(
          user.type,
          currentSite.id,
          booking.attributes.date_from,
          booking.attributes.hot_desk_items[0].unit_type_access_group_id,
          currentSite.locationId!,
          booking.attributes.time_from,
          booking.attributes.time_to,
          userZapfloorId,
          booking.attributes.hot_desk_items[0].unit_id
        )
      )
      Promise.all(promises)
        .then((res) => {
          setStatus('ok')
          const deskZones = res.filter((zone) => !zone[0].attributes.description.toLowerCase().includes('parking'))
          const parkingZones = res.filter((zone) => zone[0].attributes.description.toLowerCase().includes('parking'))
          setDeskZones(deskZones)
          setParkingZones(parkingZones)
          if (deskZones.length === 1) {
            setZone(deskZones[0][0])
          }
          if (deskZones.length === 2) {
            setZone(deskZones[0][0])
            setZoneSecondSlot(deskZones[1][0])
          }
          if (parkingZones.length === 1) {
            setZoneParking(parkingZones[0][0])
          }
          if (parkingZones.length === 2) {
            setZoneParking(parkingZones[0][0])
            setZoneParkingSecondSlot(parkingZones[1][0])
          }
        })
        .catch(() => setStatus('error'))
    }
  }, [bookings, userZapfloorId])

  React.useEffect(() => {
    if (
      !!user &&
      !!userZapfloorId &&
      !!currentSite &&
      !!currentSite.locationId &&
      !!favZoneCurrentSite &&
      !!favZoneCurrentSite.profilId &&
      !!favZoneCurrentSite.zoneId &&
      ((parkingBookings.length === 0 && favZoneCurrentSite.isParking) ||
        (deskBookings.length === 0 && !favZoneCurrentSite.isParking))
    ) {
      setStatus('loading')
      api.zapfloor
        .getAvailableZones(
          user.type,
          currentSite.id,
          i18n.t('formats.utcDate', { date: weekDate }),
          favZoneCurrentSite.profilId,
          currentSite.locationId,
          config.zapfloor.timeFromAllDay,
          config.zapfloor.timeToAllDay,
          userZapfloorId,
          favZoneCurrentSite.zoneId
        )
        .then((res) => {
          setStatus('ok')
          if (res.length > 0) {
            setFavZone(res[0])
          } else {
            setFavZone(undefined)
          }
        })
        .catch(() => setStatus('error'))
    } else {
      setFavZone(undefined)
    }
  }, [favZoneCurrentSite, userZapfloorId, parkingBookings, deskBookings])

  const onBookingFavZoneWithParking = () => {
    if (!!favZone && !!favZoneCurrentSite) {
      Modal.open(() => (
        <FavoriteZoneDetails
          favZone={favZone}
          date={weekDate}
          profilName={favZoneCurrentSite.profilName!}
          zoneName={favZoneCurrentSite.zoneName!}
          bookFavZone={() => {
            Modal.close()
            onBookingFavZone()
          }}
          bookOtherZone={() => {
            Modal.close()
            setIsReserving(true)
            setReservationDate(weekDate)
            setSelectedUser()
            setSelectedVisitor()
          }}
          poisInfos={poisInfos}
          navigation={navigation}
          refreshData={refreshData}
          parkingMode={parkingMode}
        />
      ))
    }
  }

  const onBookHotDeskError = (errorMessage?: string) => {
    setStatus('error')
    Alert.open(
      () => (
        <ValidationContent
          title={i18n.t('screens.planning.errors.postBooking')}
          onConfirm={Alert.close}
          ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
          description={errorMessage}
        />
      ),
      true
    )
  }

  const onBookingFavZone = () => {
    if (!!favZone && !!user && !!userZapfloorId && !!favZoneCurrentSite) {
      const errorPriority = findPriorityError(favZone.attributes.error_response)
      const errorMessage = getMessageError(errorPriority, true)
      if (
        errorPriority === 'ok' ||
        errorPriority === 'zone_is_full_booked' ||
        errorPriority === 'max_bookings_reached_provided_date'
      ) {
        Modal.open(() => (
          <FavoriteZoneDetails
            favZone={favZone}
            date={weekDate}
            profilName={favZoneCurrentSite.profilName!}
            zoneName={favZoneCurrentSite.zoneName!}
            poisInfos={poisInfos}
            navigation={navigation}
            refreshData={refreshData}
            parkingMode={parkingMode}
            bookFavZone={(reservationTime) => {
              const reservationTimes = getReservationTimes(reservationTime)
              Modal.close()
              setStatus('loading')
              if (
                !!currentSite &&
                !!currentSite.locationId &&
                !!favZoneCurrentSite &&
                !!favZoneCurrentSite.profilId &&
                !!favZoneCurrentSite.zoneId
              ) {
                const request: BodyBookingHotDesking = {
                  reservation: {
                    date_from: i18n.t('formats.utcDate', { date: weekDate }),
                    time_from: reservationTimes.startTime,
                    time_to: reservationTimes.endTime,
                    location_id: currentSite.locationId,
                    waiting_list: errorPriority !== 'ok',
                    description: favZoneCurrentSite.isParking ? 'Parking' : '',
                    create_type: 1,
                  },
                  booked_for_users: [userZapfloorId],
                  unit_ids: [favZoneCurrentSite.zoneId],
                  access_group_ids: [favZoneCurrentSite.profilId],
                  hot_desks: [],
                }
                if (!favZoneCurrentSite.isParking && currentSiteHasVamosParking) {
                  api.vamos
                    .vamosAccess(user.type, currentSite.id, {
                      accessDay: i18n.t('screens.planning.secondForm.dateToSend', {
                        date: weekDate,
                      }),
                    })
                    .then(() => {
                      api.zapfloor
                        .bookHotDesk(user.type, currentSite.id, request)
                        .then(() => {
                          setStatus('ok')
                          refreshData()
                        })
                        .catch(onBookHotDeskError)
                    })
                    .catch((err) => {
                      onBookHotDeskError(
                        err.data.error
                          ? i18n.t('screens.planning.parking.genericError', { errorCode: err.data.error })
                          : undefined
                      )
                    })
                } else {
                  api.zapfloor
                    .bookHotDesk(user.type, currentSite.id, request)
                    .then(() => {
                      setStatus('ok')
                      refreshData()
                    })
                    .catch(onBookHotDeskError)
                }
              }
            }}
          />
        ))
      } else {
        Alert.open(
          () => (
            <ValidationContent
              title={i18n.t(errorMessage)}
              onConfirm={Alert.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ),
          true
        )
      }
    }
  }

  const renderInfos = (iconName: IconName, size: number, color: string, label: string, ariaLabel?: string) => (
    <RowContainer>
      <IconContainer>
        <Icons name={iconName} size={size} color={color} ariaLabel={ariaLabel} />
      </IconContainer>
      <Info>{label}</Info>
    </RowContainer>
  )

  const renderReservation = (booking: ZapfloorBooking, smallView: boolean, index: number, isParking: boolean) => {
    if (!!user) {
      if (apiStatus === 'loading' || status === 'loading') {
        return (
          <LoaderContainer>
            <Loader type="TailSpin" color={Theme.colors.blue} />
          </LoaderContainer>
        )
      }

      if (apiStatus === 'error' || status === 'error') {
        return <ErrorLabel>{i18n.t('screens.planning.errors.reservation')}</ErrorLabel>
      }
      const isSecondSlot =
        smallView && index === 1 && ((isParking && !!zoneParkingSecondSlot) || (!isParking && !!zoneSecondSlot))
      const { waiting_list } = booking.attributes
      const reservationTime = getBookingReservationTime(booking.attributes.time_from, booking.attributes.time_to)

      const findZone = isSecondSlot
        ? isParking
          ? zoneParkingSecondSlot
          : zoneSecondSlot
        : isParking
        ? zoneParking
        : zone

      const hotDeskId = booking.attributes.hot_desk_items[0].hot_desk_id
      const desk = poisInfos.find((poi) => poi.zapfloorId === hotDeskId)
      const isDeskBooking = !!hotDeskId && !!desk

      if (!!findZone) {
        const { access_group_name, unit_name, desk_count, desks_taken } = findZone.attributes
        const rate = Math.round((desks_taken * 100) / desk_count)
        const iconChoice: IconName =
          rate === FULL_ZONE ? 'gauge-full' : rate > ALMOST_FULL_ZONE ? 'gauge-middle' : 'gauge-empty'
        const occupancyColor =
          rate === FULL_ZONE
            ? Theme.colors.red
            : rate > ALMOST_FULL_ZONE
            ? Theme.colors.orange
            : Theme.colors.strongTurquoise

        return (
          <ReservationContainer
            cliquable={smallView}
            tabIndex={smallView ? 0 : -1}
            onClick={() => {
              if (smallView) {
                Modal.open(() => (
                  <BookingDetails
                    booking={booking}
                    zone={findZone}
                    rate={rate}
                    iconChoice={iconChoice}
                    occupancyColor={occupancyColor}
                    peopleList={isSecondSlot ? peopleListSecondSlot : peopleList}
                    removeBooking={() => {
                      Modal.close()
                      onDeleteBooking(booking.id)
                    }}
                    desk={desk}
                    navigation={navigation}
                    isParking={isParking}
                  />
                ))
              }
            }}>
            {(!smallView || (smallView && index === 0)) && (
              <RowContainer>
                <DeskReservation>
                  {i18n.t(`screens.planning.booking.title${isParking ? 'Parking' : ''}`)}
                </DeskReservation>
              </RowContainer>
            )}
            <SubContainer>
              <LeftContainer>
                {reservationTime !== 'ALL_DAY' &&
                  renderInfos(
                    'clock',
                    15,
                    Theme.colors.blue,
                    i18n.t(`screens.desk.reservationTime.${reservationTime}`)
                  )}
                {!smallView &&
                  renderInfos(
                    'id_card',
                    15,
                    Theme.colors.blue,
                    access_group_name,
                    i18n.t('screens.planning.iconsAction.team')
                  )}
                {/* if the zone allow booking by desk, render the desk name, otherwise render the zone name */}
                {!!isDeskBooking && !!desk
                  ? renderInfos(
                      isParking ? 'parking' : 'desk',
                      15,
                      Theme.colors.blue,
                      desk.name,
                      i18n.t('screens.planning.iconsAction.zone')
                    )
                  : renderInfos(
                      isParking ? 'parking' : 'desk',
                      15,
                      Theme.colors.blue,
                      unit_name,
                      i18n.t('screens.planning.iconsAction.zone')
                    )}
                {!smallView && !isDeskBooking && (
                  <RowContainer>
                    <OccupancyInfo>
                      <IconContainer>
                        <Icons
                          name="users-wm"
                          color={Theme.colors.blue}
                          size={15}
                          ariaLabel={i18n.t('screens.planning.iconsAction.occupancy')}
                        />
                      </IconContainer>
                      <Occupancy>
                        {i18n.t('screens.planning.secondForm.occupancy', {
                          occupancy: desks_taken,
                          capacity: desk_count,
                        })}
                      </Occupancy>
                      <OccupationComponent currentCapacity={desks_taken} maxCapacity={desk_count} />
                    </OccupancyInfo>
                  </RowContainer>
                )}

                <RowContainer>
                  <PointContainer isWaitingList={waiting_list} />
                  <Info isWaitingList={waiting_list}>
                    {i18n.t(`screens.planning.booking.${waiting_list ? 'waiting' : 'confirmed'}`)}
                  </Info>
                </RowContainer>

                {!!user && !smallView && !!desk && !isParking && (
                  <PicturesListContainer>
                    <PicturesList
                      clickable
                      peopleList={peopleList}
                      poisInfos={poisInfos}
                      refreshData={refreshData}
                      navigation={navigation}
                      myDesk={desk.uniquePlaceName}
                      selectionModalTitle={i18n.t('screens.planning.selectionOnPlanModal.fromContactsTitle')}
                    />
                  </PicturesListContainer>
                )}
              </LeftContainer>

              {smallView && (
                <RightContainer>
                  <Icons name="chevron_right" size={15} color={Theme.colors.darkGrey} />
                </RightContainer>
              )}
            </SubContainer>
          </ReservationContainer>
        )
      } else {
        const hot_desk_name =
          booking.attributes.hot_desk_items.length > 0 ? booking.attributes.hot_desk_items[0].hot_desk_name : undefined

        return (
          <ReservationContainer cliquable={false} tabIndex={-1}>
            <RowContainer>
              <DeskReservation>{i18n.t(`screens.planning.booking.title${isParking ? 'Parking' : ''}`)}</DeskReservation>
            </RowContainer>
            {reservationTime !== 'ALL_DAY' && (
              <RowContainer>
                <IconContainer>
                  <Icons name="clock" size={15} color={Theme.colors.blue} />
                </IconContainer>
                <Info>{i18n.t(`screens.desk.reservationTime.${reservationTime}`)}</Info>
              </RowContainer>
            )}
            <RowContainer>
              <IconContainer>
                <Icons
                  name={isParking ? 'parking' : 'desk'}
                  ariaLabel={i18n.t('screens.planning.iconsAction.zone')}
                  size={15}
                  color={Theme.colors.blue}
                />
              </IconContainer>
              <Info>{hot_desk_name}</Info>
            </RowContainer>

            <RowContainer>
              <PointContainer isWaitingList={waiting_list} />
              <Info isWaitingList={waiting_list}>
                {i18n.t(`screens.planning.booking.${waiting_list ? 'waiting' : 'confirmed'}`)}
              </Info>
            </RowContainer>
          </ReservationContainer>
        )
      }
    }
  }

  const renderNoReservation = (isParking: boolean) => {
    if (status === 'loading') {
      return (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      )
    }

    if (status === 'error') {
      return <ErrorLabel>{i18n.t('screens.planning.errors.reservation')}</ErrorLabel>
    }

    if (hasParkingCommuty) {
      return null
    }

    if (
      !!favZone &&
      favZoneAuthorized &&
      !!favZoneCurrentSite &&
      !!favZoneCurrentSite.profilId &&
      !!favZoneCurrentSite.zoneId &&
      (isParking ? favZoneCurrentSite.isParking : !favZoneCurrentSite.isParking)
    ) {
      const { desks_taken, desk_count } = favZone.attributes

      return (
        <ReservationContainer cliquable={false} tabIndex={-1}>
          {isParking && <DeskReservation>{i18n.t('screens.planning.booking.titleParking')}</DeskReservation>}
          <RowContainer>
            <InfoBold>{i18n.t('screens.planning.referenceWeek.noBooking')}</InfoBold>
          </RowContainer>
          <RowContainer>
            <Info>{i18n.t('screens.planning.booking.bookFavZone')}</Info>
          </RowContainer>
          <RowContainer>
            <OccupancyInfo>
              <IconContainer>
                <Icons
                  name="users-wm"
                  color={Theme.colors.blue}
                  size={15}
                  ariaLabel={i18n.t('screens.planning.iconsAction.occupancy')}
                />
              </IconContainer>
              <Occupancy>
                {i18n.t('screens.planning.secondForm.occupancy', {
                  occupancy: desks_taken,
                  capacity: desk_count,
                })}
              </Occupancy>
              <OccupationComponent currentCapacity={desks_taken} maxCapacity={desk_count} />
            </OccupancyInfo>
          </RowContainer>
          {!!user && !isParking && (
            <PicturesListContainer>
              <PicturesList
                clickable
                peopleList={peopleList}
                poisInfos={poisInfos}
                refreshData={refreshData}
                navigation={navigation}
              />
            </PicturesListContainer>
          )}
          {isParking && (
            <BookingContainer>
              <LabelContainer>
                <Info>{i18n.t('screens.planning.booking.book')} :</Info>
              </LabelContainer>

              <ButtonsContainer>
                <ButtonContainer>
                  <Button
                    verticalPadding={6}
                    label={i18n.t(`screens.planning.booking.bookFavZone`)}
                    onClick={onBookingFavZone}
                    width={120}
                  />
                </ButtonContainer>
                <ButtonContainer>
                  <Button
                    verticalPadding={6}
                    color={Theme.colors.white}
                    shadow
                    textColor={Theme.colors.blue}
                    label={i18n.t(`screens.planning.booking.bookOtherZone`)}
                    onClick={() => {
                      setIsReserving(true, true)
                      setReservationDate(weekDate)
                      setSelectedUser()
                      setSelectedVisitor()
                    }}
                    width={120}
                  />
                </ButtonContainer>
              </ButtonsContainer>
            </BookingContainer>
          )}
        </ReservationContainer>
      )
    } else {
      return null
    }
  }

  const renderReservationButtons = () => {
    if (status === 'loading' || status === 'error') {
      return null
    }
    if (
      !!favZone &&
      favZoneAuthorized &&
      !!favZoneCurrentSite &&
      !!favZoneCurrentSite.profilId &&
      !!favZoneCurrentSite.zoneId &&
      !hasParkingCommuty &&
      !favZoneCurrentSite.isParking
    ) {
      return (
        <BookingContainer>
          <LabelContainer>
            <Info>{i18n.t('screens.planning.booking.book')} :</Info>
          </LabelContainer>

          <ButtonsContainer>
            <ButtonContainer>
              <Button
                verticalPadding={6}
                label={i18n.t(`screens.planning.booking.bookFavZone`)}
                onClick={onBookingFavZone}
                width={120}
              />
            </ButtonContainer>
            <ButtonContainer>
              <Button
                verticalPadding={6}
                color={Theme.colors.white}
                shadow
                textColor={Theme.colors.blue}
                label={i18n.t(`screens.planning.booking.bookOtherZone`)}
                onClick={() => {
                  setIsReserving(true)
                  setReservationDate(weekDate)
                  setSelectedUser()
                  setSelectedVisitor()
                }}
                width={120}
              />
            </ButtonContainer>
          </ButtonsContainer>
        </BookingContainer>
      )
    }
    return (
      <NoBookingContainer>
        <DeskReservation>{i18n.t('screens.planning.booking.title')}</DeskReservation>
        <Info>{i18n.t('screens.planning.referenceWeek.noBooking')}</Info>
        <NoBookingButtonContainer>
          <Button
            verticalPadding={6}
            color={Theme.colors.blue}
            textColor={Theme.colors.white}
            label={i18n.t(`screens.planning.booking.book`)}
            onClick={() => {
              if (
                !!favZone &&
                favZoneAuthorized &&
                !!favZoneCurrentSite &&
                !!favZoneCurrentSite.profilId &&
                !!favZoneCurrentSite.zoneId &&
                !favZoneCurrentSite.isParking
              ) {
                onBookingFavZoneWithParking()
              } else {
                setIsReserving(true)
                setReservationDate(weekDate)
                setSelectedUser()
                setSelectedVisitor()
              }
            }}
            width={120}
          />
        </NoBookingButtonContainer>
      </NoBookingContainer>
    )
  }

  const renderLunch = () =>
    !!barclapBooking && !!currentSite ? (
      <LunchContainer>
        <LabelContainer>
          <InfoBold>{i18n.t('screens.planning.barclap.title')}</InfoBold>
        </LabelContainer>
        <RowContainer>
          <IconContainer>
            <Icons name="building" size={15} color={Theme.colors.blue} />
          </IconContainer>
          <Info>{barclapRestaurant?.name}</Info>
        </RowContainer>
        <RowContainer>
          <IconContainer>
            <Icons name="user" size={15} color={Theme.colors.blue} />
          </IconContainer>
          <Info>
            {i18n.t(`screens.barclap.form.${!!barclapBooking.nb_guest ? 'guestCount2' : 'noGuest'}`, {
              count: barclapBooking.nb_guest,
            })}
          </Info>
        </RowContainer>
        <RowContainer>
          <IconContainer>
            <Icons name="clock" size={15} color={Theme.colors.blue} />
          </IconContainer>
          <Info>
            {i18n.t('screens.barclap.form.timeSlot', {
              start: utcDateToTimezoneSite(barclapBooking.start_time, currentSite.timezone),
              end: utcDateToTimezoneSite(barclapBooking.end_time, currentSite.timezone),
            })}
          </Info>
        </RowContainer>
        <NoBookingButtonContainer>
          <Button
            verticalPadding={6}
            width={120}
            label={i18n.t('screens.barclap.bookings.show')}
            color={Theme.colors.white}
            textColor={Theme.colors.blue}
            shadow
            onClick={() =>
              Modal.open(() => (
                <BarclapScreen
                  closeModal={Modal.close}
                  refreshPlanningBookings={fetchBarclapBookings}
                  initialDateProps={weekDate}
                  restaurants={restaurants}
                  bookingTab="BOOKINGS"
                  bookingIdToDisplayProps={barclapBooking.id}
                />
              ))
            }
          />
        </NoBookingButtonContainer>
      </LunchContainer>
    ) : (
      <NoBookingContainer>
        <DeskReservation>{i18n.t('screens.planning.barclap.title')}</DeskReservation>
        <Info>{i18n.t('screens.planning.referenceWeek.noBooking')}</Info>
        <NoBookingButtonContainer>
          {dateUtils.isAfter(barclapMaxBookingDate, weekDate) ? (
            <Button
              verticalPadding={6}
              label={i18n.t(`screens.planning.booking.book`)}
              disabled={new Date().getHours() >= 14}
              onClick={() =>
                Modal.open(() => (
                  <BarclapScreen
                    closeModal={Modal.close}
                    refreshPlanningBookings={fetchBarclapBookings}
                    initialDateProps={weekDate}
                    restaurants={restaurants}
                  />
                ))
              }
              width={120}
            />
          ) : (
            <LabelContainer>
              <Label>{i18n.t('screens.planning.barclap.barclapBookAfterMaxDate')}</Label>
            </LabelContainer>
          )}
        </NoBookingButtonContainer>
      </NoBookingContainer>
    )

  const renderParking = () => {
    if (apiStatus === 'loading') {
      return (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      )
    }

    if (apiStatus === 'error') {
      return <ErrorLabel>{i18n.t('screens.planning.errors.reservation')}</ErrorLabel>
    }
    if (
      currentSiteHasZapfloorParking &&
      parkingBookings.length === 0 &&
      !!favZoneCurrentSite &&
      favZoneCurrentSite.isParking
    ) {
      return renderNoReservation(true)
    }
    if (currentSiteHasZapfloorParking && parkingBookings.length > 0) {
      return parkingBookings.map((booking, index) => renderReservation(booking, bookings.length > 1, index, true))
    }
    if (!!parkingBooking) {
      const status = parkingBooking.nextAllocationStatus
      const statusColor = findStatusColor(status, Theme)
      return (
        <BottomContainer>
          <LunchContainer>
            <LabelContainer>
              <InfoBold>{i18n.t('screens.parking.bookingForm')}</InfoBold>
            </LabelContainer>
            <RowContainer>
              <IconContainer>
                <Icons name="parking" size={15} color={Theme.colors.blue} />
              </IconContainer>
              <Info>{parkingBooking.parkingArea.name}</Info>
            </RowContainer>
            <RowContainer>
              <IconContainer>
                <Icons name="pin" size={15} color={Theme.colors.blue} />
              </IconContainer>
              <Info>{parkingBooking.parkingArea.address}</Info>
            </RowContainer>

            <ParkingStatusContainer>
              <StatusIndicator statusColor={statusColor} />
              <StatusText statusColor={statusColor}>{i18n.t(`screens.parking.status.${status}`)}</StatusText>
            </ParkingStatusContainer>
            {(status === 'PENDING' || status === 'ALREADY_ATTEMPTED') && parkingBooking.nextAllocationAt && (
              <>
                <RowContainer>
                  <Info>{i18n.t('screens.parking.nextAttributionText')}</Info>
                </RowContainer>
                <RowContainer>
                  <IconContainer>
                    <Icons name="clock" size={15} color={Theme.colors.blue} />
                  </IconContainer>
                  <Info>
                    {utils.capitalize(
                      i18n.t('screens.parking.nextAttribution', {
                        date: {
                          date: new Date(parkingBooking.nextAllocationAt),
                          mode: 'dateWithTimezone',
                          timeZone: currentSite?.timezone,
                        },
                      })
                    )}
                  </Info>
                </RowContainer>
              </>
            )}
          </LunchContainer>
        </BottomContainer>
      )
    }
    return (
      <BottomContainer>
        <LunchContainer>
          <NoBookingContainer>
            <DeskReservation>{i18n.t('screens.parking.bookingForm')}</DeskReservation>
            <Info>{i18n.t('screens.planning.referenceWeek.noBooking')}</Info>
            <NoBookingButtonContainer>
              <Button
                verticalPadding={6}
                label={i18n.t(`screens.planning.booking.book`)}
                onClick={() => {
                  if (currentSiteHasZapfloorParking) {
                    setIsReserving(true, true)
                    setReservationDate(weekDate)
                    setSelectedUser()
                    setSelectedVisitor()
                  } else {
                    navigation.push('/parking', {
                      initialDatePlanning: weekDate,
                    })
                  }
                }}
                width={120}
              />
            </NoBookingButtonContainer>
          </NoBookingContainer>
        </LunchContainer>
      </BottomContainer>
    )
  }

  const renderSharvyBooking = (booking: SharvyParkingBooking, allDay?: boolean) => {
    const statusColor = findSharvyStatusColor(booking.status, Theme)
    return (
      <>
        <RowContainer>
          <IconContainer>
            <Icons name="clock" size={15} color={Theme.colors.blue} />
          </IconContainer>
          <Info>
            {i18n.t(
              `screens.planning.selectionOnPlanModal.reservationTime.${
                allDay ? 'ALL_DAY' : booking.isMorning ? 'MORNING' : 'AFTERNOON'
              }`
            )}
          </Info>
        </RowContainer>
        {!!booking.spot?.spotType && (
          <RowContainer>
            <IconContainer>
              <Icons name="car" size={15} color={Theme.colors.blue} />
            </IconContainer>
            <Info>
              {i18n.t('screens.planning.sharvy.typeDescription', {
                type: i18n.t(`screens.planning.sharvy.types.${booking.spot?.spotType}`),
              })}
            </Info>
          </RowContainer>
        )}
        {!!booking.spot?.name && (
          <RowContainer>
            <IconContainer>
              <Icons name="parking" size={15} color={Theme.colors.blue} />
            </IconContainer>
            <Info>{booking.spot?.name}</Info>
          </RowContainer>
        )}
        <RowContainer>
          <ParkingStatusContainer>
            <StatusIndicator statusColor={statusColor} />
            <StatusText statusColor={statusColor}>
              {i18n.t(`screens.planning.sharvy.status.${booking.status}`)}
            </StatusText>
          </ParkingStatusContainer>
        </RowContainer>
      </>
    )
  }

  const renderSharvyParking = () => {
    if (sharvyBookings.length === 0) {
      return (
        <SharvyContainer>
          <NoBookingContainer>
            <DeskReservation>{i18n.t('screens.parking.bookingForm')}</DeskReservation>

            <Info>{i18n.t('screens.planning.referenceWeek.noBooking')}</Info>
            <NoBookingButtonContainer>
              <Button
                verticalPadding={6}
                label={i18n.t(`screens.planning.booking.book`)}
                onClick={() => {
                  setIsReserving(true, false, false, true)
                  setReservationDate(weekDate)
                  setSelectedUser()
                  setSelectedVisitor()
                }}
                width={120}
              />
            </NoBookingButtonContainer>
          </NoBookingContainer>
        </SharvyContainer>
      )
    }

    const samePlaceBooked = isSamePlaceBookedSharvy(sharvyBookings)

    // Cas si on a qu'une seule résa ou alors 2 résas sur la même place
    if (sharvyBookings.length === 1 || samePlaceBooked) {
      return (
        <SharvyContainer>
          <LabelContainer>
            <InfoBold>{i18n.t('screens.parking.bookingForm')}</InfoBold>
          </LabelContainer>
          {renderSharvyBooking(sharvyBookings[0], samePlaceBooked)}
        </SharvyContainer>
      )
    }

    return (
      <SharvyContainer noPaddingHorizontal>
        <LabelContainer paddingHorizontal>
          <InfoBold>{i18n.t('screens.parking.bookingForm')}</InfoBold>
        </LabelContainer>
        {sharvyBookings.map((booking, index) => (
          <LeftContainer key={index} borderBottom={index === 0} paddingHorizontal>
            {renderSharvyBooking(booking)}
          </LeftContainer>
        ))}
      </SharvyContainer>
    )
  }

  const renderVamosParking = () => {
    if (!vamosUserInfos) {
      return null
    }
    if (!vamosParkingBooking) {
      return (
        <BottomContainer>
          <NoBookingContainer>
            <DeskReservation>{i18n.t('screens.parking.bookingForm')}</DeskReservation>
            {!!vamosUserInfos && !vamosUserInfos.available ? (
              <Info>{i18n.t('screens.planning.errors.vamosNotAvailableForUser')}</Info>
            ) : (
              <>
                <Info>{i18n.t('screens.planning.referenceWeek.noBooking')}</Info>
                <NoBookingButtonContainer>
                  <Button
                    verticalPadding={6}
                    label={i18n.t(`screens.planning.booking.book`)}
                    onClick={() => {
                      setIsReserving(true, false, true)
                      setReservationDate(weekDate)
                      setSelectedUser()
                      setSelectedVisitor()
                    }}
                    width={120}
                  />
                </NoBookingButtonContainer>
              </>
            )}
          </NoBookingContainer>
        </BottomContainer>
      )
    }
    // TODO :
    // -> Affichage du nom du parking => impossible pour le moment
    const isTandemPlace = vamosParkingBooking.tandem.tandemPlace
    return (
      <BottomContainer>
        <LunchContainer>
          <LabelContainer>
            <InfoBold>{i18n.t('screens.parking.bookingForm')}</InfoBold>
          </LabelContainer>
          <RowContainer>
            <IconContainer>
              <Icons name="parking" size={15} color={Theme.colors.blue} />
            </IconContainer>
            <Info>{vamosParkingBooking.parkingPlace}</Info>
          </RowContainer>
          <RowContainer>
            <IconContainer>
              <Icons name="car" size={15} color={Theme.colors.blue} />
            </IconContainer>
            <Info
              cliquable={isTandemPlace}
              onClick={() => {
                if (isTandemPlace) {
                  Modal.open(() => <TandemInfosModal vamosParkingBooking={vamosParkingBooking} />)
                }
              }}>
              {i18n.t(`screens.planning.parking.${isTandemPlace ? 'tandem' : 'simple'}Place`, {
                place: vamosParkingBooking.parkingNumber,
              })}
            </Info>
          </RowContainer>
        </LunchContainer>
      </BottomContainer>
    )
  }

  if (!!noDisplay) {
    return null
  }

  return (
    <MainContainer>
      <DateContainer>
        <DateText>{utils.capitalize(i18n.t('screens.planning.reservationDate', { date: weekDate }))}</DateText>
        {(bookings.length > 0 || !!parkingBooking || (currentSiteHasSharvyParking && sharvyBookings.length > 0)) && (
          <TrashContainer
            onClick={() => {
              const samePlaceBooked = isSamePlaceBookedSharvy(sharvyBookings)
              if (bookings.length === 0 && !!parkingBooking && hasParkingCommuty) {
                onDeleteParking(parkingBooking.id)
              } else if (
                bookings.length === 1 &&
                !barclapBooking &&
                !parkingBooking &&
                !vamosParkingBooking &&
                sharvyBookings.length === 0
              ) {
                onDeleteBooking(bookings[0].id)
              } else if (
                bookings.length === 0 &&
                currentSiteHasSharvyParking &&
                (sharvyBookings.length === 1 || samePlaceBooked)
              ) {
                onDeleteSharvyBooking(sharvyBookings)
              } else {
                Modal.open(() => (
                  <DeleteModal
                    closeModal={Modal.close}
                    barclapBooking={barclapBooking}
                    parkingBooking={hasParkingCommuty ? parkingBooking : undefined}
                    zapfloorBookings={deskBookings}
                    onDelete={refreshData}
                    zapfloorParkingBookings={currentSiteHasZapfloorParking ? parkingBookings : []}
                    vamosParkingBooking={currentSiteHasVamosParking ? vamosParkingBooking : undefined}
                    sharvyParkingBookings={currentSiteHasSharvyParking ? sharvyBookings : []}
                  />
                ))
              }
            }}>
            <IconContainer>
              <Icons
                name="bin"
                ariaLabel={i18n.t('screens.planning.iconsAction.delete')}
                color={Theme.colors.blue}
                size={20}
              />
            </IconContainer>
          </TrashContainer>
        )}
      </DateContainer>
      {deskBookings.length === 0
        ? renderNoReservation(false)
        : deskBookings.map((booking, index) => renderReservation(booking, bookings.length > 1, index, false))}
      {deskBookings.length === 0 ? (
        <TopContainer>{renderReservationButtons()}</TopContainer>
      ) : currentSiteHasSharvyParking ? (
        renderSharvyParking()
      ) : currentSiteHasVamosParking ? (
        renderVamosParking()
      ) : hasBarclap &&
        !hasParkingCommuty &&
        !currentSiteHasZapfloorParking &&
        !currentSiteHasVamosParking &&
        !currentSiteHasSharvyParkingFeature ? (
        <BottomContainer>{renderLunch()}</BottomContainer>
      ) : null}
      {deskBookings.length === 0 && currentSiteHasSharvyParking && renderSharvyParking()}
      {!currentSiteHasVamosParking &&
        !currentSiteHasSharvyParking &&
        (hasParkingCommuty || currentSiteHasZapfloorParking) &&
        renderParking()}
    </MainContainer>
  )
}
export default DayPlanning

const MainContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  margin: 10px;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: max-content;
`

const DateText = styled.p`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => props.theme.colors.blue};
  display: flex;
  flex: 5;
  font-size: 16px;
  line-height: 19px;
  margin: 0px 10px;
  white-space: nowrap;
`

const TrashContainer = styled.button`
  display: flex;
  flex: 1;
  color: ${(props) => props.theme.colors.blue};
  justify-content: flex-end;
`

const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-bottom: solid 1px ${(props) => props.theme.colors.mediumDarkGrey};
`

const DeskReservation = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 12px;
  line-height: 14px;
  margin: 0px;
`

const Info = styled.p<{ isWaitingList?: boolean; cliquable?: boolean }>`
  margin: 0px;
  padding-top: 3px;
  color: ${(props) =>
    props.cliquable
      ? props.theme.colors.blue
      : props.isWaitingList === undefined
      ? props.theme.colors.black
      : !props.isWaitingList
      ? props.theme.colors.strongTurquoise
      : props.theme.colors.orange};
  ${(props) => props.cliquable && 'cursor: pointer;'}
  ${(props) => props.cliquable && 'text-decoration: underline;'}
`

const InfoBold = styled.p<{ isWaitingList?: boolean }>`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 12px;
  line-height: 14px;
  margin: 0px 0px 10px;
  padding-top: 3px;
  color: ${(props) =>
    props.isWaitingList === undefined
      ? props.theme.colors.black
      : !props.isWaitingList
      ? props.theme.colors.strongTurquoise
      : props.theme.colors.orange};
`

const BottomContainer = styled.div`
  display: flex;
  padding: 10px;
`

const ReservationContainer = styled.button<{ cliquable: boolean }>`
  padding: 10px 20px 0px;
  cursor: ${(props) => (props.cliquable ? 'pointer' : 'default')};
  border-bottom: solid 1px ${(props) => props.theme.colors.mediumDarkGrey};
`

const TopContainer = styled.div`
  padding: 10px 20px;
  border-bottom: solid 1px ${(props) => props.theme.colors.mediumDarkGrey};
`

const LunchContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 10px;
`

const RowContainer = styled.div`
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
  margin: 0px 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const PointContainer = styled.div<{ isWaitingList: boolean }>`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  margin-right: 7px;
  background-color: ${(props) =>
    !props.isWaitingList ? props.theme.colors.strongTurquoise : props.theme.colors.orange};
`

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const ErrorLabel = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 10px 20px;
`

const Occupancy = styled.p`
  margin: 0px 10px 0 0;
`

const OccupancyInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const BookingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  padding-left: 10px;
`

const LabelContainer = styled.div<{ paddingHorizontal?: boolean }>`
  display: flex;
  flex: 1;
  ${(props) => props.paddingHorizontal && 'padding: 0px 10px 0px 20px;'}
`

const ButtonsContainer = styled.div`
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  flex: 2;
  white-space: nowrap;
`

const ButtonContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex: 1;
`

const NoBookingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
`

const NoBookingButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`

const IconContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.label};
  text-align: center;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
`

const ParkingStatusContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`
const StatusText = styled('p')<{ statusColor: string }>`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.statusColor};
  margin: 0px;
`

const StatusIndicator = styled('div')<{ statusColor: string }>`
  width: 4px;
  height: 4px;
  background-color: ${(props) => props.statusColor};
  border-radius: 2px;
  margin: 0px 5px;
`

const PicturesListContainer = styled('div')`
  margin-bottom: 8px;
`

const LeftContainer = styled('div')<{ borderBottom?: boolean; paddingHorizontal?: boolean }>`
  flex: 1;
  ${(props) => props.borderBottom && `border-bottom: solid 1px ${props.theme.colors.mediumDarkGrey};`}
  ${(props) => props.paddingHorizontal && 'padding: 0px 10px 0px 20px;'}
  ${(props) => props.borderBottom && 'margin-bottom: 8px;'}
`

const RightContainer = styled('div')``

const SubContainer = styled('div')`
  flex-direction: row;
  display: flex;
  align-items: center;
`

const SharvyContainer = styled('div')<{ noPaddingHorizontal?: boolean }>`
  flex: 1;
  flex-direction: column;
  display: flex;
  padding: ${(props) => (props.noPaddingHorizontal ? '10px 0px' : '10px 10px 10px 20px')};
`
