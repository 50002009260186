import * as React from 'react'
import screens from './Screens'
import styled from 'theme/styled-components'

import * as siteStore from 'sites/store/siteStore'
import * as featureStore from 'sites/store/featureStore'
import * as userStore from 'store/user/user'
import * as iframeStore from 'iframe/iframeStore'
import * as formStore from 'store/form/formStore'
import * as shortcutStore from 'home/store/shortcutStore'
import * as roomStore from 'roombooking/roomStore'
import useReducer from 'store/useReducer'

import api from 'sites/api/sitesApi'
import accountApi from 'account/accountApi'
import graphApi from 'roombooking/api/graphRoomApi'

import useNavigation from 'utils/navigation/useNavigation'
import DrawerButton from 'components/button/DrawerButton'
import { useLocation } from 'react-router-dom'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { processFeatures } from 'home/utils'
import useBreakpoint from 'utils/useBreakpoints'
import config from 'core/src/config'

import useI18n from 'i18n/useI18n'
import useTheme from 'theme/useTheme'

import Logger from 'utils/Logger'
import FocusLock from 'react-focus-lock'

import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'
import InteractiveToast from 'components/toast/InteractiveToast'

interface Props {
  drawerOpened: boolean
  setDrawerOpened: (opened: boolean) => void
}

const Drawer = ({ drawerOpened, setDrawerOpened }: Props) => {
  const [Theme] = useTheme()
  const [, currentBreakpoint] = useBreakpoint()
  const site = useReducer(siteStore.store, (s) => s.site)
  const allSiteStore = useReducer(siteStore.store, (s) => s)
  const user = useReducer(userStore.store, (u) => u.user)
  const sameUser = useReducer(userStore.store, (u) => u.sameUser)
  const iframeLink = useReducer(iframeStore.store, (s) => s.iframeURL)
  const currentIframe = useReducer(iframeStore.store, (s) => s.currentIFrame)
  const features: Feature[] = useReducer(featureStore.store, (f) => f.features)
  const confirmNavigation = useReducer(formStore.store, (s) => s.confirmNavigation)
  const parkingUrl = useReducer(siteStore.store, (s) => s.parkingUrl)

  const graphIdBooking = useReducer(roomStore.storeNotPersistant, (s) => s.graphIdBooking)
  const graphEmailBooking = useReducer(roomStore.storeNotPersistant, (s) => s.graphEmailBooking)
  const formTimes = useReducer(roomStore.storeNotPersistant, (s) => s.formTimes)

  const allSitesShortcuts = useReducer(shortcutStore.store, (s) => s.sitesShortcuts)

  const navigation = useNavigation()
  const location = useLocation()
  const i18n = useI18n()

  const [mouseY, setMouseY] = React.useState(0)

  const featuredItems = processFeatures(navigation, features, allSiteStore, i18n, user, parkingUrl, 'drawer').sort(
    (a, b) => a.position - b.position
  )

  const showSites = React.useMemo(() => {
    if (!!user) {
      if (user.type === 'EXTERNAL') {
        return allSiteStore.mySites.length > 1
      }
      return allSiteStore.allSites.length > 1
    }
    return false
  }, [user, allSiteStore.mySites, allSiteStore.allSites])

  React.useEffect(() => {
    if (drawerOpened) {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [drawerOpened])

  React.useEffect(() => {
    if (user && site) {
      if (user.type === 'EXTERNAL') {
        api
          .getUserSites(user.type)
          .then((personalSites) => {
            siteStore.actions.setMySites(personalSites.sites)
          })
          .catch(Logger.error)
      }

      accountApi
        .getUserFeatures(site.id, user.type)
        .then((userFeatures) => {
          featureStore.actions.setUserFeatures(userFeatures.features)

          if (!allSitesShortcuts[site.id] || allSitesShortcuts[site.id].isDefault || !sameUser) {
            const commonList = userFeatures.features
              .filter(
                (feature) => feature.shortcutByDefault && feature.type !== 'DOMOTIC' && feature.type !== 'RELEASE'
              )
              .map((f) =>
                f.type === 'THIRD_PART' || f.type === 'PARKING'
                  ? { id: f.id, name: f.name }
                  : { id: f.id, name: f.type }
              )
            shortcutStore.actions.setSiteShortcuts({ siteId: site.id, list: commonList, isDefault: true })
            userStore.actions.setSameUser(true)
          }
        })
        .catch(Logger.error)
    }
  }, [user, i18n.lang, sameUser, allSitesShortcuts])

  React.useEffect(() => {
    if (graphIdBooking && graphEmailBooking && user) {
      const interval = setInterval(() => {
        graphApi.getBooking(user.type, graphIdBooking).then((res) => {
          const findAttendee = res.attendees.find((attendee) => attendee.emailAddress.address === graphEmailBooking)
          const onlyOneAttendee = res.attendees.length === 1
          const isAccepted =
            (!!findAttendee && findAttendee.status?.response === 'accepted') ||
            (onlyOneAttendee && res.attendees[0].status?.response === 'accepted')
          const isDeclined =
            (!!findAttendee && findAttendee.status?.response === 'declined') ||
            (onlyOneAttendee && res.attendees[0].status?.response === 'declined')
          if (isAccepted || isDeclined) {
            clearInterval(interval)
            roomStore.actions.resetGraphInfos()
            InteractiveToast.close()
            InteractiveToast.open(
              i18n.t(`screens.room.toast.${isAccepted ? 'confirm' : 'declined'}Title`),
              i18n.t(`screens.room.toast.${isAccepted ? 'confirm' : 'declined'}Description`),
              isAccepted ? 'check-circle' : 'cross-circle',
              isAccepted ? Theme.colors.toastSuccessText : Theme.colors.toastErrorText,
              isAccepted ? Theme.colors.toastSuccessBackground : Theme.colors.toastErrorBackground,
              currentBreakpoint === 'phone' ? 'bottom' : 'top-right',
              navigation,
              !!formTimes && !isAccepted ? formTimes : undefined,
              !!formTimes && !isAccepted ? i18n.t('screens.room.toast.declinedSeeBooking') : undefined
            )
          }
        })
      }, 5000)
      // On arrête les appels après 30 secondes si on a pas eu de réponse
      setTimeout(() => {
        clearInterval(interval)
        InteractiveToast.close()
      }, 30000)
      return () => clearInterval(interval)
    }
  }, [graphIdBooking, graphEmailBooking, user, formTimes])

  const resolvePushAndClose = (path?: string, onClick?: () => void) => {
    if (path) {
      navigation.push(path, { origin: 'drawer' })
    }
    if (onClick) {
      onClick()
    }
    if (drawerOpened) {
      setDrawerOpened(false)
    }
  }

  const pushAndClose = (path?: string, onClick?: () => void, confirmNavigation?: boolean) => {
    if (confirmNavigation) {
      Modal.open(() => (
        <ValidationContent
          title={i18n.t('screens.incident.aboutToLeave')}
          description={i18n.t('screens.incident.wontSave')}
          onConfirm={() => {
            formStore.actions.reset()
            Modal.close()
            resolvePushAndClose(path, onClick)
          }}
          cancelButton={true}
          font={Theme.fonts.label}
        />
      ))
    } else {
      resolvePushAndClose(path, onClick)
    }
  }

  const onMouseMoveIcon = (e: any) => {
    setMouseY(e.clientY)
  }

  const goToHomeAndClose = () => pushAndClose('/', undefined, confirmNavigation)

  const goToSitesAndClose = () => pushAndClose('/sites', undefined, confirmNavigation)

  const onEnterKey = (e: React.KeyboardEvent, onClickFunc: () => void) => {
    if (e.key === 'Enter') {
      onClickFunc()
    }
  }

  return (
    <MainContainer>
      <FocusLock disabled={!drawerOpened} autoFocus={false}>
        <DrawerContainer drawerOpened={drawerOpened}>
          <DrawerOverflowContainer drawerOpened={drawerOpened} tabIndex={-1}>
            <DrawerColor drawerOpened={drawerOpened} />
            <ButtonContainer drawerOpened={drawerOpened}>
              <MenuElement
                drawerOpened={drawerOpened}
                onClick={goToHomeAndClose}
                onMouseOver={onMouseMoveIcon}
                tabIndex={0}
                onKeyDown={(e) => onEnterKey(e, goToHomeAndClose)}
                aria-label={i18n.t('label.ariaLabel.drawerShortcut', { title: i18n.t('screens.home.title') })}>
                <DrawerButton
                  size={27}
                  selected={location.pathname === `/${i18n.lang}/` || location.pathname === `/${i18n.lang}`}
                  iconName={screens.home.icon}
                  color={
                    drawerOpened || location.pathname === `/${i18n.lang}/` || location.pathname === `/${i18n.lang}`
                      ? Theme.colors.white
                      : undefined
                  }
                />
                {!drawerOpened && (
                  <OnHoverTitleContainer mouseY={mouseY}>
                    <OnHoverTitle>{i18n.t('screens.home.title')}</OnHoverTitle>
                  </OnHoverTitleContainer>
                )}
                {drawerOpened && (
                  <MenuLabel selected={location.pathname === `/${i18n.lang}/` || location.pathname === `/${i18n.lang}`}>
                    {i18n.t('screens.home.title')}
                  </MenuLabel>
                )}
              </MenuElement>
              {site &&
                featuredItems.map((screen, i) => (
                  <MenuElement
                    drawerOpened={drawerOpened}
                    key={`${screen.name}_${i}`}
                    onClick={() =>
                      pushAndClose(
                        undefined,
                        screen.onClick,
                        screen.url || !screen.path ? undefined : confirmNavigation
                      )
                    }
                    onMouseOver={onMouseMoveIcon}
                    tabIndex={0}
                    onKeyDown={(e) =>
                      onEnterKey(e, () =>
                        pushAndClose(
                          undefined,
                          screen.onClick,
                          screen.url || !screen.path ? undefined : confirmNavigation
                        )
                      )
                    }
                    aria-label={i18n.t('label.ariaLabel.drawerShortcut', {
                      title: screen.path ? i18n.t(`screens.${screen.name.toLowerCase()}.title`) : screen.name,
                    })}>
                    <DrawerButton
                      size={27}
                      key={screen.name}
                      selected={
                        screen.path
                          ? screen.path === '/'
                            ? location.pathname === screen.path
                            : location.pathname.indexOf(screen.path) > -1
                          : screen.url === iframeLink &&
                            currentIframe === screen.name &&
                            location.pathname.indexOf('/iframe') > -1
                      }
                      iconName={screen.icon}
                      color={
                        drawerOpened ||
                        (screen.path &&
                          (screen.path === '/'
                            ? location.pathname === screen.path
                            : location.pathname.indexOf(screen.path) > -1)) ||
                        (screen.url === iframeLink &&
                          location.pathname.indexOf('/iframe') > -1 &&
                          currentIframe === screen.name)
                          ? Theme.colors.white
                          : undefined
                      }
                    />
                    {!drawerOpened && (
                      <OnHoverTitleContainer mouseY={mouseY}>
                        <OnHoverTitle>
                          {screen.path ? i18n.t(`screens.${screen.name.toLowerCase()}.title`) : screen.name}
                        </OnHoverTitle>
                      </OnHoverTitleContainer>
                    )}
                    {drawerOpened && (
                      <MenuLabel
                        selected={
                          screen.path
                            ? screen.path === '/'
                              ? location.pathname === screen.path
                              : location.pathname.indexOf(screen.path) > -1
                            : false
                        }>
                        {screen.path ? i18n.t(`screens.${screen.name.toLowerCase()}.title`) : screen.name}
                      </MenuLabel>
                    )}
                  </MenuElement>
                ))}
              {showSites && (
                <MenuElement
                  drawerOpened={drawerOpened}
                  onClick={goToSitesAndClose}
                  onMouseOver={onMouseMoveIcon}
                  tabIndex={0}
                  onKeyDown={(e) => onEnterKey(e, goToSitesAndClose)}
                  aria-label={i18n.t('label.ariaLabel.drawerShortcut', { title: i18n.t('screens.sites.title') })}>
                  <DrawerButton
                    size={27}
                    selected={location.pathname.endsWith('/sites')}
                    iconName={screens.sites.icon}
                    color={drawerOpened || location.pathname.endsWith('/sites') ? Theme.colors.white : undefined}
                  />
                  {!drawerOpened && (
                    <OnHoverTitleContainer mouseY={mouseY}>
                      <OnHoverTitle>{i18n.t('screens.sites.title')}</OnHoverTitle>
                    </OnHoverTitleContainer>
                  )}
                  {drawerOpened && (
                    <MenuLabel selected={location.pathname.endsWith('/sites')}>
                      {i18n.t('screens.sites.title')}
                    </MenuLabel>
                  )}
                </MenuElement>
              )}
            </ButtonContainer>
          </DrawerOverflowContainer>
          <ArrowContainer drawerOpened={drawerOpened}>
            <ArrowElement
              drawerOpened={drawerOpened}
              tabIndex={0}
              onKeyDown={(e) => onEnterKey(e, () => setDrawerOpened(!drawerOpened))}
              aria-label={i18n.t(`label.ariaLabel.${drawerOpened ? 'close' : 'open'}Drawer`)}>
              <DrawerButton
                size={35}
                onClick={() => setDrawerOpened(!drawerOpened)}
                iconName={drawerOpened ? 'circle_arrow_left' : 'circle_arrow_right'}
                color={drawerOpened ? Theme.colors.white : Theme.colors.darkGrey}
              />
            </ArrowElement>
            {drawerOpened && (
              <AppVersionContainer>
                <AppVersion>{i18n.t('common.version', { version: config.appVersion })}</AppVersion>
              </AppVersionContainer>
            )}
          </ArrowContainer>
        </DrawerContainer>
      </FocusLock>
      {drawerOpened && <BlackScreen onClick={() => setDrawerOpened(!drawerOpened)} />}
    </MainContainer>
  )
}

export default Drawer

const MainContainer = styled.div`
  display: flex;
`
const DrawerOverflowContainer = styled('div')<{ drawerOpened: boolean }>`
  z-index: 9;
  height: calc(100vh - 60px);
  width: ${(props) => (props.drawerOpened ? '330' : '114')}px;
  display: flex;
  flex-direction: column;
  transition: 0.25s ease;
  ${(props) => props.drawerOpened && `overflow: hidden;`}
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;

  ${(props) =>
    props.drawerOpened &&
    `
    ::-webkit-scrollbar-thumb {
      background: ${props.theme.colors.white};
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${props.theme.colors.mediumDarkGrey};
    }
    scrollbar-color: ${props.theme.colors.white} transparent;`}
`
const DrawerContainer = styled('div')<{ drawerOpened: boolean }>`
  position: relative;
  z-index: 9;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  width: ${(props) => (props.drawerOpened ? '330' : '114')}px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 3px 0px 6px ${(props) => props.theme.colors.shadow};
  transition: 0.25s ease;
  ${(props) => props.drawerOpened && `overflow: hidden;`}
`
const DrawerColor = styled('div')<{ drawerOpened: boolean }>`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${(props) => (props.drawerOpened ? 1 : 0)};
  background: ${(props) => props.theme.colors.gradient};
  transition: 0.25s ease;
`
const ButtonContainer = styled('ul')<{ drawerOpened: boolean }>`
  width: ${(props) => (props.drawerOpened ? '330' : '114')}px;
  justify-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 0px 0px 12px 0px;
  margin: 0px;
  margin-top: 24px;
`
const MenuLabel = styled.p<{ selected: boolean }>`
  ${(props) => props.theme.fonts.bodyBold}
  color: ${(props) => (props.selected ? props.theme.colors.darkGrey : props.theme.colors.white)};
  margin-left: 25px;
  margin-right: 10px;
  padding-top: 3px;
`
const ArrowContainer = styled('div')<{ drawerOpened: boolean }>`
  display: flex;
  position: absolute;
  bottom: 0;
  list-style: none;
  align-items: center;
  align-self: flex-start;
  margin-left: 30px;
  padding-bottom: 6px;
`
const BlackScreen = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: calc(100vw + 330px);
  }
`
const OnHoverTitleContainer = styled('div')<{ mouseY: number }>`
  position: absolute;
  z-index: 30;
  left: 90px;
  top: ${(props) => props.mouseY - 15}px;
  display: none;
  flex-direction: row;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
`
const OnHoverTitle = styled('div')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  white-space: nowrap;
`
const MenuElement = styled('li')<{ drawerOpened: boolean }>`
  cursor: pointer;
  display: flex;
  list-style: none;
  align-self: flex-start;
  margin-left: 30px;
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 30px;
  ${(props) =>
    !props.drawerOpened &&
    `&:hover {
    background-color: ${props.theme.colors.mediumLightGrey};
  }`};
  :focus {
    outline: 2px solid ${(props) => (props.drawerOpened ? props.theme.colors.white : props.theme.colors.darkBlue)};
  }
  &:hover ${OnHoverTitleContainer} {
    display: flex;
  }
`
const AppVersionContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
`
const AppVersion = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.white};
`
const ArrowElement = styled('div')<{ drawerOpened: boolean }>`
  :focus {
    outline: 2px solid ${(props) => (props.drawerOpened ? props.theme.colors.white : props.theme.colors.darkBlue)};
  }
`
