import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  getAllRestaurants: (userType: UserType, siteId: string) =>
    assureToken(userType).then((token) =>
      Webservice<Restaurants>(userType, `${config.globalAPIVersion}/restaurants?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getRestaurant: (userType: UserType, restaurantId: string, siteId: string) =>
    assureToken(userType).then((token) =>
      Webservice<Restaurant>(
        userType,
        `${config.globalAPIVersion}/restaurants/${restaurantId}?siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  getMenus: (userType: UserType, restaurantId: string, days: string[], siteId: string) => {
    const daysList = days.join(',')
    return assureToken(userType).then((token) =>
      Webservice<Menus>(
        userType,
        `${config.globalAPIVersion}/restaurants/${restaurantId}/menus?days=${daysList}&siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    )
  },
  getMrsHistory: (userType: UserType, siteId: string) =>
    assureToken(userType).then((token) =>
      Webservice<MrsHistory>(userType, `${config.globalAPIVersion}/account/me/mrs/history?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getReceipt: (
    userType: UserType,
    siteId: string,
    ticketNumber: number,
    ticketDate: string,
    sitePIE: number,
    registerId: number
  ) =>
    assureToken(userType).then((token) =>
      Webservice<ArrayBuffer>(
        userType,
        `${config.globalAPIVersion}/account/me/mrs/receipts?siteId=${siteId}&ticketNumber=${ticketNumber}&ticketDate=${ticketDate}&sitePIE=${sitePIE}&registerId=${registerId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'blob',
        },
        undefined,
        undefined,
        undefined,
        'arraybuffer'
      )
    ),
  getReloadMRSBadgeURL: (userType: UserType, siteId: string, amount: number) =>
    assureToken(userType).then((token) =>
      Webservice<MrsPaymentInformation>(
        userType,
        `${config.globalAPIVersion}/account/me/mrs/badges?siteId=${siteId}&amount=${amount}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),

  getTotemRestaurant: (id: string, siteId: string) =>
    Webservice<TotemRestaurant>(
      'ENGIE',
      `${config.globalAPIVersion}/public/totems/restaurants/${id}?siteId=${siteId}`,
      'GET',
      {
        'X-Api-Key': config.API_KEY,
      }
    ),
}

export default api
