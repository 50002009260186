import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  getAllEditos: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<InformationList>(userType, `${config.globalAPIVersion}/information?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getEdito: (id: string, siteId: string, userType: UserType, lang?: string) =>
    assureToken(userType).then((token) =>
      Webservice<InformationDetail>(userType, `${config.globalAPIVersion}/information/${id}?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
        ...(lang ? { 'Accept-Language': lang } : {}),
      })
    ),
}

export default api
