import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import sanitize from 'utils/Sanitizer'

import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import Button from 'components/button/Button'

interface Props {
  allServices: IncidentService[]
  selectedServices: IncidentService[]
  setSelectedServices: (services: IncidentService[]) => void
  goBack?: () => void
}

const ServicesSelector = ({ allServices, selectedServices, setSelectedServices, goBack }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const [searchText, setSearchText] = React.useState('')

  const filteredServices = React.useMemo(
    () =>
      allServices
        .filter((s) => sanitize(s.name).includes(sanitize(searchText)))
        .sort((s1, s2) => s1.name.localeCompare(s2.name)),
    [searchText]
  )

  const [selectedServicesState, setSelectedServicesState] = React.useState<IncidentService[]>(selectedServices)

  const toggleService = (item: IncidentService) =>
    setSelectedServicesState((services) =>
      !!services.find((s) => s.id === item.id) ? services.filter((s) => s.id !== item.id) : [...services, item]
    )

  const renderService = (item: IncidentService) => {
    const selected = !!selectedServicesState.find((s) => s.id === item.id)
    return (
      <ServiceContainer selected={selected} onClick={() => toggleService(item)}>
        <ServiceTitle>{item.name}</ServiceTitle>
        <Circle>{selected && <SelectedCircle />}</Circle>
      </ServiceContainer>
    )
  }

  const validateSelection = () => {
    setSelectedServices(selectedServicesState)
    Modal.close()
  }

  return (
    <MainContainer>
      <TitleContainer>
        {!!goBack && (
          <CrossContainer
            onClick={() => {
              goBack()
              Modal.close()
            }}>
            <Icons name="chevron_left" size={25} color={Theme.colors.blue} />
          </CrossContainer>
        )}
        <Title>{i18n.t('screens.incident.form.whichServices')}</Title>
        <CrossContainer onClick={Modal.close} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" size={25} color={Theme.colors.blue} />
        </CrossContainer>
      </TitleContainer>

      <SearchContainer>
        <SearchBarContainer>
          <SearchBar
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={i18n.t('screens.incident.search')}
            autoFocus
          />
          {searchText.length > 0 && (
            <CrossContainer
              onClick={() => {
                setSearchText('')
              }}>
              <Icons name="cross" size={17} color={Theme.colors.blue} />
            </CrossContainer>
          )}
          <IconContainer>
            <Icons name="search" size={15} color={Theme.colors.blue} />
          </IconContainer>
        </SearchBarContainer>
      </SearchContainer>

      <ListContainer>
        {allServices.length > 0 ? (
          filteredServices.length > 0 ? (
            <CategoryListContainer>{filteredServices.map(renderService)}</CategoryListContainer>
          ) : (
            <ErrorMessageContainer>
              <ErrorMessage>{i18n.t('errors.incident.noServicesSearch')}</ErrorMessage>
            </ErrorMessageContainer>
          )
        ) : (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('errors.incident.noServices')}</ErrorMessage>
          </ErrorMessageContainer>
        )}
      </ListContainer>
      <ButtonContainer>
        <Button
          noFlex
          horizontalPadding={40}
          label={i18n.t('screens.incident.form.validateRequestServices', { count: selectedServicesState.length })}
          onClick={validateSelection}
          disabled={selectedServicesState.length === 0}
          font={Theme.fonts.h1}
          fontSize={18}
        />
      </ButtonContainer>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 80vh;
  width: 444px;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 90vw;
  }
`
const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px 30px 10px;
  }
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  padding: 12px;
`
const CrossContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`
const SearchContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`
const SearchBarContainer = styled('div')`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  flex: 1;
  margin: 0px 20px;
  height: 38px;
  border-radius: 52px;
  border: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`
const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 40px;
  overflow-y: scroll;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px 30px;
  }
`
const ErrorMessageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
`
const CategoryListContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const Title = styled('h1')`
  display: flex;
  flex: 1;
  margin: 0px;
  ${(props) => props.theme.fonts.title};
  overflow-wrap: anywhere;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

const SearchBar = styled('input')`
  flex: 1;
  ${(props) => props.theme.fonts.label}
  margin-left: 24px;
  border: 0;
  outline: 0;
`

const ServiceContainer = styled('div')<{ selected: boolean }>`
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 15px 0px;
  padding: 5px;
  ${(props) => props.selected && `background-color: ${props.theme.colors.paleBlue}`};
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
  cursor: pointer;
`

const ServiceTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkgrey};
  margin: 0px;
`

const Circle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  max-width: 25px;
  width: 25px;
  min-height: 25px;
  max-height: 25px;
  height: 25px;
  border: 2px solid ${(props) => props.theme.colors.blue};
  border-radius: 15px;
  margin-right: 2px;
  margin-left: 38px;
`

const SelectedCircle = styled('div')`
  width: 19px;
  height: 19px;
  border-radius: 15px;
  background-color: ${(props) => props.theme.colors.blue};
`

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: 20px;
`

export default ServicesSelector
