import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  getAllSites: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<SiteList>(userType, `${config.globalAPIVersion}/sites`, 'GET', { Authorization: `Bearer ${token}` })
    ),
  getAllSitesLight: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<SiteLightList>(userType, `${config.globalAPIVersion}/sites/light`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getSite: (id: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<Site>(userType, `${config.globalAPIVersion}/sites/${id}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getFeatures: (id: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<FeatureList>(userType, `${config.globalAPIVersion}/sites/${id}/features`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getUserSites: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<PersonalSites>(userType, `${config.globalAPIVersion}/account/me/sites`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getAllRegions: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<RegionsList>(userType, `${config.globalAPIVersion}/sites/regions`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
}

export default api
