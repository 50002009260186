const colors: Colors = {
  gradient: 'linear-gradient(135deg, #00BCFD 0%, #23D2B5 100%)',
  black: '#000',
  white: '#fff',
  whiteTransparent90: '#FFFFFFE6',
  transparentWhite: '#ffffff33',
  shadow: 'rgba(151, 151, 151, 0.3)',
  blue: '#00AAFF',
  skyBlue: '#00BCFD',
  darkBlue: '#005FAA',
  paleBlue: '#EEF5FD',
  turquoise: '#6ED2B1',
  green: '#76B634',
  lightGrey: '#F8F8F8',
  mediumLightGrey: '#F3F3F3',
  mediumDarkGrey: '#EBEBEB',
  darkGrey: '#353A3D',
  placeholderGrey: '#909090',
  lightWhite: '#EDF5FA',
  darken: 'rgba(34, 34, 35, 0.4)',
  middleGrey: '#9D9CA9',
  iconGrey: '#3D3D3D',
  scrollBar: '#c1c0c7',
  red: '#E74A50',
  orange: '#F78A31',
  strongTurquoise: '#6ED2B1',
  yellow: '#FBDB3C',
  occupied: '#FF7A70',
  available: '#00D88D',
  blue10p: '#EDF5FA', // blue à 10%
  outline: '#005FAA',
  loginWelcome: '#00AAFF',
  occupationError: '#ABAAB6',

  A: '#2D7E43',
  B: '#97BA38',
  C: '#F0CA0D',
  D: '#D57B1A',
  E: '#C53419',

  toastInfoBackground: '#CCEEFF',
  toastInfoText: '#005FAA',
  toastErrorBackground: '#F8C9CA',
  toastErrorText: '#E74A50',
  toastSuccessBackground: '#D3F1E8',
  toastSuccessText: '#27906E',
}

const theme: Theme = {
  colors: {
    ...colors,
  },
  fonts: {
    h1: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '22px',
      lineHeight: '25px',
      letterSpacing: '0.5px',
      color: colors.black,
    },
    h1Bold: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '22px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: colors.black,
    },
    h2Bold: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '27px',
      letterSpacing: '0.3px',
      color: colors.black,
    },
    h3Bold: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '18px',
      lineHeight: '22px',
      letterSpacing: '0.5px',
      color: colors.black,
    },
    title: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '28px',
      letterSpacing: '0.5px',
      color: colors.black,
    },
    bodyBold: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.2px',
      color: colors.black,
    },
    body: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0.5px',
      color: colors.black,
    },
    subtitle: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.5px',
      color: colors.black,
    },
    menuSubtitle: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.5px',
      color: colors.black,
    },
    subtitleBold: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.5px',
      color: colors.black,
    },
    labelBold: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.1px',
      color: colors.black,
    },
    label: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '14px',
      color: colors.black,
    },
    linkUnderlined: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '14px',
      textDecoration: 'underline',
      color: colors.black,
    },
    footerText: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '16px',
      color: colors.black,
    },
    contentTitle: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '20px',
      lineHeight: '22px',
      letterSpacing: '0.5px',
      color: colors.black,
    },
    contentTitleBold: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '22px',
      letterSpacing: '0.5px',
      color: colors.black,
    },
    thinTitle: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '24px',
      lineHeight: '30px',
      letterSpacing: '-0.5px',
      color: colors.black,
    },
    validationCodeNumber: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '100px',
      color: colors.black,
    },
    bigInput: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '48px',
      color: colors.black,
    },
    totemBig: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '36px',
      letterSpacing: '0.5px',
      lineHeight: 'normal',
      color: colors.black,
    },
    totemMiddle: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      letterSpacing: '0.5px',
      lineHeight: 'normal',
      color: colors.black,
    },
    totemLight: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '28px',
      letterSpacing: '0.5px',
      lineHeight: '24px',
      color: colors.black,
    },
    totemPercentage: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '36px',
      letterSpacing: '0.3px',
      lineHeight: '114%',
      color: colors.black,
    },
    totemTransport: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '22px',
      letterSpacing: '0.5px',
      lineHeight: '26.4px',
      color: colors.black,
    },
  },

  constants: {
    userPicture: {
      large: {
        size: 96,
        borderRadius: 48,
      },
      small: {
        size: 40,
        borderRadius: 30,
      },
      smaller: {
        size: 32,
        borderRadius: 16,
      },
    },
    shadow: {
      card: {
        'box-shadow': '0px 0px 15px rgba(0, 0, 0, 0.13)',
      },
    },
  },
}

export default theme
