import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys } from 'utils/analytics'

import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as contactStore from 'directory/contactStore'
import useReducer from 'store/useReducer'

import GraphDirectory from './GraphDirectory'
import FavoriteGraphContacts from './FavoriteGraphContacts'
import EngieDirectory from './engie/EngieDirectory'
import FavoriteContactsEngie from './engie/FavoriteContactsEngie'
import ExternalContact from './ExternalContact'
import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import Alert from 'components/alert/Alert'

type TabType = 'search' | 'favorites' | 'external'

interface Props {
  title: string
  onSelectContact: (contact: UserData, external?: boolean) => void
  engieDirectory?: boolean
  addVisitorMode?: boolean
  modalType?: 'modal' | 'alert'
  searchAriaLabel?: string
  visitors?: Visitor[]
}

const DirectoryScreen = ({
  title,
  addVisitorMode = false,
  onSelectContact,
  engieDirectory,
  modalType = 'modal',
  searchAriaLabel,
  visitors = [],
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const user = useReducer(userStore.store, (s) => s.user)
  const favorites = useReducer(contactStore.store, (s) => s.favorites).filter((favorite) =>
    engieDirectory ? !!favorite.engieUserId : !!favorite.idGraph
  )

  const [selectedTab, setSelectedTab] = React.useState<TabType>(
    favorites.length > 0 || visitors.length > 0 ? 'favorites' : 'search'
  )

  React.useEffect(() => {
    if (site && user) {
      analytics.track({ screenName: analyticsKeys.directory, userType: user.type, currentSite: site, mySites })
    }
  }, [])

  const closeDirectory = () => {
    if (modalType === 'modal') {
      Modal.close()
    } else if (modalType === 'alert') {
      Alert.close()
    }
  }

  const selectSearch = () => setSelectedTab('search')

  const selectFavorites = () => setSelectedTab('favorites')

  const selectExternal = () => setSelectedTab('external')

  const onSelectEngieContact = (contact: EngieUser) =>
    onSelectContact({
      email: contact.userEmail,
      firstname: contact.firstName,
      lastname: contact.lastName,
      engieId: contact.engieUserId,
    })

  const onSelectGraphContact = (contact: DirectoryUser) =>
    onSelectContact({
      email: contact.mail,
      firstname: contact.givenName,
      lastname: contact.surname,
      graphId: contact.id,
    })

  const onSelectExternalContact = (email: string) => onSelectContact({ email, firstname: '', lastname: '' }, true)

  return (
    <MainContainer fixedHeight={selectedTab !== 'external'}>
      <CrossContainer>
        <Title>{title}</Title>
        <IconContainer onClick={closeDirectory} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" color={Theme.colors.darkGrey} size={25} />
        </IconContainer>
      </CrossContainer>
      <TabsContainer>
        <Tab selected={selectedTab === 'favorites'} onClick={selectFavorites}>
          <TabTitle selected={selectedTab === 'favorites'}>{i18n.t('screens.directory.myFavorites')}</TabTitle>
        </Tab>
        <Tab selected={selectedTab === 'search'} onClick={selectSearch}>
          <TabTitle
            selected={selectedTab === 'search'}
            aria-label={i18n.t('label.ariaLabel.directory.searchInDirectory')}>
            {i18n.t('screens.directory.search')}
          </TabTitle>
        </Tab>
        {addVisitorMode && (
          <Tab selected={selectedTab === 'external'} onClick={selectExternal}>
            <TabTitle
              selected={selectedTab === 'external'}
              aria-label={i18n.t('label.ariaLabel.directory.addExternal')}>
              {i18n.t('screens.directory.external')}
            </TabTitle>
          </Tab>
        )}
      </TabsContainer>
      {selectedTab === 'search' ? (
        engieDirectory ? (
          <EngieDirectory onSelectContact={onSelectEngieContact} searchAriaLabel={searchAriaLabel} />
        ) : (
          <GraphDirectory onSelectContact={onSelectGraphContact} searchAriaLabel={searchAriaLabel} />
        )
      ) : selectedTab === 'favorites' ? (
        engieDirectory ? (
          <FavoriteContactsEngie onSelectContact={onSelectEngieContact} />
        ) : (
          <FavoriteGraphContacts onSelectContact={onSelectGraphContact} visitors={visitors} />
        )
      ) : (
        <ExternalContact onSelectContact={onSelectExternalContact} />
      )}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')<{ fixedHeight: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  padding-bottom: 30px;
  ${(props) => props.fixedHeight && 'height: 595px'};
  width: 480px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    ${(props) => props.fixedHeight && 'height: 580px'};
    width: calc(100vw - 70px);
  }
`
const TabsContainer = styled('div')`
  display: flex;
  background-color: ${(props) => props.theme.colors.paleBlue};
`
const CrossContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  margin: 12px 20px 0 40px;
`
const IconContainer = styled('button')`
  display: flex;
  align-items: center;
  padding: 0px;
  :focus {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const Tab = styled('button')<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 0 10px;
  flex: 1;
  cursor: pointer;
  ${(props) => props.selected && `border-bottom: solid 2px ${props.theme.colors.blue};`}
`

// TEXTES

const TabTitle = styled('p')<{ selected: boolean }>`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.middleGrey)};
  margin: 0px;
`
const Title = styled('h2')`
  ${(props) => props.theme.fonts.contentTitle};
  color: ${(props) => props.theme.colors.darkGrey};
`

export default DirectoryScreen
