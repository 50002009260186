import React from 'react'

import styled from 'theme/styled-components'
import useI18n from 'i18n/useI18n'
import useTheme from 'theme/useTheme'

import Modal from 'components/modal/Modal'
import Alert from 'components/alert/Alert'
import UserList from './UserList'
import UserPicture from 'authent365/utils/UserPicture'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'

interface Props {
  peopleList: People[]
  clickable?: boolean
  withAlert?: boolean
  poisInfos?: AreaLight[]
  refreshData?: () => void
  navigation: Navigation
  myDesk?: string
  selectionModalTitle?: string
}

const pictureNumberToDisplay = 3

const PicturesList = ({
  peopleList,
  clickable = false,
  withAlert,
  poisInfos,
  refreshData,
  navigation,
  myDesk,
  selectionModalTitle,
}: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const favoriteUsers = useReducer(userStore.store, (s) => s.favoriteUsers) || []

  const orderedListWithPictures = React.useMemo(() => {
    const filteredUniqueList = peopleList.reduce((acc, curr) => {
      if (!acc.find((p) => p.id === curr.id)) {
        acc.push(curr)
      }
      return acc
    }, [] as People[])

    const nonFavoriteUsersSorted = filteredUniqueList
      .filter((p) => favoriteUsers.indexOf(p.id) < 0)
      .sort((a, b) => a.lastname.localeCompare(b.lastname))
    const favoriteUsersSorted = filteredUniqueList
      .filter((p) => favoriteUsers.indexOf(p.id) >= 0)
      .sort((a, b) => a.lastname.localeCompare(b.lastname))
    return [...favoriteUsersSorted, ...nonFavoriteUsersSorted]
  }, [peopleList, favoriteUsers])

  const onClickPicture: React.MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.stopPropagation()
    if (clickable) {
      if (withAlert) {
        Alert.open(
          () => (
            <UserList
              title={i18n.t('screens.planning.userList.title', { count: orderedListWithPictures.length })}
              peopleList={orderedListWithPictures}
              closeModal={Alert.close}
              searchAriaLabel={i18n.t('label.ariaLabel.planning.searchContactOnUnit')}
              poisInfos={poisInfos}
              refreshData={refreshData}
              navigation={navigation}
              myDesk={myDesk}
              selectionModalTitle={selectionModalTitle}
            />
          ),
          true
        )
      } else {
        Modal.open(() => (
          <UserList
            title={i18n.t('screens.planning.userList.title', { count: orderedListWithPictures.length })}
            peopleList={orderedListWithPictures}
            closeModal={Modal.close}
            searchAriaLabel={i18n.t('label.ariaLabel.planning.searchContactOnUnit')}
            poisInfos={poisInfos}
            refreshData={refreshData}
            navigation={navigation}
            myDesk={myDesk}
            selectionModalTitle={selectionModalTitle}
          />
        ))
      }
    }
  }

  const renderPicture = (people: People, index: number) => {
    return index < pictureNumberToDisplay ? (
      <UserPicture
        key={people.id}
        color={Theme.colors.blue}
        size="smaller"
        userId={people.email}
        userFirstName={people.firstname}
        userLastName={people.lastname}
      />
    ) : index === pictureNumberToDisplay ? (
      <UserPicture
        key={people.id}
        color={Theme.colors.blue}
        size="smaller"
        customValue={i18n.t('components.pictureList.initials', { number: orderedListWithPictures.length - index })}
      />
    ) : null
  }

  if (orderedListWithPictures.length === 0) {
    return null
  }

  return (
    <MainContainer clickable onClick={onClickPicture} onKeyDown={(evt) => evt.stopPropagation()}>
      {orderedListWithPictures.slice(0, 4).map(renderPicture)}
    </MainContainer>
  )
}

export default PicturesList

const MainContainer = styled.button<{ clickable: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`
