import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useReducer from 'store/useReducer'
import * as roomStore from './roomStore'
import * as themeStore from 'theme/store'
import * as userStore from 'store/user/user'

import { AllTimes } from './types'
import utils from './utils'
import { I18n } from 'i18n/types'

import Button from 'components/button/Button'
import Icons from 'components/icons/Icons'
import RoomImage from 'components/image/RoomImage'

interface Props {
  room: Area
  index: number
  list: Area[]
  i18n: I18n
  navigation: Navigation
  confirmReservation: (name: string, o365Id: string) => void
  formTimes: AllTimes
  bookingSoon: boolean
}

const RoomInfo = ({ room, index, list, i18n, navigation, confirmReservation, formTimes, bookingSoon }: Props) => {
  const [Theme] = useTheme()
  const isLast = list.length === index + 1

  const user = useReducer(userStore.store, (s) => s.user)
  const isNUC = React.useMemo(() => !!user && !!user.email && user.email.includes('@bnl.engie.com'), [user])

  const isFavorite = !!useReducer(roomStore.store, (s) => s.favorites).find((f) => f === room.id)
  const isAccessible = useReducer(themeStore.store, (s) => s.theme) === 'accessible'

  const [statusName, setStatusName] = React.useState<string>()
  const [statusColor, setStatusColor] = React.useState(Theme.colors.darkGrey)

  const goToDetails = () => navigation.push(`/room/${room.id}`, { formTimes, uniqueName: room.uniquePlaceName })

  React.useEffect(() => {
    if (room) {
      setStatusName(utils.getStatusName(room.occupationStatus, room.bookable, room.booked))
      const color = isAccessible
        ? room.contrastedColorCode || room.colorCode
        : room.colorCode || room.contrastedColorCode
      color && setStatusColor(color)
    }
  }, [room])

  return (
    <div key={room.id}>
      <RoomContainer
        tabIndex={0}
        onClick={goToDetails}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            goToDetails()
          }
        }}>
        <ImageContainer>
          <RoomImage iconSize={40} width={70} src={room.picture} roomType={room.placeType} />
        </ImageContainer>

        <RommInfoContainer>
          <NameContainer>
            <RoomName>{room.name}</RoomName>
            {isFavorite && (
              <HeartIconContainer>
                <Icons name="heart-filled" color={Theme.colors.darkGrey} size={13} />
              </HeartIconContainer>
            )}
          </NameContainer>

          {bookingSoon && (
            <RoomStatusContainer>
              <StatusIndicator statusColor={statusColor} />
              <StatusText statusColor={statusColor}>
                {i18n.t([`screens.room.status.${statusName}`, 'screens.room.status.UNKNOWN'])}
              </StatusText>
            </RoomStatusContainer>
          )}

          <IconInfoContainer>
            <Icons name="user" size={15} color={Theme.colors.darkGrey} ariaLabel={i18n.t('label.svg.capacity')} />
            <Capacitytext>{i18n.t('screens.room.capacity', { count: room.capacity })}</Capacitytext>
          </IconInfoContainer>
        </RommInfoContainer>

        {room.bookable && !isNUC ? (
          <CenterButtonContainer>
            <ReservationButtonContainer onClick={(evt) => evt.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
              <Button
                label={i18n.t('screens.room.reservation')}
                onClick={() => {
                  if (room.office365Id) {
                    confirmReservation(room.name, room.office365Id)
                  }
                }}
                verticalPadding={6}
                horizontalPadding={0}
                font={Theme.fonts.label}
                ariaLabel={i18n.t('label.ariaLabel.room.makeReservation')}
              />
            </ReservationButtonContainer>
          </CenterButtonContainer>
        ) : (
          <NotBookableContainer>
            <NotBookable>{i18n.t('screens.room.notBookable')}</NotBookable>
          </NotBookableContainer>
        )}
      </RoomContainer>
      {!isLast && <BlueLineBreak />}
    </div>
  )
}

// CONTAINERS

const RoomContainer = styled('div')`
  cursor: pointer;
  display: flex;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-height: 70px;
  margin: 15px 0px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const ImageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.mediumDarkGrey};
  min-height: 70px;
  width: 70px;
`
const RommInfoContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 155px;
  margin: 5px 0px 7px 10px;
`
const RoomStatusContainer = styled('div')`
  display: flex;
  align-items: center;
`
const StatusText = styled('p')<{ statusColor: string }>`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.statusColor};
  margin: 0px;
`
const IconInfoContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 2px;
`
const CenterButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`
const ReservationButtonContainer = styled('div')`
  display: flex;
  width: 72px;
`
const NotBookableContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 10px;
`
const NameContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`
const HeartIconContainer = styled('div')`
  margin-bottom: 4px;
`

// TEXTES

const RoomName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px 5px 0px 0px;
`
const Capacitytext = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  margin-left: 5px;
`
const NotBookable = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.middleGrey};
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 50px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  border-radius: 2px;
`
const StatusIndicator = styled('div')<{ statusColor: string }>`
  width: 4px;
  height: 4px;
  background-color: ${(props) => props.statusColor};
  border-radius: 2px;
  margin: 0px 5px;
`

export default RoomInfo
