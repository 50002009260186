import * as React from 'react'
import styled from 'theme/styled-components'
import Theme from 'theme/Theme'

import Button from 'components/button/Button'
import Icons from 'components/icons/Icons'

import useI18n from 'i18n/useI18n'

import { findLevelWording } from '../utils'

interface Props {
  room: Area
  onClose: () => void
  onSelect: () => void
}

const RoomMapInfo = ({ room, onClose, onSelect }: Props) => {
  const i18n = useI18n()

  return (
    <Place>
      <PlaceTitle>{room.name}</PlaceTitle>
      {!!room.description && <PlaceInfo>{room.description}</PlaceInfo>}
      <PlaceFloor>{findLevelWording(room.floor, i18n, room.floorCustomName)}</PlaceFloor>

      <Button label={i18n.t('common.select')} onClick={onSelect} />

      <Close onClick={onClose}>
        <Icons name="cross" color={Theme.colors.blue} size={30} />
      </Close>
    </Place>
  )
}

const Place = styled('div')`
  width: 310px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;

  padding: 20px;
  gap: 20px;

  position: relative;

  display: flex;
  flex-direction: column;

  ${(props) => props.theme.constants.shadow.card};
`

const PlaceTitle = styled('span')`
  ${(props) => props.theme.fonts.h3Bold};
  margin-right: 30px;
`

const PlaceInfo = styled('span')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.darkGrey};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin: 0;
`

const PlaceFloor = styled('span')`
  ${(props) => props.theme.fonts.body};
`

const Close = styled('button')`
  position: absolute;
  top: 15px;
  right: 15px;
`

export default RoomMapInfo
