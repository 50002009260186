import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import useI18n from 'i18n/useI18n'

import Icons from 'components/icons/Icons'
import UserPicture from 'authent365/utils/UserPicture'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as planningStore from 'planning/planningStore'
import * as siteStore from 'sites/store/siteStore'

import api from '../api'

import utils from 'utils/strings'
import { filterUserList, filterPeopleListAndSortByFav, filterVisitorList } from './utils'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { getBookingReservationTime, sortDesksNames } from 'planning/utils'
import Logger from 'utils/Logger'

import Loader from 'react-loader-spinner'
import LazyContainer from 'react-lazyload'
import SelectionOnPlanModal from '../reservation/SelectionOnPlanModal'

interface Props {
  searchUsers?: SearchFunction
  setSelectedId?: (user: UserResponse) => void
  setSelectedVisitor?: (visitor: PersonalVisit) => void
  autoFocus?: boolean
  title?: string
  searchFavorites?: SearchFunction
  peopleList?: People[]
  closeModal: () => void
  searchAriaLabel?: string
  poisInfos?: AreaLight[]
  refreshData?: () => void
  navigation: Navigation
  myDesk?: string
  selectionModalTitle?: string
  withVisitors?: boolean
  visits?: PersonalVisit[]
  visitorStatus?: 'loading' | 'ok' | 'error'
  parkingMode?: boolean
  selectedVisitor?: PersonalVisit
}

type SearchFunction = (parameter: string, userType: UserType, siteId: string) => Promise<UserResponse[]>

type Tab = 'SEARCH' | 'FAVORITES' | 'VISITORS'

export type Status = 'notStarted' | 'error' | 'loading' | 'ready'

const UserList = ({
  searchUsers,
  setSelectedId,
  autoFocus = false,
  title,
  searchFavorites,
  peopleList,
  closeModal,
  searchAriaLabel,
  poisInfos,
  refreshData,
  navigation,
  myDesk,
  selectionModalTitle,
  withVisitors,
  setSelectedVisitor,
  visits = [],
  visitorStatus,
  parkingMode,
  selectedVisitor,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const [searchText, setSearchText] = React.useState('')
  const [researchList, setResearchList] = React.useState<UserResponse[]>([])
  const [favoritesCompleteList, setFavoritesCompleteList] = React.useState<UserResponse[]>([])
  const [researchStatus, setResearchStatus] = React.useState<Status>('notStarted')

  const favoritePeoples = useReducer(userStore.store, (s) => s.favoriteUsers) || []
  const userZapfloorId = useReducer(planningStore.store, (s) => s.zapfloorUserId)
  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const [tab, setTab] = React.useState<Tab>(favoritePeoples.length > 0 ? 'FAVORITES' : 'SEARCH')

  const favoritesFiltered = React.useMemo(() => filterUserList(favoritesCompleteList, searchText), [
    favoritesCompleteList,
    searchText,
  ])

  const peopleListFiltered = React.useMemo(
    () => (peopleList ? filterPeopleListAndSortByFav(peopleList, searchText, favoritePeoples) : undefined),
    [peopleList, searchText, favoritePeoples]
  )

  const formattedVisits = React.useMemo(() => {
    const removeDuplicates = visits.reduce((acc, cur) => {
      const findDuplicate = acc.find((visit) => visit.multiDayGroupId === cur.multiDayGroupId)
      if (!findDuplicate) {
        acc.push(cur)
      }
      return acc
    }, [] as PersonalVisit[])
    return removeDuplicates
  }, [visits])

  const visitorSorted = React.useMemo(() => filterVisitorList(formattedVisits, searchText), [
    formattedVisits,
    searchText,
  ])

  const resultList = React.useMemo(() => (tab === 'FAVORITES' ? favoritesFiltered : researchList), [
    tab,
    favoritesFiltered,
    researchList,
  ])

  React.useEffect(() => {
    if (!!peopleList) {
      setResearchStatus('ready')
    }
  }, [])

  React.useEffect(() => {
    if (tab === 'FAVORITES' && !peopleList) {
      if (favoritePeoples.length < 1) {
        setResearchStatus('ready')
        setFavoritesCompleteList([])
      } else {
        setResearchStatus('loading')
        !!user &&
          !!currentSite &&
          !!searchFavorites &&
          !!userZapfloorId &&
          searchFavorites(favoritePeoples.join(','), user.type, currentSite.id)
            .then((res) => {
              setResearchStatus('ready')
              setFavoritesCompleteList(res)
            })
            .catch(() => {
              setResearchStatus('error')
              setFavoritesCompleteList([])
            })
      }
    }
  }, [favoritePeoples, tab])

  React.useEffect(() => {
    if (tab === 'SEARCH' && !!searchUsers && !peopleList) {
      if (searchText.length < 3) {
        setResearchStatus('notStarted')
        setResearchList([])
      } else {
        setResearchStatus('loading')
        const timer = setTimeout(
          () =>
            !!user &&
            !!currentSite &&
            !!userZapfloorId &&
            searchUsers(searchText, user.type, currentSite.id)
              .then((res) => {
                setResearchStatus('ready')
                setResearchList(res.sort((a, b) => a.attributes.lastname.localeCompare(b.attributes.lastname)))
              })
              .catch(() => {
                setResearchStatus('error')
                setResearchList([])
              }),
          500
        )
        return () => clearTimeout(timer)
      }
    }
  }, [searchText, tab])

  const resetSearchText = () => {
    setSearchText('')
    if (!peopleList) {
      setResearchStatus('notStarted')
    }
  }

  const onUserClicked = (contact: People, general?: boolean) => {
    if (
      !!user &&
      !!currentSite &&
      !!currentSite.locationId &&
      !!contact.timeFrom &&
      !!contact.timeTo &&
      !!contact.zone &&
      !!contact.initialDate &&
      !!poisInfos &&
      !!userZapfloorId
    ) {
      const reservationTime = getBookingReservationTime(contact.timeFrom, contact.timeTo)
      api.zapfloor
        .getHotDeskAvailable(
          user.type,
          currentSite.id,
          i18n.t('screens.planning.secondForm.dateToSend', {
            date: contact.initialDate,
          }),
          currentSite.locationId,
          reservationTime,
          contact.zone.attributes.access_group_id,
          userZapfloorId,
          contact.zone.attributes.unit_id
        )
        .then((res) => {
          const newDeskAreas = poisInfos.reduce((prev, curr) => {
            const currDesk = res.find((desk) => desk.attributes.hot_desk_id === curr.zapfloorId)
            return !!currDesk ? [...prev, { zapfloorDesk: currDesk, area: curr }] : prev
          }, [] as ZapfloorDeskArea[])

          return renderOnPlanModal(sortDesksNames(newDeskAreas), contact, general)
        })
        .catch((err) => Logger.error('Error when fetching hot desks available : ', err))
    }
  }

  const renderOnPlanModal = (deskAreas: ZapfloorDeskArea[], contact: People, general?: boolean) => {
    if (
      deskAreas.length > 1 &&
      !!refreshData &&
      !!contact.initialDate &&
      !!userZapfloorId &&
      !!contact.timeFrom &&
      !!contact.timeTo &&
      !!contact.zone
    ) {
      const reservationTime = getBookingReservationTime(contact.timeFrom, contact.timeTo)
      const uniqueNameZone = contact.desk?.uniquePlaceName.split('/')[0]
      const teamId = contact?.zone?.attributes.access_group_id
      const initialPlace = deskAreas.find((area) => area.area.uniquePlaceName === contact.desk?.uniquePlaceName)

      Modal.open(
        () => (
          <SelectionOnPlanModal
            userId={userZapfloorId}
            teamId={teamId}
            closeModal={Modal.close}
            reservationTime={reservationTime}
            refreshData={refreshData}
            unitAreaUniqueName={uniqueNameZone}
            navigation={navigation}
            deskAreas={deskAreas}
            initialDate={contact.initialDate!}
            location={contact.zone!}
            initialPlace={!general ? initialPlace : undefined}
            myDesk={myDesk}
            isFromContact
            title={selectionModalTitle}
            parkingMode={false}
            visits={visits}
            visitorMode={!!withVisitors}
            zapfloorPois={poisInfos || []}
            selectedVisitor={selectedVisitor}
          />
        ),
        true
      )
    }
  }

  const renderPeopleElement = (people: People, index: number, array: People[]) => {
    const { id, lastname, firstname, email } = people
    const timeSlot =
      !!people.timeFrom && !!people.timeTo
        ? getBookingReservationTime(people.timeFrom.slice(0, 5), people.timeTo.slice(0, 5))
        : undefined

    return (
      <ElementContainer key={index} onClick={() => onUserClicked(people)}>
        <UserContainer>
          <LazyContainer offset={200} throttle={150} overflow>
            <UserPicture
              key={people.id}
              color={Theme.colors.blue}
              size="smaller"
              userFirstName={firstname}
              userLastName={lastname}
              userId={email}
            />
          </LazyContainer>
          <UserInfoContainer>
            <NameContainer>
              {lastname.toUpperCase()} {utils.formatNames(firstname)}
            </NameContainer>
            {!!timeSlot && timeSlot !== 'ALL_DAY' && (
              <RowContainer>
                <IconTimeSlotContainer>
                  <Icons name="clock" color={Theme.colors.blue} size={14} />
                </IconTimeSlotContainer>
                <EmailContainer>{i18n.t(`screens.desk.reservationTime.${timeSlot}`)}</EmailContainer>
              </RowContainer>
            )}
            <EmailContainer>{email}</EmailContainer>
            {!!people.desk && (
              <DeskInfoContainer>
                <Icons
                  name={'desk'}
                  size={16}
                  color={Theme.colors.blue}
                  ariaLabel={i18n.t('screens.planning.iconsAction.zone')}
                />
                <Info>{people.desk.name}</Info>
              </DeskInfoContainer>
            )}
          </UserInfoContainer>
          {favoritePeoples.indexOf(id) < 0 ? (
            <LikeContainer
              onClick={(e) => {
                e.stopPropagation()
                userStore.actions.setFavoriteUsers([...favoritePeoples, id])
              }}>
              <Icons name="heart" color={Theme.colors.blue} size={26} />
            </LikeContainer>
          ) : (
            <LikeContainer
              onClick={(e) => {
                e.stopPropagation()
                userStore.actions.setFavoriteUsers(favoritePeoples.filter((p) => p !== id))
              }}>
              <Icons name="heart-filled" color={Theme.colors.blue} size={26} />
            </LikeContainer>
          )}
        </UserContainer>
      </ElementContainer>
    )
  }

  const renderElement = (user: UserResponse, index: number, array: UserResponse[]) => {
    const { lastname, firstname, email } = user.attributes
    return (
      <ElementContainer key={user.id}>
        {index !== 0 && lastname[0].toUpperCase() === array[index - 1].attributes.lastname[0].toUpperCase() ? (
          <BlueLine />
        ) : (
          <LetterContainer>
            <Letter>
              {index === 0 ? lastname[0].toUpperCase() : array[index].attributes.lastname[0].toUpperCase()}
            </Letter>
          </LetterContainer>
        )}
        <UserContainer onClick={() => !!setSelectedId && setSelectedId(user)}>
          <LazyContainer offset={200} throttle={150} overflow>
            <UserPicture
              color={Theme.colors.blue}
              size="smaller"
              userFirstName={firstname}
              userLastName={lastname}
              userId={email}
            />
          </LazyContainer>
          <UserInfoContainer>
            <NameContainer>
              {user.attributes.lastname.toUpperCase()} {utils.formatNames(firstname)}
            </NameContainer>
            <EmailContainer>{email}</EmailContainer>
          </UserInfoContainer>
          {favoritePeoples.indexOf(user.id) < 0 ? (
            <LikeContainer
              onClick={(e) => {
                e.stopPropagation()
                userStore.actions.setFavoriteUsers([...favoritePeoples, user.id])
              }}>
              <Icons name="heart" color={Theme.colors.blue} size={26} />
            </LikeContainer>
          ) : (
            <LikeContainer
              onClick={(e) => {
                e.stopPropagation()
                userStore.actions.setFavoriteUsers(favoritePeoples.filter((p) => p !== user.id))
              }}>
              <Icons name="heart-filled" color={Theme.colors.blue} size={26} />
            </LikeContainer>
          )}
        </UserContainer>
      </ElementContainer>
    )
  }

  const renderVisitor = (visitor: PersonalVisit, index: number, array: PersonalVisit[]) => {
    const { visitId, visitorLastName, visitorFirstName } = visitor
    const findAllVisits = visits.filter((visit) => visit.multiDayGroupId === visitor.multiDayGroupId)
    const visitAlreadyBooked = findAllVisits.filter(
      (visit) =>
        visit.zapfloorBookings &&
        visit.zapfloorBookings.find((booking) =>
          parkingMode ? booking.bookingType === 'PARKING' : booking.bookingType === 'DESK'
        )
    )
    const noMoreBookingsAvailable = withVisitors ? findAllVisits.length === visitAlreadyBooked.length : false
    return (
      <ElementContainer key={visitId}>
        {index !== 0 && visitorLastName[0].toUpperCase() === array[index - 1].visitorLastName[0].toUpperCase() ? (
          <BlueLine />
        ) : (
          <LetterContainer>
            <Letter>
              {index === 0 ? visitorLastName[0].toUpperCase() : array[index].visitorLastName[0].toUpperCase()}
            </Letter>
          </LetterContainer>
        )}
        <UserContainer
          onClick={() => {
            if (!!setSelectedVisitor && !noMoreBookingsAvailable) {
              setSelectedVisitor(visitor)
            }
          }}>
          <LazyContainer offset={200} throttle={150} overflow>
            <UserPicture
              color={Theme.colors.blue}
              size="smaller"
              userFirstName={visitorFirstName}
              userLastName={visitorLastName}
            />
          </LazyContainer>
          <UserInfoContainer disabled={noMoreBookingsAvailable}>
            <NameContainer>
              {visitorLastName.toUpperCase()} {utils.formatNames(visitorFirstName)}
            </NameContainer>
            {findAllVisits.length > 0 && (
              <EmailContainer>
                {findAllVisits.length > 1
                  ? i18n.t('components.userList.visitMultipleDates', {
                      startDate: new Date(findAllVisits[0].day),
                      endDate: new Date(findAllVisits[findAllVisits.length - 1].day),
                    })
                  : i18n.t('components.userList.visitDate', {
                      date: new Date(findAllVisits[0].day),
                    })}
              </EmailContainer>
            )}
            {withVisitors && noMoreBookingsAvailable && (
              <EmailContainer>{i18n.t('components.userList.noMoreAvailableVisits')}</EmailContainer>
            )}
          </UserInfoContainer>
        </UserContainer>
      </ElementContainer>
    )
  }

  return (
    <MainContainer>
      <TitleContainer>
        {!!title && <Title>{title}</Title>}
        <CrossContainer onClick={closeModal} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" size={25} />
        </CrossContainer>
      </TitleContainer>
      {!!searchFavorites && (
        <TabContainer>
          <TabResearch isSelected={tab === 'SEARCH'} onClick={() => setTab('SEARCH')}>
            {i18n.t('components.userList.research')}
          </TabResearch>
          <Divider />
          <TabFavorites isSelected={tab === 'FAVORITES'} onClick={() => setTab('FAVORITES')}>
            {i18n.t('components.userList.favorites')}
          </TabFavorites>
          {withVisitors && (
            <>
              <Divider />
              <TabFavorites isSelected={tab === 'VISITORS'} onClick={() => setTab('VISITORS')}>
                {i18n.t('components.userList.visitors')}
              </TabFavorites>
            </>
          )}
        </TabContainer>
      )}
      <SearchContainer>
        <SearchBarContainer>
          <SearchBar
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder={i18n.t('screens.cartography.search')}
            autoFocus={autoFocus}
            aria-label={searchAriaLabel}
          />
          {searchText.length > 0 ? (
            <CrossContainer onClick={resetSearchText}>
              <Icons name="cross" size={17} color={Theme.colors.blue} />
            </CrossContainer>
          ) : (
            <IconContainer>
              <Icons name="search" size={15} color={Theme.colors.blue} />
            </IconContainer>
          )}
        </SearchBarContainer>
      </SearchContainer>
      <ListContainer>
        {researchStatus === 'loading' || visitorStatus === 'loading' ? (
          <LoaderContainer>
            <Loader type="TailSpin" color={Theme.colors.blue} />
          </LoaderContainer>
        ) : researchStatus === 'notStarted' && tab !== 'VISITORS' ? (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('errors.map.searchNotStarted')}</ErrorMessage>
          </ErrorMessageContainer>
        ) : researchStatus === 'ready' || (tab === 'VISITORS' && visitorStatus === 'ok') ? (
          !peopleListFiltered ? (
            tab === 'VISITORS' && visitorSorted.length > 0 ? (
              visitorSorted.map(renderVisitor)
            ) : resultList.length > 0 ? (
              resultList.map(renderElement)
            ) : (
              <ErrorMessageContainer>
                <ErrorMessage>{i18n.t('errors.map.noResult')}</ErrorMessage>
              </ErrorMessageContainer>
            )
          ) : (
            peopleListFiltered.map(renderPeopleElement)
          )
        ) : (
          <ErrorMessageContainer>
            <ErrorMessage>{i18n.t('errors.map.searchError')}</ErrorMessage>
          </ErrorMessageContainer>
        )}
      </ListContainer>
      {!!peopleList && peopleList.length > 0 && !!poisInfos && (
        <ButtonContainer>
          <Button
            label={i18n.t('screens.planning.userList.viewOnPlan')}
            onClick={() => onUserClicked(peopleList[0], true)}
          />
        </ButtonContainer>
      )}
    </MainContainer>
  )
}

export default UserList

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 70vh;
`

const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px 10px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px 30px 10px;
  }
`

const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: calc(100% + 7px);
  }

  padding-bottom: 50px;
`

const Title = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold}
  font-size: 18px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.black};
`

const TabContainer = styled.div`
  display: flex;
  flex: 1;
  ${(props) => props.theme.fonts.label}
  font-size: 16px;
  line-height: 19px;
  justify-content: center;
  margin-bottom: 15px;
`

const TabResearch = styled.p<{ isSelected: boolean }>`
  text-decoration: underline solid
    ${(props) => (!props.isSelected ? props.theme.colors.black : props.theme.colors.blue)};
  color: ${(props) => (!props.isSelected ? props.theme.colors.black : props.theme.colors.blue)};
  cursor: pointer;
  margin: 0px;
`

const TabFavorites = styled.p<{ isSelected: boolean }>`
  text-decoration: underline solid ${(props) => (props.isSelected ? props.theme.colors.blue : props.theme.colors.black)};
  color: ${(props) => (props.isSelected ? props.theme.colors.blue : props.theme.colors.black)};
  cursor: pointer;
  margin: 0px;
`

const SearchContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const SearchBarContainer = styled('div')`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  width: 330px;
  height: 38px;
  border-radius: 52px;
  border: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
  margin: 10px;
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  padding: 12px;
`
const CrossContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-right: 12px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ErrorMessageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

const UserInfoContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 15px;
  ${(props) => !props.disabled && 'cursor: pointer;'}
`

const LikeContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
`

const UserContainer = styled.div`
  width: 330px;
  display: flex;
  margin: 15px 30px 15px;
`

const ElementContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const NameContainer = styled.div`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 16px;
  line-height: 19px;
`

const EmailContainer = styled.div`
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin: 20px 35px;
  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: calc(100% + 7px);
  }
`
// TEXTES

const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

// AUTRES

const SearchBar = styled('input')`
  flex: 1;
  ${(props) => props.theme.fonts.label}
  margin-left: 24px;
  border: 0;
  outline: 0;
`

const BlueLine = styled.div`
  width: 50px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
  margin-left: 30px;
`

const Divider = styled.div`
  height: 21px;
  width: 1px;
  background-color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px 40px 0px 40px;
`

const LetterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 370px;
  height: 30px;
  border-top: 0.5px solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-bottom: 0.5px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

const Letter = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 18px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.blue};
  margin-left: 20px;
`

const RowContainer = styled('div')`
  flex-direction: row;
  display: flex;
`

const IconTimeSlotContainer = styled('div')`
  margin-right: 4px;
`

const Info = styled.label`
  ${(props) => props.theme.fonts.label};
`

const DeskInfoContainer = styled.div`
  display: flex;
  margin: 5px 0px;
  gap: 10px;
`
