import React, { useState, useEffect, useMemo } from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'
import PicturesList from '../components/PicturesList'
import DeskSelectionModal from './DeskSelectionModal'
import Button from 'components/button/Button'

import api from '../api'

import useReducer from 'store/useReducer'
import * as planningStore from '../planningStore'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import { getDayTimezone, getReservationTimes } from '../utils'
import analytics, { analyticsKeys, analyticsActionKeys } from 'utils/analytics'
import useNavigation from 'utils/navigation/useNavigation'

import Loader from 'react-loader-spinner'
import useI18n from 'i18n/useI18n'
import ReservationModal from './ReservationModal'
import OccupationComponent from 'restaurants/screens/OccupationComponent'

type Status = 'loading' | 'success' | 'error' | 'notStarted'

interface Props {
  reservationDate: Date
  userId: string
  profil: ZapfloorProfil
  selectedReservationTime: ReservationTime
  refreshData: () => void
  isProfilesLoading: boolean
  zapfloorPois: AreaLight[]
  teamId: string
  parkingMode: boolean
  visitorMode: boolean
  visitorInfos?: UserResponse
  selectedVisitor?: PersonalVisit
  visits: PersonalVisit[]
}

const LocationSelectionForm = ({
  reservationDate,
  userId,
  profil,
  refreshData,
  selectedReservationTime,
  isProfilesLoading,
  zapfloorPois,
  teamId,
  parkingMode,
  visitorMode,
  visitorInfos,
  selectedVisitor,
  visits,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const favZone = useReducer(planningStore.store, (s) => s.favoriteLocation)
  const favZoneCurrentSite = useMemo(
    () => (!!currentSite && !!favZone && favZone[currentSite.id] ? favZone[currentSite.id] : undefined),
    [favZone, currentSite]
  )
  const user = useReducer(userStore.store, (s) => s.user)
  const { access_group_id } = profil.attributes

  const [status, setStatus] = useState<Status>('loading')
  const [locations, setLocations] = useState<ZapfloorZone[]>([])
  const navigation = useNavigation()

  const parkingVisitorUnitId = currentSite?.zapfloorVisitorZoneId

  useEffect(() => {
    if (!!user && !!profil && !!currentSite && !!currentSite.locationId) {
      const reservationTimes = getReservationTimes(selectedReservationTime)
      setStatus('loading')
      api.zapfloor
        .getAvailableZones(
          user.type,
          currentSite.id,
          i18n.t('formats.utcDate', { date: reservationDate }),
          access_group_id,
          currentSite.locationId!,
          reservationTimes.startTime,
          reservationTimes.endTime,
          userId,
          parkingVisitorUnitId && parkingMode && !!selectedVisitor ? parkingVisitorUnitId : undefined
        )
        .then((res) => {
          sortZones(res)
          setStatus('success')
        })
        .catch(() => setStatus('error'))
    }
  }, [userId, reservationDate, profil])

  useEffect(() => {
    sortZones(locations)
  }, [favZoneCurrentSite])

  const goToMap = (areaId?: string) => {
    if (currentSite && currentSite.webVisioglobeHash && areaId) {
      navigation.pushQuery(`/map`, `?areaId=${areaId}`, { origin: 'localisation' })
    }
  }

  const renderReservationModal = (l: ZapfloorZone) => {
    // If the zone allows reserving specific desk : open DeskSelectionModal, if not : open directly ReservationModal

    const isDeskBookingZone = zapfloorPois.find((area) => area.zapfloorId === l.attributes.unit_id)
    const isHotDesk = zapfloorPois.filter(
      (area) => isDeskBookingZone?.uniquePlaceName === area.uniquePlaceName.split('/')[0] && area !== isDeskBookingZone
    )
    if (isHotDesk.length > 0) {
      Modal.open(() => (
        <DeskSelectionModal
          userId={userId}
          teamId={access_group_id}
          closeModal={Modal.close}
          location={l}
          initialDate={reservationDate}
          refreshData={refreshData}
          reservationTime={selectedReservationTime}
          navigation={navigation}
          selectedAreas={isHotDesk}
          zapfloorPois={zapfloorPois}
          parkingMode={parkingMode}
          visitorMode={visitorMode}
          visitorInfos={visitorInfos}
          selectedVisitor={selectedVisitor}
          visits={visits}
        />
      ))
    } else {
      Modal.open(() => (
        <ReservationModal
          userId={userId}
          teamId={access_group_id}
          closeModal={Modal.close}
          location={l}
          initialDate={reservationDate}
          refreshData={refreshData}
          reservationTime={selectedReservationTime}
          parkingMode={parkingMode}
          visitorMode={visitorMode}
          visitorInfos={visitorInfos}
          selectedVisitor={selectedVisitor}
          visits={visits}
          zapfloorPois={zapfloorPois.filter((poi) => !!poi.zapfloorId && poi.uniquePlaceName.includes('/'))}
        />
      ))
    }
  }

  const changeFavZone = (profil: ZapfloorProfil, zone: ZapfloorZone) => {
    if (!!currentSite) {
      if (
        !!favZoneCurrentSite &&
        favZoneCurrentSite.zoneId === zone.attributes.unit_id &&
        favZoneCurrentSite.profilId === profil.attributes.access_group_id
      ) {
        planningStore.actions.setFavoriteLocation({
          siteId: currentSite.id,
          isParking: false,
        })
      } else if (!!favZoneCurrentSite && !!favZoneCurrentSite.zoneId && !!favZoneCurrentSite.profilId) {
        Modal.open(() => (
          <ValidationContent
            title={i18n.t('screens.planning.modal.warning')}
            description={i18n.t('screens.planning.modal.changeFavZone')}
            onConfirm={() => {
              planningStore.actions.setFavoriteLocation({
                profilId: profil.attributes.access_group_id,
                zoneId: zone.attributes.unit_id,
                profilName: profil.attributes.access_group_name,
                zoneName: zone.attributes.unit_name,
                siteId: currentSite.id,
                isParking: profil.attributes.description.toLowerCase().includes('parking'),
              })
              analytics.track({
                screenName: analyticsKeys.zapfloor,
                userType: user?.type,
                currentSite,
                mySites,
                action: analyticsActionKeys.planningSaveFavoriteZone,
              })
              Modal.close()
            }}
            cancelButton={true}
            font={Theme.fonts.label}
          />
        ))
      } else {
        planningStore.actions.setFavoriteLocation({
          profilId: profil.attributes.access_group_id,
          zoneId: zone.attributes.unit_id,
          profilName: profil.attributes.access_group_name,
          zoneName: zone.attributes.unit_name,
          siteId: currentSite.id,
          isParking: profil.attributes.description.toLowerCase().includes('parking'),
        })
        analytics.track({
          screenName: analyticsKeys.zapfloor,
          userType: user?.type,
          currentSite,
          mySites,
          action: analyticsActionKeys.planningSaveFavoriteZone,
        })
      }
    }
  }

  const sortZones = (zones: ZapfloorZone[]) => {
    const favZone =
      (!!favZoneCurrentSite && zones.filter((z) => z.attributes.unit_id === favZoneCurrentSite.zoneId)[0]) || undefined
    setLocations(
      !!favZone
        ? [
            favZone,
            ...zones
              .filter((z) => z.attributes.unit_id !== favZone.attributes.unit_id)
              .sort((a, b) => a.attributes.unit_name.localeCompare(b.attributes.unit_name)),
          ]
        : [...zones.sort((a, b) => a.attributes.unit_name.localeCompare(b.attributes.unit_name))]
    )
  }

  const renderLocations = () => {
    if (status === 'loading' || isProfilesLoading) {
      return (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      )
    }

    if (status === 'error') {
      return (
        <MainContainer>
          <Title>{i18n.t('screens.planning.errors.profiles')}</Title>
        </MainContainer>
      )
    }

    return locations.map((l, index, array) => {
      const unitArea = zapfloorPois.find((area) => area.zapfloorId === l.attributes.unit_id)
      const { desk_count, desks_taken, unit_name, hot_desk_bookings } = l.attributes
      const isHotDesk = zapfloorPois.filter(
        (area) => unitArea?.uniquePlaceName === area.uniquePlaceName.split('/')[0] && area !== unitArea
      )
      return (
        <div key={l.id}>
          <LocationContainer>
            <LocationInfoContainer onClick={() => renderReservationModal(l)} tabIndex={0}>
              <LocationName>{unit_name}</LocationName>
              <OccupancyInfo>
                <IconUserContainer>
                  <Icons
                    name="users-wm"
                    color={Theme.colors.blue}
                    size={12}
                    ariaLabel={i18n.t('screens.planning.iconsAction.occupancy')}
                  />
                </IconUserContainer>
                <Occupancy>
                  {i18n.t('screens.planning.secondForm.occupancy', {
                    occupancy: desks_taken,
                    capacity: desk_count,
                  })}
                </Occupancy>
                <OccupationComponent currentCapacity={desks_taken} maxCapacity={desk_count} />
              </OccupancyInfo>
              {!parkingMode && (
                <PictureListContainer listLength={hot_desk_bookings.length > 4 ? 4 : hot_desk_bookings.length}>
                  <PicturesList
                    clickable
                    peopleList={hot_desk_bookings.map((user) => ({
                      id: user.user_id,
                      firstname: user.user_firstname,
                      lastname: user.user_lastname,
                      email: user.user_email,
                      timeFrom: user.time_from,
                      timeTo: user.time_to,
                      desk: zapfloorPois.find((poi) => poi.zapfloorId === user.desk_id),
                      initialDate: getDayTimezone(user.date_from),
                      teamId: teamId,
                      type: 'ENGIE',
                      zone: l,
                    }))}
                    navigation={navigation}
                    poisInfos={zapfloorPois}
                    refreshData={refreshData}
                  />
                </PictureListContainer>
              )}
            </LocationInfoContainer>
            <RightColumnContainer>
              {!parkingMode && (
                <LikeContainer onClick={() => changeFavZone(profil, l)}>
                  <Icons
                    name={
                      !!favZoneCurrentSite &&
                      favZoneCurrentSite.zoneId === l.attributes.unit_id &&
                      favZoneCurrentSite.profilId === profil.attributes.access_group_id
                        ? 'heart-filled'
                        : 'heart'
                    }
                    ariaLabel={i18n.t(
                      `screens.planning.iconsAction.${
                        !!favZoneCurrentSite &&
                        favZoneCurrentSite.zoneId === l.attributes.unit_id &&
                        favZoneCurrentSite.profilId === profil.attributes.access_group_id
                          ? 'unfav'
                          : 'fav'
                      }`
                    )}
                    color={Theme.colors.blue}
                    size={26}
                  />
                </LikeContainer>
              )}
              {!!unitArea && isHotDesk.length === 0 && (
                <Button
                  color={Theme.colors.blue}
                  font={Theme.fonts.label}
                  label={i18n.t('screens.desk.locate')}
                  verticalPadding={6}
                  horizontalPadding={12}
                  onClick={() => goToMap(unitArea.uniquePlaceName)}
                  ariaLabel={i18n.t('label.ariaLabel.desk.locateZone')}
                  noFlex
                />
              )}
            </RightColumnContainer>
          </LocationContainer>
          {index < array.length - 1 && <BlueLine role="presentation" />}
        </div>
      )
    })
  }

  return (
    <MainContainer>
      <LocationsContainer>
        <TitleContainer>
          <IconContainer>
            <Icons name="arrow_right" size={20} color={Theme.colors.blue} />
          </IconContainer>
          <Title>
            {i18n.t('screens.planning.secondForm.title', {
              locationNumber: locations.length.toString(),
              count: locations.length,
            })}
          </Title>
        </TitleContainer>

        {renderLocations()}
      </LocationsContainer>
    </MainContainer>
  )
}

export default LocationSelectionForm

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  width: 350px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 300px;
  }
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`

const TitleContainer = styled.div`
  display: flex;
`

const Title = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 18px;
  line-height: 20px;
  margin: 0px 0px 20px;
  color: ${(props) => props.theme.colors.blue};
`

const IconContainer = styled.div`
  margin-right: 10px;
  cursor: pointer;
`

const LikeContainer = styled.button`
  margin: 10px;
  align-self: center;
`

const RightColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-itms: center;
`

const LocationsContainer = styled.div`
  margin-top: 10px;
`

const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  &:hover {
    box-shadow: 0 0 20px 0 ${(props) => props.theme.colors.shadow};
  }
`

const LocationName = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  margin: 0px;
`

const LocationInfoContainer = styled.button`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1;
  ${(props) => props.theme.fonts.label};
`

const Occupancy = styled.p`
  margin: 0px;
  padding: 0px 10px;
`

const OccupancyInfo = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.fonts.label};
  margin: 12px 0px;
`

const BlueLine = styled.div`
  width: 60px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
  margin-bottom: 15px;
`

const PictureListContainer = styled.div<{ listLength: number }>`
  max-width: ${(props) => props.listLength * 32}px;
`

const IconUserContainer = styled('div')`
  justify-content: center;
`
