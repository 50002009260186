import React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import Modal from 'components/modal/Modal'
import ReservationTimeModal from '../myPlanning/ReservationTimeModal'
import SwitchFilter from 'components/switch/SwitchFilter'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import utils from 'utils/strings'
import {
  daysToExclude,
  getClosestDate,
  getDatesInInterval,
  getDayTimezone,
  getMaxDate,
  getWeekDaysNumber,
} from '../utils'
import {
  TIME_OPTIONS,
  TYPE_PLACE_OPTIONS,
  TypePlace,
  WEEKDAYS,
  WEEK_DAY_SORTER,
  allowedDaysToBook,
  bookingsSharvyToSend,
  datesToAvoidSharvy,
  filterSharvyBookings,
  isNotAllowedToBookSharvy,
  minSelectableDateSharvy,
} from 'parking/utils'

import { isWeekend, addBusinessDays, getDay, isSameDay, isBefore, isAfter } from 'date-fns'
import useI18n from 'i18n/useI18n'

import api from '../api'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as planningStore from 'planning/planningStore'

const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
  if (e.key === 'Enter') {
    onClick()
  }
}

interface Props {
  reservationDate: Date
  setReservationDate: (date: Date) => void
  refreshData: () => void
  sharvyInfos: SharvyDataUser
}

const CURRENT_DATE = new Date()
const MIN_DATE = !isWeekend(CURRENT_DATE) ? CURRENT_DATE : addBusinessDays(CURRENT_DATE, 1)
const MAX_DAYS = 30

const SharvyReservationForm = ({ reservationDate, setReservationDate, refreshData, sharvyInfos }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const maxDaysInFuture = useReducer(planningStore.store, (s) => s.maxDaysInFuture)

  const [datePickerToggle, setDatePickerToggle] = React.useState<'none' | 'initial' | 'recurrence'>('none')
  const [isBooking, setIsBooking] = React.useState(false)
  const [selectedReservationTime, setSelectedReservatonTime] = React.useState<ReservationTime>('ALL_DAY')
  const [selectedTypePlace, setSelectedTypePlace] = React.useState<TypePlace>('STANDARD')
  const [timeOptions, setTimeOptions] = React.useState<ReservationTime[]>(TIME_OPTIONS)
  const [isRecurrenceActive, setIsRecurrenceActive] = React.useState(false)
  const [selectedWeekDays, setSelectedWeekDays] = React.useState<WeekDay[]>([])
  const [daysToBook, setDaysToBook] = React.useState<Date[]>([])
  const [filteredDaysToBook, setFilteredDaysToBook] = React.useState<Date[]>([])

  const MAX_DATE = React.useMemo(
    () => getMaxDate(MIN_DATE, MAX_DAYS, maxDaysInFuture, currentSite?.maxDeskBookingDays),
    [MIN_DATE, currentSite, maxDaysInFuture]
  )

  const sharvyBookings = React.useMemo(
    () => (!!sharvyInfos.bookings ? filterSharvyBookings(sharvyInfos.bookings) : []),
    [sharvyInfos.bookings]
  )

  const datesToAvoid = React.useMemo(() => datesToAvoidSharvy(sharvyBookings), [sharvyBookings])

  const [endDateRecurrence, setEndDateRecurrence] = React.useState<Date>(
    getClosestDate(MIN_DATE, MAX_DATE, MAX_DATE || CURRENT_DATE, datesToAvoid, true) || MAX_DATE
  )

  const excludedDays = React.useMemo(() => {
    if (!!sharvyInfos.parkingInfo && !!sharvyInfos.parkingInfo.workingDays) {
      const workingDays = sharvyInfos.parkingInfo.workingDays
      return daysToExclude(workingDays)
    }
    return [0, 6]
  }, [sharvyInfos])

  const filterWorkingDays = (date: Date) => !excludedDays.includes(getDay(date))

  const disabledDates = React.useMemo(() => getDatesInInterval(MIN_DATE, MAX_DATE, excludedDays).concat(datesToAvoid), [
    datesToAvoid,
    excludedDays,
  ])

  const minSelectableDate = React.useMemo(() => minSelectableDateSharvy(MIN_DATE, MAX_DATE, disabledDates), [
    disabledDates,
    MAX_DATE,
  ])

  React.useEffect(() => {
    if (disabledDates.find((date) => isSameDay(date, reservationDate))) {
      setReservationDate(minSelectableDate)
    } else if (isAfter(minSelectableDate, reservationDate)) {
      setReservationDate(minSelectableDate)
    } else if (isBefore(MAX_DATE, reservationDate)) {
      setReservationDate(MAX_DATE)
    }
  }, [disabledDates, reservationDate, minSelectableDate])

  React.useEffect(() => {
    const findBookingDay = sharvyBookings.find((booking) =>
      isSameDay(getDayTimezone(booking.reservationDate || ''), reservationDate)
    )
    // On met le créneau qui n'est pas réservé
    if (!!findBookingDay) {
      if (findBookingDay.isMorning) {
        setSelectedReservatonTime('AFTERNOON')
        setTimeOptions(['AFTERNOON'])
      } else {
        setSelectedReservatonTime('MORNING')
        setTimeOptions(['MORNING'])
      }
    } else {
      setSelectedReservatonTime('ALL_DAY')
      setTimeOptions(TIME_OPTIONS)
    }
  }, [sharvyBookings, reservationDate])

  React.useEffect(() => {
    if (isRecurrenceActive) {
      const weekDaysNumber = getWeekDaysNumber(selectedWeekDays)
      const daysToBook = getDatesInInterval(reservationDate, endDateRecurrence, weekDaysNumber)
      const filteredAllowedDaysToBook = allowedDaysToBook(daysToBook, sharvyBookings, selectedReservationTime)
      if (!!daysToBook.find((day) => isSameDay(day, reservationDate))) {
        setDaysToBook(daysToBook)
        setFilteredDaysToBook(filteredAllowedDaysToBook)
      } else {
        setDaysToBook([reservationDate, ...daysToBook])
        setFilteredDaysToBook([reservationDate, ...filteredAllowedDaysToBook])
      }
    }
  }, [
    isRecurrenceActive,
    selectedWeekDays,
    endDateRecurrence,
    selectedReservationTime,
    reservationDate,
    sharvyBookings,
  ])

  const onClickBook = () => {
    if (!!user && !!currentSite) {
      setIsBooking(true)
      const dateToSend = i18n.t('screens.planning.secondForm.dateToSend', {
        date: reservationDate,
      })
      const bookingsToSend: SharvyBookingRequest[] = bookingsSharvyToSend(
        dateToSend,
        isRecurrenceActive,
        selectedReservationTime,
        filteredDaysToBook,
        selectedTypePlace,
        i18n
      )
      const bodyRequest: SharvyBookingsForm = {
        bookings: bookingsToSend,
      }
      api.sharvy
        .postSharvyBookings(user.type, currentSite.id, bodyRequest)
        .then((res) => {
          refreshData()
          const filteredErrors = !!res.bookings ? res.bookings.filter((item) => !!item.result?.error) : []
          const filteredSucceed = !!res.bookings ? res.bookings.filter((item) => !item.result?.error) : []
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.planning.modal.confirmedRegistration', { count: filteredSucceed.length })}
                onConfirm={() => {
                  Alert.close()
                }}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                description={
                  filteredErrors.length > 0
                    ? i18n.t('screens.planning.sharvy.errorDescription', {
                        listDates: filteredErrors.map(
                          (error, index) =>
                            `${index > 0 ? ' ' : ''}${utils.capitalize(
                              i18n.t('screens.planning.reservationDate', {
                                date: getDayTimezone(error.reservationDate || ''),
                              })
                            )} (${i18n.t(
                              `screens.planning.selectionOnPlanModal.reservationTime.${
                                error.isMorning ? 'MORNING' : 'AFTERNOON'
                              }`
                            )})`
                        ),
                      })
                    : undefined
                }
              />
            ),
            true
          )
        })
        .catch((err) => {
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.planning.modal.errorRegistration')}
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        })
        .finally(() => setIsBooking(false))
    }
  }

  const openTimeSlotSelector = () =>
    Modal.open(() => (
      <ReservationTimeModal
        title={i18n.t('screens.desk.time')}
        options={timeOptions}
        setChoice={(newTime) => setSelectedReservatonTime(newTime as ReservationTime)}
        typeModal="modal"
        type="time"
      />
    ))

  const openTypePlaceSelector = () =>
    Modal.open(() => (
      <ReservationTimeModal
        title={i18n.t('screens.planning.firstForm.typeSharvy')}
        options={TYPE_PLACE_OPTIONS}
        setChoice={(newType) => setSelectedTypePlace(newType as TypePlace)}
        typeModal="modal"
        type="sharvy"
      />
    ))

  const setAllWeekDays = () =>
    setSelectedWeekDays(
      WEEKDAYS.filter(
        (day) =>
          !!sharvyInfos.parkingInfo &&
          !!sharvyInfos.parkingInfo.workingDays &&
          !!sharvyInfos.parkingInfo.workingDays.find((workingDay) => workingDay.toUpperCase() === day)
      )
    )

  const toggleWeekDay = (day: WeekDay) =>
    setSelectedWeekDays((days) =>
      days.includes(day)
        ? days.length === 1
          ? days
          : days.filter((d) => d !== day).sort((d1, d2) => WEEK_DAY_SORTER[d1] - WEEK_DAY_SORTER[d2])
        : [...days, day].sort((d1, d2) => WEEK_DAY_SORTER[d1] - WEEK_DAY_SORTER[d2])
    )

  const renderDayToBook = () =>
    daysToBook.map((day, index) => {
      const findBookingsDay = sharvyBookings.filter((booking) =>
        isSameDay(day, getDayTimezone(booking.reservationDate || ''))
      )
      const isNotAllowedToBook = isNotAllowedToBookSharvy(findBookingsDay, selectedReservationTime)
      return (
        <RowContainer key={index}>
          <LeftContainer>
            <Day>{utils.capitalize(i18n.t('screens.planning.reservationDate', { date: day }))}</Day>
          </LeftContainer>
          <IconContainer>
            <Icons
              name={isNotAllowedToBook ? 'cross-circle' : 'check-circle'}
              size={26}
              color={isNotAllowedToBook ? Theme.colors.red : Theme.colors.blue}
            />
          </IconContainer>
        </RowContainer>
      )
    })

  const renderRecurrenceDay = (day: WeekDay) => {
    const isSelected = !!selectedWeekDays.find((d) => d === day)
    const isEnabled = !!sharvyInfos.parkingInfo?.workingDays
      ? !!sharvyInfos.parkingInfo.workingDays.find((workingDay) => workingDay.toUpperCase() === day)
      : true
    return (
      <DayChoice disabled={!isEnabled} key={day} selected={isSelected} onClick={() => toggleWeekDay(day)}>
        <DayLetter selected={isSelected}>{i18n.t(`common.weekdays.${day}`)[0]}</DayLetter>
      </DayChoice>
    )
  }

  const renderRecurrencePanel = () => (
    <DaySelectionContainer>
      {WEEKDAYS.map(renderRecurrenceDay)}
      <EveryDayContainer onClick={setAllWeekDays}>{i18n.t('screens.planning.modal.everyDay')}</EveryDayContainer>
    </DaySelectionContainer>
  )

  const renderRecurrence = () => (
    <>
      <RowContainer>
        <Label>{i18n.t('screens.planning.modal.recurrence')}</Label>
        <IconContainer>
          <SwitchFilter value={isRecurrenceActive} setValue={setIsRecurrenceActive} />
        </IconContainer>
      </RowContainer>
      {isRecurrenceActive && (
        <>
          <RowContainer>{renderRecurrencePanel()}</RowContainer>
          <RowContainer>
            <Label>{i18n.t('screens.planning.modal.to')}</Label>
            <ChoiceContainer>
              <CliquableDiv
                onClick={() => setDatePickerToggle('recurrence')}
                onKeyDown={(e) => onEnterKey(e, () => setDatePickerToggle('recurrence'))}>
                <ChoiceText>
                  {utils.capitalize(i18n.t('screens.planning.reservationDate', { date: endDateRecurrence }))}
                </ChoiceText>
                <IconContainer>
                  <Icons name="chevron_right" color={Theme.colors.darkGrey} />
                </IconContainer>
              </CliquableDiv>
              {datePickerToggle === 'recurrence' && (
                <CustomDatePicker
                  selectedDate={endDateRecurrence}
                  onChange={setEndDateRecurrence}
                  minDate={reservationDate}
                  maxDate={MAX_DATE}
                  closePicker={() => setDatePickerToggle('none')}
                  filterDate={filterWorkingDays}
                  excludeDates={datesToAvoid}
                />
              )}
            </ChoiceContainer>
          </RowContainer>
          <RowContainer>
            <BlueLine role="presentation" />
          </RowContainer>

          {renderDayToBook()}
        </>
      )}
    </>
  )

  return (
    <MainContainer>
      <RowContainer>
        <Label>{i18n.t('screens.planning.firstForm.date')}</Label>
        <ChoiceContainer>
          <CliquableDiv
            tabIndex={0}
            onClick={() => setDatePickerToggle('initial')}
            onKeyDown={(e) => onEnterKey(e, () => setDatePickerToggle('initial'))}>
            <ChoiceText>
              {utils.capitalize(i18n.t('screens.planning.reservationDate', { date: reservationDate }))}
            </ChoiceText>
            <IconContainer>
              <Icons name="chevron_right" color={Theme.colors.darkGrey} />
            </IconContainer>
          </CliquableDiv>
          {datePickerToggle === 'initial' && (
            <CustomDatePicker
              selectedDate={reservationDate}
              onChange={setReservationDate}
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              closePicker={() => setDatePickerToggle('none')}
              filterDate={filterWorkingDays}
              excludeDates={datesToAvoid}
            />
          )}
        </ChoiceContainer>
      </RowContainer>
      <BlueLine role="presentation" />
      {!!sharvyInfos.parkingInfo && sharvyInfos.parkingInfo.useHalfDays && (
        <>
          <RowContainer>
            <Label>{i18n.t('screens.planning.firstForm.timeSlot')}</Label>
            <ChoiceContainer tabIndex={0} onClick={openTimeSlotSelector}>
              <ChoiceText>{i18n.t(`screens.desk.reservationTime.${selectedReservationTime}`)}</ChoiceText>
              <IconContainer>
                <Icons name="chevron_right" color={Theme.colors.darkGrey} />
              </IconContainer>
            </ChoiceContainer>
          </RowContainer>
          <BlueLine role="presentation" />
        </>
      )}
      <RowContainer>
        <Label>{i18n.t('screens.planning.firstForm.typeSharvy')}</Label>
        <ChoiceContainer tabIndex={0} onClick={openTypePlaceSelector}>
          <ChoiceText>{i18n.t(`screens.planning.sharvy.types.${selectedTypePlace}`)}</ChoiceText>
          <IconContainer>
            <Icons name="chevron_right" color={Theme.colors.darkGrey} />
          </IconContainer>
        </ChoiceContainer>
      </RowContainer>
      <BlueLine role="presentation" />
      {renderRecurrence()}
      <ButtonContainer>
        <Button
          label={i18n.t('screens.planning.modal.confirmReservation', {
            count: isRecurrenceActive ? filteredDaysToBook.length : 1,
          })}
          onClick={onClickBook}
          color={Theme.colors.blue}
          textColor={Theme.colors.white}
          font={Theme.fonts.h3Bold}
          loading={isBooking}
        />
      </ButtonContainer>
    </MainContainer>
  )
}

export default SharvyReservationForm

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  width: 350px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 300px;
  }
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex: 1;
`

const CliquableDiv = styled.div`
  cursor: pointer;
  display: flex;
`

const ChoiceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  flex: 2.5;
`

const ChoiceText = styled.p`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.blue};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`

const IconContainer = styled.div``

const BlueLine = styled.div`
  width: 60px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
  margin-bottom: 15px;
`

const ButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  margin-top: 10px;
`

const DaySelectionContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const EveryDayContainer = styled.button`
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
  width: 50px;
  padding-top: 7px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const DayChoice = styled.button<{ selected: boolean; disabled?: boolean }>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.blue
      : props.disabled
      ? props.theme.colors.occupied
      : props.theme.colors.lightGrey};
  ${(props) => props.disabled && 'cursor: not-allowed;'}
`

const DayLetter = styled.p<{ selected: boolean }>`
  ${(props) => props.theme.fonts.body}
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.black)};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  text-transform: capitalize;
`

const Day = styled.p`
  ${(props) => props.theme.fonts.body}
  font-size: 16px;
  line-height: 19px;
  margin: 2px 0px 0px 0px;
`

const LeftContainer = styled('div')`
  display: flex;
  flex: 1;
`
