import React from 'react'
import api from '../api/sitesApi'
import accountApi from 'account/accountApi'
import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys } from 'utils/analytics'

import * as siteStore from '../store/siteStore'
import * as userStore from 'store/user/user'
import * as featureStore from 'sites/store/featureStore'
import * as shortcutStore from 'home/store/shortcutStore'
import useReducer from 'store/useReducer'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Logger from 'utils/Logger'
import Icons from 'components/icons/Icons'
import InstallAppModal from 'components/modal/InstallAppModal'
import Modal from 'components/modal/Modal'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'

import Loader from 'react-loader-spinner'
import sanitize from 'utils/Sanitizer'
import { checkOrigin } from 'core/src/layout/utils'

type Status = 'loading' | 'success' | 'error' | 'notStarted'

interface Props {
  origin?: string
}

const SitesScreen = ({ origin }: Props) => {
  const [Theme] = useTheme()
  const [sitesTab, setSites] = React.useState<SiteLight[]>([])
  const [status, setStatus] = React.useState<Status>('loading')
  const [personnalSites, setPersonnalSites] = React.useState<SiteLight[]>([])
  const [otherSites, setOtherSites] = React.useState<SiteLight[]>([])
  const [regionSelected, setRegionSelected] = React.useState<SiteRegion>()
  const [personalSitesSearch, setPersonnalSitesSearch] = React.useState('')
  const [otherSitesSearch, setOtherSitesSearch] = React.useState('')
  const [regions, setRegions] = React.useState<Region[]>([])

  const i18n = useI18n()
  const navigation = useNavigation()
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const user = useReducer(userStore.store, (u) => u.user)
  const sameUser = useReducer(userStore.store, (u) => u.sameUser)
  const badges = useReducer(userStore.store, (s) => s.badges)
  const seeDownloadApp = useReducer(userStore.store, (s) => s.seeDownloadApp)

  const allSitesShorcuts = useReducer(shortcutStore.store, (s) => s.sitesShortcuts)

  const regionsList = React.useMemo(() => {
    const allRegions = otherSites.map((site) => site.region)
    return allRegions
      .reduce((acc, cur) => {
        if (!acc.find((i) => i.regionKey === cur?.regionKey) && !!cur) {
          acc.push(cur)
        }
        return acc
      }, [] as SiteRegion[])
      .sort((a, b) => {
        const findRegionA = regions.find((r) => r.regionKey === a.regionKey)
        const findRegionB = regions.find((r) => r.regionKey === b.regionKey)
        if (!!findRegionA && !!findRegionB) {
          if (findRegionA.priority > findRegionB.priority) {
            return 1
          }
          return -1
        }
        return 0
      })
  }, [otherSites, regions])

  const otherSitesFiltered = React.useMemo(
    () =>
      !!otherSitesSearch
        ? otherSites.filter((site) => sanitize(site.name).includes(otherSitesSearch))
        : !!regionSelected
        ? otherSites.filter((site) => site.region?.regionKey === regionSelected.regionKey)
        : [],
    [otherSites, regionSelected, otherSitesSearch]
  )

  const personalSitesFiltered = React.useMemo(
    () => personnalSites.filter((site) => sanitize(site.name).includes(personalSitesSearch)),
    [personnalSites, personalSitesSearch]
  )

  const onValidate = (site: SiteLight) => {
    if (site && user) {
      setStatus('loading')
      api
        .getSite(site.id, user.type)
        .then((res) => {
          siteStore.actions.setSite(res)
          settingFeaturesFunc(res)
          Modal.close()
          navigation.push('/')
          setStatus('success')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
    if (!currentSite) {
      navigation.push('/')
    }
  }

  const settingFeaturesFunc = (site: Site) => {
    if (user) {
      accountApi
        .getUserFeatures(site.id, user.type)
        .then((userFeatures) => {
          featureStore.actions.setUserFeatures(userFeatures.features)

          if (!allSitesShorcuts[site.id] || allSitesShorcuts[site.id].isDefault || !sameUser) {
            const commonList = userFeatures.features
              .filter(
                (feature) => feature.shortcutByDefault && feature.type !== 'DOMOTIC' && feature.type !== 'RELEASE'
              )
              .map((f) =>
                f.type === 'THIRD_PART' || f.type === 'PARKING'
                  ? { id: f.id, name: f.name }
                  : { id: f.id, name: f.type }
              )
            shortcutStore.actions.setSiteShortcuts({ siteId: site.id, list: commonList, isDefault: true })
            userStore.actions.setSameUser(true)
          }
        })
        .catch(Logger.error)
    }
  }

  React.useEffect(() => {
    if (user && user.type === 'EXTERNAL') {
      accountApi.getInfo('EXTERNAL').then((u) => {
        userStore.actions.setUser(u)
      })
    }
    if (user) {
      Promise.all([api.getAllSitesLight(user.type), api.getAllRegions(user.type)])
        .then((res) => {
          const siteList = res[0]
          const regionsList = res[1].regions
          setRegions(regionsList)
          api
            .getUserSites(user.type)
            .then((personalSites) => {
              siteStore.actions.setMySites(personalSites.sites)

              if (personalSites.sites.length === 1 && !currentSite) {
                const autoSelectedSite = siteList.sites.find((s) => s.id === personalSites.sites[0].id)

                if (autoSelectedSite) {
                  api
                    .getSite(autoSelectedSite.id, user.type)
                    .then((res) => {
                      siteStore.actions.setSite(res)
                      settingFeaturesFunc(res)
                      navigation.push('/')
                    })
                    .catch(Logger.error)
                }
              }
              const findPrincipalBadge = badges.find((badge) => badge.isPrincipal)
              const findPrincipalBadgeSite = !!findPrincipalBadge
                ? personalSites.sites.find((site) => site.id === findPrincipalBadge.siteId)
                : undefined
              setPersonnalSites(
                siteList.sites
                  .filter((site) => personalSites.sites.map((s) => s.id).indexOf(site.id) > -1)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .sort((a, b) => {
                    if (!!findPrincipalBadgeSite) {
                      if (a.id === findPrincipalBadgeSite.id) {
                        return -1
                      }
                      if (b.id === findPrincipalBadgeSite.id) {
                        return 1
                      }
                    }
                    return 0
                  })
              )
              setOtherSites(
                siteList.sites
                  .filter((site) => personalSites.sites.map((s) => s.id).indexOf(site.id) === -1)
                  .sort((a, b) => a.name.localeCompare(b.name))
              )
              setSites(siteList.sites)
              setStatus('success')
            })
            .catch((err) => {
              Logger.error(err)
              setStatus('error')
            })
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }, [badges])

  React.useEffect(() => {
    openInstallAppPopUp()
    if (user) {
      analytics.track({
        screenName: analyticsKeys.sites,
        userType: user.type,
        currentSite,
        mySites,
        origin: origin || (!!currentSite ? 'refresh' : 'première connexion'),
      })
    }
  }, [])

  const openInstallAppPopUp = () => {
    if (!seeDownloadApp) {
      const origin = checkOrigin()
      if (origin === 'iPhone' || origin === 'android') {
        userStore.actions.setSeeDownloadApp(true)
        Modal.open(() => <InstallAppModal origin={origin} />, undefined, undefined, 16)
      }
    }
  }

  const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
    if (e.key === 'Enter') {
      onClick()
    }
  }

  const onChangeSite = (site: SiteLight, selected: boolean) => {
    if (!selected) {
      onValidate(site)
    }
  }

  const renderSite = (site: SiteLight) => {
    const selected = !!currentSite && site.name === currentSite.name

    return (
      <Site
        selected={selected}
        key={site.id}
        onClick={() => onChangeSite(site, selected)}
        tabIndex={0}
        onKeyDown={(e) => onEnterKey(e, () => onChangeSite(site, selected))}>
        <Image
          src={site.image || require('components/image/assets/site_placeholder.jpg').default}
          height={200}
          alt=""
        />
        <TextContainer>
          <SiteName selected={selected}>{site.name}</SiteName>
          {selected && (
            <IconContainer>
              <Icon>
                <Icons color={Theme.colors.blue} name="check-circle" size={31} />
              </Icon>
            </IconContainer>
          )}
        </TextContainer>
      </Site>
    )
  }

  const renderRegion = (region: SiteRegion) => (
    <Site
      selected={false}
      key={region.regionKey}
      onClick={() => setRegionSelected(region)}
      tabIndex={0}
      onKeyDown={(e) => onEnterKey(e, () => setRegionSelected(region))}>
      <Image src={region.image} height={200} alt="" />
      <TextContainer>
        <SiteName selected={false}>{region.name}</SiteName>
      </TextContainer>
    </Site>
  )

  const renderSearchBar = (searchText: string, onChange: (newText: string) => void) => (
    <SearchBarContainer>
      <SearchBar
        aria-label={i18n.t('label.ariaLabel.map.searchArea')}
        value={searchText}
        onChange={(e) => onChange(sanitize(e.target.value))}
        placeholder={i18n.t('screens.cartography.search')}
      />
      {searchText.length > 0 && (
        <CrossContainer onClick={() => onChange('')}>
          <Icons name="cross" size={17} color={Theme.colors.blue} />
        </CrossContainer>
      )}
      <SearchIconContainer>
        <Icons name="search" size={15} color={Theme.colors.blue} />
      </SearchIconContainer>
    </SearchBarContainer>
  )

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (status === 'error' || sitesTab.length < 1) {
    return (
      <MainContainer>
        <Title>{i18n.t('screens.sites.error')}</Title>
      </MainContainer>
    )
  }

  return (
    <MainContainer site={!!currentSite}>
      <TitleHelmet title={i18n.t('screens.sites.title')} />
      {currentSite && <Breadcrumb screen="sites" path="sites" navigation={navigation} />}

      <TopContainer>
        <Title>{i18n.t(`screens.sites.${personnalSites.length === 1 ? 'mySingleSite' : 'mySites'}`)}</Title>
        {personnalSites.length > 15 &&
          renderSearchBar(personalSitesSearch, (newSearch) => setPersonnalSitesSearch(newSearch))}
        <SiteContainer>
          {personnalSites.length > 0 ? (
            personalSitesFiltered.length > 0 ? (
              personalSitesFiltered.map(renderSite)
            ) : (
              <NoSiteMessage>{i18n.t('screens.sites.noSiteSearch')}</NoSiteMessage>
            )
          ) : (
            <NoSiteMessage>{i18n.t('screens.sites.noPersonnalSites')}</NoSiteMessage>
          )}
        </SiteContainer>
      </TopContainer>
      {user && user.type !== 'EXTERNAL' && (
        <BottomContainer>
          <TitleContainer
            pointer={!!regionSelected}
            onClick={() => {
              if (!!regionSelected) {
                setRegionSelected(undefined)
              }
            }}>
            {!!regionSelected && (
              <BackIconContainer>
                <Icons color={Theme.colors.black} name="chevron_left" size={31} />
              </BackIconContainer>
            )}
            <Title>
              {i18n.t(`screens.sites.${otherSites.length === 1 ? 'otherSingleSite' : 'otherSites'}`)}
              {!!regionSelected && ` - ${regionSelected.name}`}
            </Title>
          </TitleContainer>

          {otherSites.length > 0 && renderSearchBar(otherSitesSearch, (newSearch) => setOtherSitesSearch(newSearch))}

          <SiteContainer>
            {!regionSelected && !otherSitesSearch ? (
              regionsList.map(renderRegion)
            ) : otherSitesFiltered.length > 0 ? (
              otherSitesFiltered.map(renderSite)
            ) : (
              <NoSiteMessage>
                {i18n.t(`screens.sites.${otherSites.length > 0 ? 'noSiteSearch' : 'noOtherSites'}`)}
              </NoSiteMessage>
            )}
          </SiteContainer>
        </BottomContainer>
      )}
    </MainContainer>
  )
}
export default SitesScreen

const MainContainer = styled.div<{ site?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.site ? 'calc(100vh - 116px)' : '100%')};
  min-width: ${(props) => (props.site ? 'calc(100% - 114px)' : '100%')};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
    min-width: 100%;
  }
`
const TopContainer = styled('div')`
  margin-left: 90px;
  margin-bottom: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-left: 20px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 10px;
  }
`
const BottomContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 5% 40px 90px;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding-left: 20px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding-left: 10px;
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const Title = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  margin-left: 20px;
  margin-top: 50px;
  margin-bottom: 0px;
`
const Image = styled.img`
  object-fit: cover;
  height: 200px;
  width: 300px;
  background-color: ${(props) => props.theme.colors.middleGrey};
`
const SiteContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 20px;
  flex-wrap: wrap;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    flex-direction: column;
  }
`
const Site = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => !props.selected && 'cursor: pointer;'}
  height: 250px;
  width: 300px;
  margin: 20px 10px 0px 20px;
  ${(props) => props.theme.constants.shadow.card};
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin: 0px 0px 30px 20px;
  }
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`
const SiteName = styled.p<{ selected: boolean }>`
  ${(props) => props.theme.fonts.subtitleBold}
  font-size: 24px;
  line-height: 22px;
  padding-left: 20px;
  ${(props) => props.selected && `color: ${props.theme.colors.blue};`}
`
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  padding-right: 20px;
`
const TitleContainer = styled.div<{ pointer: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) => props.pointer && 'cursor: pointer;'}
`
const BackIconContainer = styled.div`
  padding-right: 20px;
  align-items: center;
  margin-top: 50px;
  margin-left: 12px;
`

const NoSiteMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
  margin-left: 20px;
`

const SearchBarContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-left: 12px;
  background-color: ${(props) => props.theme.colors.white};
  width: 330px;
  height: 38px;
  border-radius: 52px;
  border: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

const SearchBar = styled('input')`
  display: flex;
  flex: 1;
  ${(props) => props.theme.fonts.label}
  margin-left: 24px;
  border: 0;
  outline: 0;
  element.focus({
  preventScroll: true
});
`

const CrossContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const SearchIconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  padding: 12px;
`
