import Webservice from 'utils/Webservice'
import config from 'core/src/config'
import { assureToken } from 'authent365/utils/utils'

const api = {
  removeAccount: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<void>(userType, `${config.globalAPIVersion}/account/me`, 'DELETE', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getUserFeatures: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<FeatureList>(userType, `${config.globalAPIVersion}/account/me/features?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getInfo: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<UserSelf>(userType, `${config.globalAPIVersion}/account/me`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getBadges: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<BadgeList>(userType, `${config.globalAPIVersion}/account/me/badges`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  updatePassword: (userType: UserType, body: PasswordUpdateForm) =>
    assureToken(userType).then((token) =>
      Webservice<void>(
        userType,
        `${config.globalAPIVersion}/account/me/password`,
        'PUT',
        {
          Authorization: `Bearer ${token}`,
        },
        undefined,
        body
      )
    ),
  getVisits: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<PersonalVisits>(userType, `${config.globalAPIVersion}/account/visits?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  ringRing: {
    getSubscriptions: (userType: UserType, siteId: string) =>
      assureToken(userType).then((token) =>
        Webservice<ProfileRingRing>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/ringring/subscriptions/me?siteId=${siteId}`,
          'GET',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
    postSubscription: (siteId: string, userType: UserType, subForm: SubscriptionCreationForm) =>
      assureToken(userType).then((token) =>
        Webservice<void>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/ringring/subscriptions/me?siteId=${siteId}`,
          'POST',
          {
            Authorization: `Bearer ${token}`,
          },
          undefined,
          subForm
        )
      ),
    deleteSubscription: (siteId: string, userType: UserType) =>
      assureToken(userType).then((token) =>
        Webservice<PersonalVisits>(
          userType,
          `${config.SERVER_PREFIX}${config.globalAPIVersion}/ringring/subscriptions/me?siteId=${siteId}`,
          'DELETE',
          {
            Authorization: `Bearer ${token}`,
          }
        )
      ),
  },
}

export default api
