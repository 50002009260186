import React from 'react'
import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import stringUtils from 'utils/strings'

import {
  findPriorityError,
  getMessageError,
  findAvailabilityColor,
  findAvailabilityIcon,
  getStatusErrorMessage,
  getReservationTimes,
} from '../utils'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'
import PicturesList from '../components/PicturesList'
import Alert from 'components/alert/Alert'
import ReservationTimeModal from './ReservationTimeModal'
import ValidationContent from 'components/modal/ValidationContent'
import Modal from 'components/modal/Modal'
import OccupationComponent from 'restaurants/screens/OccupationComponent'

import api from '../api'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as planningStore from '../planningStore'
import * as siteStore from 'sites/store/siteStore'
import DeskSelectionModal from '../reservation/DeskSelectionModal'

interface Props {
  favZone: ZapfloorZone
  date: Date
  zoneName: string
  profilName: string
  bookFavZone: (reservationTime: ReservationTime) => void
  bookOtherZone?: () => void
  poisInfos: AreaLight[]
  navigation: Navigation
  refreshData: () => void
  parkingMode: boolean
}

const TIMEOPTIONS: ReservationTime[] = ['MORNING', 'AFTERNOON', 'ALL_DAY']
type Status = 'loading' | 'ok' | 'error'

const FavoriteZoneDetails = ({
  favZone,
  date,
  zoneName,
  profilName,
  bookFavZone,
  bookOtherZone,
  poisInfos,
  navigation,
  refreshData,
  parkingMode,
}: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const userZapfloorId = useReducer(planningStore.store, (s) => s.zapfloorUserId)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const favoriteLocation = useReducer(planningStore.store, (s) => s.favoriteLocation)

  const favZoneCurrentSite = React.useMemo(
    () =>
      !!currentSite && !!favoriteLocation && favoriteLocation[currentSite.id]
        ? favoriteLocation[currentSite.id]
        : undefined,
    [favoriteLocation, currentSite]
  )

  const [selectedReservationTime, setReservationTime] = React.useState<ReservationTime>('ALL_DAY')
  const [currentFavZoneInfos, setCurrentFavZoneInfos] = React.useState<ZapfloorZone>(favZone)
  const [statusScreen, setStatusScreen] = React.useState<Status>('ok')

  const { desks_taken, desk_count, hot_desk_bookings } = currentFavZoneInfos.attributes
  const errorMessage = findPriorityError(currentFavZoneInfos.attributes.error_response)
  const availabilityColor = findAvailabilityColor(errorMessage, theme)
  const availabilityIcon = findAvailabilityIcon(errorMessage)
  const statusError = getStatusErrorMessage(errorMessage)

  const fetchFavZoneInfos = (selectedReservationTime: ReservationTime) => {
    if (
      !!user &&
      !!userZapfloorId &&
      !!currentSite &&
      !!currentSite.locationId &&
      !!favZoneCurrentSite &&
      !!favZoneCurrentSite.profilId &&
      !!favZoneCurrentSite.zoneId
    ) {
      const reservationTimes = getReservationTimes(selectedReservationTime)
      setStatusScreen('loading')
      api.zapfloor
        .getAvailableZones(
          user.type,
          currentSite.id,
          i18n.t('formats.utcDate', { date }),
          favZoneCurrentSite.profilId,
          currentSite.locationId,
          reservationTimes.startTime,
          reservationTimes.endTime,
          userZapfloorId,
          favZoneCurrentSite.zoneId
        )
        .then((res) => {
          setStatusScreen('ok')
          if (res.length > 0) {
            setCurrentFavZoneInfos(res[0])
          }
        })
        .catch(() => {
          setStatusScreen('error')
          Modal.close()
          Alert.open(
            () => <ValidationContent title={i18n.t('screens.planning.delete.error')} onConfirm={Alert.close} />,
            true
          )
        })
    }
  }

  const onBookZone = (zoneInfos: ZapfloorZone, slot: ReservationTime) => {
    const findZapfloorZonePoi = poisInfos.find((poi) => poi.zapfloorId === zoneInfos.attributes.unit_id)
    const findZapfloorDeskPoi = poisInfos.filter(
      (poi) => zoneInfos.attributes.unit_id === poi.zapfloorId?.split('/')[0]
    )
    if (!!findZapfloorDeskPoi && !!findZapfloorZonePoi) {
      if (!!userZapfloorId) {
        const hotDesk = poisInfos.filter(
          (area) =>
            findZapfloorZonePoi.uniquePlaceName === area.uniquePlaceName.split('/')[0] && area !== findZapfloorZonePoi
        )
        Modal.open(() => (
          <DeskSelectionModal
            userId={userZapfloorId}
            teamId={favZone.attributes.access_group_id}
            closeModal={Modal.close}
            location={zoneInfos}
            initialDate={date}
            refreshData={refreshData}
            reservationTime={selectedReservationTime}
            navigation={navigation}
            selectedAreas={hotDesk}
            fromFavoriteZone={true}
            zapfloorPois={poisInfos}
            parkingMode={favZoneCurrentSite?.isParking || false}
            visitorMode={false}
            visits={[]}
          />
        ))
      }
    } else {
      bookFavZone(selectedReservationTime)
    }
  }

  const openTimeSlotSelector = () =>
    Alert.open(
      () => (
        <ReservationTimeModal
          title={i18n.t('screens.desk.time')}
          options={TIMEOPTIONS}
          setChoice={(choice) => {
            fetchFavZoneInfos(choice as ReservationTime)
            setReservationTime(choice as ReservationTime)
          }}
          typeModal="alert"
          type="time"
        />
      ),
      true
    )

  return (
    <MainContainer>
      <HeaderTitle>{i18n.t('screens.planning.favoriteZone.title')}</HeaderTitle>

      <DateContainer>
        <DateLabel>
          {stringUtils.capitalize(
            i18n.t('screens.parking.dateBooking', {
              date: date,
            })
          )}
        </DateLabel>
      </DateContainer>
      <RowContainer>
        <LeftContainer>
          <InfosContainer>
            <IconContainer>
              <Icon
                name="id_card"
                size={20}
                color={theme.colors.blue}
                ariaLabel={i18n.t('screens.planning.iconsAction.team')}
              />
            </IconContainer>

            <Label>{profilName}</Label>
          </InfosContainer>
          <InfosContainer>
            <IconContainer>
              <Icon
                name={favZoneCurrentSite?.isParking ? 'parking' : 'desk'}
                size={20}
                color={theme.colors.blue}
                ariaLabel={i18n.t('screens.planning.iconsAction.zone')}
              />
            </IconContainer>

            <Label>{zoneName}</Label>
          </InfosContainer>
          <OccupancyInfo>
            <IconContainer>
              <Icon
                name="users-wm"
                color={theme.colors.blue}
                size={20}
                ariaLabel={i18n.t('screens.planning.iconsAction.occupancy')}
              />
            </IconContainer>
            <Occupancy>
              {i18n.t('screens.planning.secondForm.occupancy', {
                occupancy: desks_taken,
                capacity: desk_count,
              })}
            </Occupancy>
            <OccupationComponent currentCapacity={desks_taken} maxCapacity={desk_count} />
          </OccupancyInfo>
          {!!user && !favZoneCurrentSite?.isParking && (
            <PicturesList
              clickable
              peopleList={hot_desk_bookings.map((people) => ({
                id: people.user_id,
                firstname: people.user_firstname,
                lastname: people.user_lastname,
                email: people.user_email,
                timeFrom: people.time_from,
                timeTo: people.time_to,
              }))}
              withAlert
              navigation={navigation}
            />
          )}
        </LeftContainer>

        <StatusContainer>
          <Icon name={availabilityIcon} size={26} color={availabilityColor} />
        </StatusContainer>
      </RowContainer>
      <RowContainer>
        <Label>{i18n.t('screens.planning.firstForm.timeSlot')}</Label>
        <ChoiceContainer
          tabIndex={0}
          onClick={() => {
            if (currentSite?.halfDayDeskBooking) {
              openTimeSlotSelector()
            }
          }}
          isDisabled={!currentSite?.halfDayDeskBooking}>
          <ChoiceText isDisabled={!currentSite?.halfDayDeskBooking}>
            {i18n.t(`screens.desk.reservationTime.${selectedReservationTime}`)}
          </ChoiceText>
          <IconContainer>
            <Icon name="chevron_right" color={theme.colors.darkGrey} />
          </IconContainer>
        </ChoiceContainer>
      </RowContainer>

      {errorMessage !== 'ok' && (
        <MessageContainer>
          <Message color={availabilityColor}>{i18n.t(getMessageError(errorMessage, true))}</Message>
        </MessageContainer>
      )}
      <ButtonContainer>
        <Button
          font={theme.fonts.h3Bold}
          onClick={() => onBookZone(currentFavZoneInfos, selectedReservationTime)}
          label={i18n.t('screens.planning.booking.bookFavZoneModal')}
          disabled={statusError === 'error'}
          loading={statusScreen === 'loading'}
        />
        <Margin />
        {!!bookOtherZone && (
          <Button
            font={theme.fonts.h3Bold}
            onClick={bookOtherZone}
            label={i18n.t('screens.planning.booking.bookOtherZoneModal')}
            color={theme.colors.white}
            textColor={theme.colors.blue}
            shadow
          />
        )}
      </ButtonContainer>
    </MainContainer>
  )
}

export default FavoriteZoneDetails

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 15px;
  }
  width: 300px;
`

const HeaderTitle = styled.p`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 0 0 20px 0;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const Margin = styled.div`
  height: 15px;
`

const InfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 12px 6px 0px;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
`

const DateContainer = styled.div`
  display: flex;
  margin: 20px 0px;
`

const DateLabel = styled.p`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0 0 6px 6px;
`

const RowContainer = styled.div`
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
  margin: 0px 0px 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`

const IconContainer = styled.div`
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`

const Occupancy = styled.p`
  margin: 0px 10px 0 0;
  ${(props) => props.theme.fonts.body};
`

const OccupancyInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 12px 6px 0px;
`

const MessageContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 8px;
`

const Message = styled('p')<{ color: string }>`
  ${(props) => props.theme.fonts.label};
  margin-top: 0px;
  color: ${(props) => props.color};
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`

const LeftContainer = styled.div``

const ChoiceContainer = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: flex-end;
  ${(props) => !props.isDisabled && 'cursor: pointer;'}
  flex: 3;
`

const ChoiceText = styled.p<{ isDisabled?: boolean }>`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => (props.isDisabled ? props.theme.colors.middleGrey : props.theme.colors.blue)};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`
