import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as bannersStore from 'banners/store'

import useNavigation from 'utils/navigation/useNavigation'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { showedDate } from 'news/utils'
import analytics, { analyticsKeys } from 'utils/analytics'
import utils from 'utils/strings'

import Button from 'components/button/Button'
import NewsImage from 'news/NewsImage'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import ModalContent from 'components/modal/ValidationContent'
import Banner from 'components/banner/Banner'

import api from '../api'
import { executeGraphRequest } from 'authent365/utils/utils'
import * as store from '../store'
import useBreakpoint from 'utils/useBreakpoints'

import Loader from 'react-loader-spinner'
import { formatDistanceToNow, format, addDays } from 'date-fns'

interface Props {
  limit?: number
  isHomePage?: boolean
  navigate?: () => void
}

// On enlève la partie sur les filtres mais elle pourra revenir un jour
// const ALL_TAGS = ['CAMPUS', 'INFORMATION', 'URGENT']

const NewsScreen = ({ limit, isHomePage = false, navigate }: Props) => {
  const [Theme] = useTheme()
  const [, currentBreakpoint] = useBreakpoint()
  const i18n = useI18n()
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const news = useReducer(store.store, (s) => s.news)
  const banners = useReducer(bannersStore.store, (s) => s.banners)
  // On enlève la partie sur les filtres mais elle pourra revenir un jour
  // const type = useReducer(store.store, (s) => s.type)
  // const [tags, setTags] = React.useState<string[]>(['INFORMATION', 'URGENT', 'CAMPUS'])
  const navigation = useNavigation()
  const user = useReducer(userStore.store, (s) => s.user)

  const [status, setStatus] = React.useState<'loading' | 'error' | 'ok'>('loading')

  const showedNews = news.filter((r, i) => !limit || i < limit)

  const showedBanner = React.useMemo(() => banners.find((banner) => !!banner.feature && banner.feature === 'NEWS'), [
    banners,
  ])

  const load = (displayLoading?: boolean) => {
    if (displayLoading) {
      setStatus('loading')
    }
    if (site && user) {
      api
        .all(site.id, user.type)
        .then((articleList) => {
          store.actions.setNews(articleList.news)
          setStatus('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }

  React.useEffect(() => {
    load()
    if (user) {
      analytics.track({ screenName: analyticsKeys.newsList, userType: user.type, currentSite: site, mySites })
    }
  }, [i18n.lang])

  const onEnterKey = (e: React.KeyboardEvent, onClickFunc: () => void) => {
    if (e.key === 'Enter') {
      onClickFunc()
    }
  }

  const addToCalendar = (news: NewsSimple) => {
    if (!!user && !!site) {
      if (!news.startDate || !news.endDate) {
        return null
      }

      const startDate = `${format(new Date(news.startDate), 'yyyy-MM-dd')}T${
        (!news.entireDay && news.startTime) || '00:00:00'
      }`
      const endDate = `${format(
        !!news.entireDay ? addDays(new Date(news.endDate), 1) : new Date(news.endDate),
        'yyyy-MM-dd'
      )}T${(!news.entireDay && news.endTime) || '00:00:00'}`

      const event = {
        start: {
          dateTime: startDate,
          timeZone: site.timezone,
        },
        end: {
          dateTime: endDate,
          timeZone: site.timezone,
        },
        isAllDay: !!news.entireDay,
        subject: news.title,
      }

      executeGraphRequest(user.type, '/me/events', 'POST', event)
        .then(() =>
          Modal.open(() => (
            <ModalContent
              title={i18n.t(`screens.news.calendarSucceed`)}
              onConfirm={Modal.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ))
        )
        .catch(() =>
          Modal.open(() => (
            <ModalContent
              title={i18n.t(`screens.news.calendarFailed`)}
              onConfirm={Modal.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ))
        )
    }
  }

  // On enlève la partie sur les filtres mais elle pourra revenir un jour
  // const displayInfo = () => store.actions.setDisplayedType('INFO')

  // const displayEvent = () => store.actions.setDisplayedType('EVENT')

  const renderArticle = (article: NewsSimple, articleIndex: number) => {
    const goToDetails = () => navigation.push(`/news/${article.id}`)

    return (
      <ArticleContainer
        onClick={goToDetails}
        tabIndex={0}
        onKeyDown={(e) => onEnterKey(e, goToDetails)}
        key={article.id}>
        <PhotoContainer>
          <NewsImage
            imageURL={article.photo?.url}
            animation={false}
            alt={article.photo?.description || ''}
            width={372}
            height={230}
          />
        </PhotoContainer>
        <ArticleInfoContainer>
          <TagContainer>
            <Tag>{i18n.t(`screens.news.tags.${article.tag}`)}</Tag>
          </TagContainer>
          <TitleArticle>{article.title}</TitleArticle>
          {article.type === 'EVENT' && !!article.startDate && !!article.endDate && (
            <EventDateContainer>
              <Icons name="agenda" size={18} color={Theme.colors.blue} />
              <ArticleDate marginLeft>
                {showedDate(i18n, new Date(article.startDate), new Date(article.endDate))}
              </ArticleDate>
              <AddButton
                onClick={(e) => {
                  e.stopPropagation()
                  addToCalendar(article)
                }}>
                <AddText>{i18n.t('common.add')}</AddText>
              </AddButton>
            </EventDateContainer>
          )}
          <BottomContainer>
            {(article.lifecycle === 'DRAFT' || article.lifecycle === 'APPROVED') && (
              <TagContainer>
                <Icons name="pencil" color={Theme.colors.blue} size={25} />
                <StatusText>
                  {i18n.t(`screens.news.${article.lifecycle === 'DRAFT' ? 'DRAFT' : 'scheduledDate'}`, {
                    date: new Date(article.publicationDate),
                  })}
                </StatusText>
              </TagContainer>
            )}
            {article.lifecycle !== 'DRAFT' && article.lifecycle !== 'APPROVED' && (
              <ArticleDate color={Theme.colors.strongTurquoise}>
                {utils.capitalize(
                  i18n.t('screens.news.publicationDate', {
                    duration: formatDistanceToNow(new Date(article.publicationDate), {
                      locale: i18n.locale,
                    }),
                  })
                )}
              </ArticleDate>
            )}
          </BottomContainer>
        </ArticleInfoContainer>
      </ArticleContainer>
    )
  }

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (status === 'error') {
    return (
      <ErrorContainer>
        <NoNewsError>{i18n.t('screens.news.list.error')}</NoNewsError>
        <ErrorButtonContainer>
          <Button label={i18n.t('common.retry')} onClick={() => load(true)} font={Theme.fonts.h3Bold} />
        </ErrorButtonContainer>
      </ErrorContainer>
    )
  }

  if (news.length === 0) {
    return <NoNewsMessage>{i18n.t('screens.news.list.noNews')}</NoNewsMessage>
  }

  return (
    <>
      {!isHomePage && <TitleHelmet title={i18n.t('screens.news.title')} />}
      {!isHomePage && <Breadcrumb screen="news" path="news" navigation={navigation} />}

      <MainContainer>
        {!!showedBanner && (
          <Banner banner={showedBanner} marginTop={20} marginRight={currentBreakpoint !== 'phone' ? 20 : 0} />
        )}
        <TitleContainer>
          <Title>{i18n.t('screens.news.title')}</Title>
          {!!limit && !!navigate && <SeeMore onClick={navigate}>{i18n.t('common.seeAll')}</SeeMore>}
          {
            // On enlève la partie sur les filtres mais elle pourra revenir un jour
            /* {!isHomePage && (
            <TypeContainer>
              <TypeSpace>
                <TypeSelector onClick={displayInfo}>
                  <TypeText selected={type.toUpperCase() === 'INFO'}>
                    {i18n.t('screens.news.list.switch.news')}
                  </TypeText>
                </TypeSelector>
              </TypeSpace>
              <Separator>|</Separator>
              <TypeSpace>
                <TypeSelector onClick={displayEvent}>
                  <TypeText selected={type.toUpperCase() === 'EVENT'}>
                    {i18n.t('screens.news.list.switch.events')}
                  </TypeText>
                </TypeSelector>
              </TypeSpace>
            </TypeContainer>
          )} */
          }
        </TitleContainer>
        {/* {!isHomePage && (
          <FilterContainer>
            <FilterButton
              color={Theme.colors.blue}
              iconName="options"
              onClick={() => Modal.open(() => ModalContent(ALL_TAGS, tags, setTags))}
              iconSize={28}
              circleSize={56}
              useFilter={!isHomePage}
              ariaLabel={i18n.t('label.ariaLabel.news.filterOptions')}
            />
          </FilterContainer>
        )} */}
        {news.length === 0 ? (
          <NoNewsMessage>{i18n.t('screens.news.list.noNews')}</NoNewsMessage>
        ) : (
          <NewsList>{showedNews.map((a, i) => renderArticle(a, i))}</NewsList>
        )}
      </MainContainer>
    </>
  )
}

export default NewsScreen

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 60px;
  margin-left: 5%;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-right: 2%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-right: 4%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 4%;
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const Title = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  margin: 0px;
`
const NewsList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    align-items: center;
    justify-content: center;
    gap: 0px;
    padding-top: 0px;
    margin-left: 0px;
  }
`
const ArticleContainer = styled.div`
  width: 372px;
  cursor: pointer;
  margin: 0px;
  margin-right: 30px;
  padding-bottom: 20px;
  ${(props) => props.theme.constants.shadow.card};
  margin-bottom: 20px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 20px 0px 0px 0px;
    width: 90%;
  }
  :hover {
    transform: scale(1.02);
  }
`
const PhotoContainer = styled('div')`
  display: flex;
  height: 230px;
  width: 372px;
  background-color: ${(props) => props.theme.colors.placeholderGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`
const ArticleInfoContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 10px 0;
  height: 200px;
`
const TagContainer = styled('div')`
  display: flex;
  align-items: center;
`
const Tag = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  color: ${(props) => props.theme.colors.blue};
  text-align: center;
  margin: 0;
`
const TitleArticle = styled('h3')`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 12px 0px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`
const ArticleDate = styled.p<{ marginLeft?: boolean; color?: string }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => (!!props.color ? props.color : props.theme.colors.blue)};
  margin: 0px;
  ${(props) => props.marginLeft && 'margin-left: 8px;'}
`
const NoNewsMessage = styled('p')`
  margin-top: 40px;
  margin-left: 15px;
  ${(props) => props.theme.fonts.body};
`
const TitleContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 40px;
  margin-right: 120px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-right: 80px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-top: 70px;
    margin-right: 40px;
  }
`
// On enlève la partie sur les filtres mais elle pourra revenir un jour
// const TypeContainer = styled('div')`
//   display: flex;
//   align-items: center;
//   height: 0px;
//   margin-bottom: 4px;
//   margin-left: 80px;
//   @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
//     margin-left: 20px;
//     margin-bottom: 2px;
//   }
// `
// const Separator = styled('p')`
//   ${(props) => props.theme.fonts.body};
//   color: ${(props) => props.theme.colors.darkGrey};
//   font-size: 20px;
//   padding-bottom: 4px;
//   line-height: 0px;
//   @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
//     font-size: 20px;
//   }
// `
// const TypeSpace = styled('div')`
//   display: flex;
//   margin: 0px 20px;
//   justify-content: center;
//   @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
//     margin: 0px 5px;
//   }
// `
const NoNewsError = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    text-align: center;
  }
`
const ErrorButtonContainer = styled('div')`
  display: flex;
  width: 300px;
`
const ErrorContainer = styled('div')`
  padding: 30px 50px;
  min-height: calc(100vh - 176px);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px 10px;
    min-height: calc(100vh - 136px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

// FILTER

// On enlève la partie sur les filtres mais elle pourra revenir un jour
// const FilterContainer = styled.div`
//   display: flex;
//   position: fixed;
//   top: 110px;
//   right: 8%;
//   @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
//     top: 70px;
//   }
// `

const StatusText = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 0px 5px;
  color: ${(props) => props.theme.colors.middleGrey};
`

const EventDateContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const BottomContainer = styled('div')`
  display: flex;
  flex: 1;
  align-items: flex-end;
  margin: 0px;
`

const SeeMore = styled('button')`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  text-decoration: underline;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const AddButton = styled('button')`
  padding-left: 8px;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.blue};
  &:hover {
    color: ${(props) => props.theme.colors.blue};
    text-decoration: none;
  }
`

const AddText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.blue};
`
