import ws from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  all: (siteId: string, userType: UserType, limit?: number, tags?: string[]) =>
    assureToken(userType).then((token) => {
      const queryString = `?siteId=${siteId}${limit ? `&limit=${limit}` : ''}${tags ? `&tags=${tags.join()}` : ''}`
      return ws<NewsList>(userType, `${config.globalAPIVersion}/news${queryString}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    }),
  article: (id: string, siteId: string, userType: UserType, lang?: string) =>
    assureToken(userType).then((token) =>
      ws<NewsDetail>(userType, `${config.globalAPIVersion}/news/${id}?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
        ...(lang ? { 'Accept-Language': lang } : {}),
      })
    ),
  like: (id: string, siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      ws<void>(userType, `${config.globalAPIVersion}/news/${id}/like?siteId=${siteId}`, 'POST', {
        Authorization: `Bearer ${token}`,
      })
    ),
  unlike: (id: string, siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      ws<void>(userType, `${config.globalAPIVersion}/news/${id}/like?siteId=${siteId}`, 'DELETE', {
        Authorization: `Bearer ${token}`,
      })
    ),
}

export default api
