import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import graphApi from 'directory/api'

import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as contactStore from 'directory/contactStore'
import * as userStore from 'store/user/user'

import Icons from 'components/icons/Icons'

interface Props {
  contact: EngieUser
  isLast: boolean
  onSelectContact: (contact: EngieUser) => void
  updateFavorites?: (favorites: FavoriteIds[]) => void
}

const ContactInfosEngie = ({ contact, isLast, onSelectContact, updateFavorites }: Props) => {
  const [Theme] = useTheme()
  const favorites = useReducer(contactStore.store, (s) => s.favorites)
  const isFavorite = !!favorites.find((favorite) => favorite.engieUserId === contact.engieUserId)
  const userType = useReducer(userStore.store, (s) => s.user?.type || 'ENGIE')

  const modifyFavorite = () => {
    if (isFavorite) {
      const updatedFav = favorites.filter((f) => f.engieUserId !== contact.engieUserId)
      contactStore.actions.setFav(updatedFav)
      if (updateFavorites) {
        updateFavorites(updatedFav)
      }
    } else {
      graphApi
        .getUserByMail(userType, contact.userEmail)
        .then(
          (res) =>
            !!res &&
            contactStore.actions.setFav([
              ...favorites,
              { idGraph: res.id !== '' ? res.id : undefined, engieUserId: contact.engieUserId },
            ])
        )
        .catch((err) => {
          Logger.error(err)
          contactStore.actions.setFav([...favorites, { engieUserId: contact.engieUserId }])
        })
    }
  }

  const onClickContact = () => onSelectContact(contact)

  return (
    <MainContainer>
      <ContactContainer onClick={onClickContact}>
        <ContactLeftContainer>
          <div>
            <PictureContainer>
              <ContactLetters>
                {contact.firstName[0]}
                {contact.lastName[0]}
              </ContactLetters>
            </PictureContainer>
          </div>
          <ContactInfosContainer>
            <ContactName>
              {contact.lastName} {contact.firstName}
            </ContactName>
            <ContactMail>{contact.userEmail}</ContactMail>
          </ContactInfosContainer>
        </ContactLeftContainer>
      </ContactContainer>

      <HeartIconContainer onClick={modifyFavorite}>
        <Icons name={isFavorite ? 'heart-filled' : 'heart'} size={26} color={Theme.colors.blue} />
      </HeartIconContainer>

      {!isLast && <BlueLineBreak />}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  position: relative;
`
const ContactContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px 10px 3px 10px;
  padding-right: 14px;
  &:hover {
    background-color: ${(props) => props.theme.colors.lightGrey};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 3px 0px;
    padding-right: 0px;
  }
`
const ContactInfosContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 197px;
  word-wrap: break-word;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`
const ContactLeftContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 12px 14px 12px 14px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 12px 0px;
  }
`
const HeartIconContainer = styled('button')`
  display: flex;
  position: absolute;
  top: calc(50% - 13px);
  right: 24px;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
  }
`
const PictureContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 28px;
  background-color: ${(props) => props.theme.colors.mediumDarkGrey};
`

// TEXTES

const ContactName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const ContactMail = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const ContactLetters = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.darken};
  text-transform: uppercase;
  margin-left: 1.5px;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 50px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
  margin-left: 24px;
`

export default ContactInfosEngie
