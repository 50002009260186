import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  getMyInterventions: (siteId: string, userType: UserType, incidentType?: 'DI' | 'FLEET') =>
    assureToken(userType).then((token) =>
      Webservice<IncidentList>(
        userType,
        `${config.globalAPIVersion}/incidents?siteId=${siteId}${!!incidentType ? `&incidentType=${incidentType}` : ''}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  getRequestCategories: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<CategoryList>(userType, `${config.globalAPIVersion}/incidents/categories?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getParentCategories: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<ParentCategoryList>(
        userType,
        `${config.globalAPIVersion}/incidents/parentCategories?siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  getRoomRequests: (roomId: string, siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<IncidentList>(userType, `${config.globalAPIVersion}/incidents/${roomId}?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getAllFloorLocations: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<LocationList>(userType, `${config.globalAPIVersion}/incidents/locations?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  createIncident: (incidentForm: FormData, siteId: string, userType: UserType, incidentType?: 'DI' | 'FLEET') =>
    assureToken(userType).then((token) =>
      Webservice<IncidentCreationResponse>(
        userType,
        `${config.globalAPIVersion}/incidents?siteId=${siteId}${!!incidentType ? `&incidentType=${incidentType}` : ''}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data;',
        },
        undefined,
        incidentForm
      )
    ),
  createInterventionClaim: (claimForm: FormData, siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<Complaint>(
        userType,
        `${config.globalAPIVersion}/complaints?siteId=${siteId}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        undefined,
        claimForm
      )
    ),
  suggestionImageIa: (suggestionForm: FormData, siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<ImageDescription>(
        userType,
        `${config.globalAPIVersion}/incidents/ia/image?siteId=${siteId}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        undefined,
        suggestionForm
      )
    ),
  getFleetVehiclesInfos: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<FleetVehicleList>(userType, `${config.globalAPIVersion}/fleet?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
}

export default api
