import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as sitesStore from 'sites/store/siteStore'

import useBreakpoint from 'utils/useBreakpoints'

import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'

import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'

type ServiceType = 'commuTable' | 'tableService' | 'packedLunch' | 'surveys' | 'solde' | 'roomService'

interface Props {
  title: string
  barclapModuleData?: BarclapModuleDatas
  iconName: IconName
  serviceName: ServiceType
  onClick: () => void
  onSecondaryClick?: () => void
  packedLunchBooking?: BarclapBooking
  tableCommuBooking?: BarclapBooking
  tableServiceBooking?: BarclapBooking
  surveysList?: Survey[]
  width?: number
}

interface Service {
  label: string
  booking?: BarclapBooking
  subLabel?: string
  isOpen?: boolean
  disabled?: boolean
}

const ServiceDetails = ({
  barclapModuleData,
  iconName,
  serviceName,
  onClick,
  onSecondaryClick,
  packedLunchBooking,
  tableCommuBooking,
  tableServiceBooking,
  surveysList,
  width,
  title,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const [, currentBreakpoint] = useBreakpoint()

  const currentSite = useReducer(sitesStore.store, (s) => s.site)
  const badges = useReducer(userStore.store, (s) => s.badges)
  const lastBadgesRefresh = useReducer(userStore.store, (s) => s.lastBadgesRefresh)

  const currentBadge = React.useMemo(
    () => (!!currentSite ? badges.find((b) => b.siteId === currentSite.id) : undefined),
    [currentSite, badges]
  )
  const solde = React.useMemo(() => currentBadge?.solde, [currentBadge])

  const isOpen = !!barclapModuleData ? barclapModuleData.is_open : false

  const isDisabled = !!barclapModuleData
    ? (!isOpen ||
        !barclapModuleData.module_timeslots[0] ||
        barclapModuleData.module_timeslots[0].remaining_booking <= 0) &&
      serviceName === 'packedLunch' &&
      !packedLunchBooking
    : false
  const remainingBookings = !!barclapModuleData
    ? isDisabled
      ? 0
      : barclapModuleData.module_timeslots.reduce((acc, cur) => acc + cur.remaining_booking, 0)
    : 0

  Object.getOwnPropertyNames(languages).forEach((lang) => {
    numbro.registerLanguage(languages[lang])
  })
  numbro.setLanguage('fr-FR')

  const service: Service = React.useMemo(() => {
    switch (serviceName) {
      case 'packedLunch':
        return {
          label: i18n.t(`screens.catering.services.packedLunch${!!packedLunchBooking ? 'Booked' : 'Left'}`, {
            count: isOpen ? remainingBookings : 0,
          }),
          isOpen: !packedLunchBooking ? isOpen : undefined,
          booking: packedLunchBooking,
          disabled: isDisabled,
        }
      case 'tableService':
        return {
          label: i18n.t(`screens.catering.services.table${tableServiceBooking ? 'Booked' : 'sLeft'}`),
          isOpen,
          booking: tableServiceBooking,
        }
      case 'commuTable':
        return {
          label: i18n.t(`screens.catering.services.table${tableCommuBooking ? 'Booked' : 'sLeft'}`),
          isOpen,
          booking: tableCommuBooking,
        }
      case 'surveys':
        return {
          label: !!surveysList
            ? i18n.t('screens.surveys.surveysLeft', {
                count: surveysList.length,
              })
            : '',
        }
      case 'solde':
        return {
          label: solde !== undefined && solde !== null ? numbro(solde).formatCurrency({ mantissa: 2 }) : '',
          subLabel: i18n.t('screens.catering.services.lastSoldeRefresh', {
            date: lastBadgesRefresh ? new Date(lastBadgesRefresh) : new Date(),
          }),
        }
      case 'roomService':
        return {
          label: i18n.t('screens.catering.services.roomServiceLabel'),
        }
    }
  }, [
    serviceName,
    remainingBookings,
    solde,
    lastBadgesRefresh,
    isOpen,
    packedLunchBooking,
    tableCommuBooking,
    tableServiceBooking,
    i18n.lang,
  ])

  return (
    <ServiceContainer
      disabled={isDisabled}
      width={width}
      onClick={() => {
        if (!isDisabled) {
          onClick()
        }
      }}>
      <IconContainer>
        <Icon size={25} name={iconName} color={Theme.colors.blue} />
      </IconContainer>
      <InfosServiceContainer>
        <ServiceName>{title}</ServiceName>
        <ServiceInfo>{service.label}</ServiceInfo>
        {service.isOpen !== undefined ? (
          <ServiceStatusContainer>
            <StatusIndicator free={isOpen} />
            <StatusText color={isOpen ? Theme.colors.turquoise : Theme.colors.red}>
              {i18n.t(`screens.catering.status.${isOpen ? 'open' : 'close'}`)}
            </StatusText>
          </ServiceStatusContainer>
        ) : (
          !!service.subLabel && (
            <ServiceStatusContainer>
              <StatusText>{service.subLabel}</StatusText>
            </ServiceStatusContainer>
          )
        )}
      </InfosServiceContainer>
      <RightIconContainer>
        {serviceName === 'solde' && !!onSecondaryClick && currentBreakpoint !== 'mediumBig' ? (
          <div onClick={(evt) => evt.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
            <Button
              label={i18n.t('components.solde.recharge')}
              width={80}
              onClick={onSecondaryClick}
              verticalPadding={6}
            />
          </div>
        ) : (
          <Icon size={20} name="chevron_right" color={Theme.colors.black} />
        )}
      </RightIconContainer>
    </ServiceContainer>
  )
}

export default ServiceDetails

const ServiceContainer = styled('button')<{ disabled: boolean; width?: number }>`
  background-color: ${(props) => (!!props.disabled ? props.theme.colors.mediumLightGrey : props.theme.colors.white)};
  padding: 20px 40px;
  margin: 10px;
  display: flex;
  ${(props) => props.width && `width: ${props.width}px;`};
  ${(props) => !props.width && `min-width: 315px;`};
  min-height: 70px;
  ${(props) => !props.width && `flex: 1;`}
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${(props) => props.theme.constants.shadow.card};
  cursor: ${(props) => (!!props.disabled ? 'not-allowed' : 'pointer')};
  border: 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 20px 0px 0px 0px;
    min-width: 250px;
  }
`
const ServiceStatusContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 0px;
`

const ServiceName = styled('p')`
  text-align: start;
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`

const ServiceInfo = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 6px 0px;
`

const IconContainer = styled('div')`
  margin-right: 20px;
`

const RightIconContainer = styled('div')`
  margin-left: 20px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

const InfosServiceContainer = styled('div')``

const StatusIndicator = styled('div')<{ free: boolean }>`
  width: 4px;
  height: 4px;
  background-color: ${(props) => (props.free ? props.theme.colors.turquoise : props.theme.colors.red)};
  border-radius: 2px;
  margin: 0px 5px;
`

const StatusText = styled('p')<{ color?: string }>`
  ${(props) => props.theme.fonts.label};
  ${(props) => props.color && 'color : ' + props.color};
  margin: 0px;
`
