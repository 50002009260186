import React, { useState } from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import Banner from 'components/banner/Banner'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import utils from 'utils/strings'

import { isWeekend, addBusinessDays, getDay, isAfter, isBefore, isSameDay, compareAsc } from 'date-fns'
import useI18n from 'i18n/useI18n'

import api from '../api'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as planningStore from 'planning/planningStore'
import * as bannersStore from 'banners/store'
import { getDatesInInterval, getDayTimezone, getMaxDate } from '../utils'

const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
  if (e.key === 'Enter') {
    onClick()
  }
}

interface Props {
  reservationDate: Date
  setReservationDate: (date: Date) => void
  datesToAvoid: Date[]
  plate?: string
  refreshData: () => void
  zapfloorBookings: ZapfloorBooking[]
  vamosParkingBookings: VamosBooking[]
  poisInfos: AreaLight[]
}

const CURRENT_DATE = new Date()

const MIN_DATE = !isWeekend(CURRENT_DATE) ? CURRENT_DATE : addBusinessDays(CURRENT_DATE, 1)

const MAX_DAYS = 30

const VamosReservationForm = ({
  reservationDate,
  setReservationDate,
  datesToAvoid,
  plate,
  refreshData,
  zapfloorBookings,
  vamosParkingBookings,
  poisInfos,
}: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const maxDaysInFuture = useReducer(planningStore.store, (s) => s.maxDaysInFuture)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false)
  const [isBooking, setIsBooking] = React.useState(false)

  const showedBanner = React.useMemo(
    () => banners.find((banner) => !!banner.feature && banner.feature === 'VAMOS_PARKING'),
    [banners]
  )

  const MAX_DATE = React.useMemo(
    () => getMaxDate(MIN_DATE, MAX_DAYS, maxDaysInFuture, currentSite?.maxDeskBookingDays),
    [MIN_DATE, currentSite, maxDaysInFuture]
  )

  const minSelectableDate = React.useMemo(() => {
    const sortedZapfloorBookings = zapfloorBookings.sort((a, b) =>
      compareAsc(getDayTimezone(a.attributes.date_from), getDayTimezone(b.attributes.date_from))
    )
    const findMinSelectableDate = sortedZapfloorBookings.find(
      (zapfloorBooking) =>
        !vamosParkingBookings.find((vamosBooking) =>
          isSameDay(getDayTimezone(vamosBooking.startDateTime), getDayTimezone(zapfloorBooking.attributes.date_from))
        )
    )
    return !!findMinSelectableDate ? getDayTimezone(findMinSelectableDate.attributes.date_from) : new Date()
  }, [zapfloorBookings, vamosParkingBookings])

  const datesToDisabled = getDatesInInterval(MIN_DATE, MAX_DATE, [1, 2, 3, 4, 5]).filter(
    (date) => !zapfloorBookings.find((b) => isSameDay(date, getDayTimezone(b.attributes.date_from)))
  )

  const disabledDates = [...getDatesInInterval(MIN_DATE, MAX_DATE, [0, 6]), ...datesToDisabled, ...datesToAvoid]

  React.useEffect(() => {
    if (disabledDates.find((date) => isSameDay(date, reservationDate))) {
      setReservationDate(minSelectableDate)
    } else if (isAfter(minSelectableDate, reservationDate)) {
      setReservationDate(minSelectableDate)
    } else if (isBefore(MAX_DATE, reservationDate)) {
      setReservationDate(MAX_DATE)
    }
  }, [minSelectableDate, reservationDate])

  const filterWeekend = (date: Date) =>
    ![0, 6].includes(getDay(date)) &&
    !!zapfloorBookings.find((b) => isSameDay(date, getDayTimezone(b.attributes.date_from)))

  const onClickBook = () => {
    if (!!user && !!currentSite) {
      const findBookingZapfloor = zapfloorBookings.find((booking) =>
        isSameDay(getDayTimezone(booking.attributes.date_from), reservationDate)
      )
      const findPOI = !!findBookingZapfloor
        ? poisInfos.find((poi) => poi.zapfloorId === findBookingZapfloor.attributes.hot_desk_items[0].unit_id)
        : undefined

      const floorNumber = !!findPOI ? findPOI.floor : undefined

      if (!plate) {
        Alert.open(
          () => (
            <ValidationContent
              title={i18n.t('screens.planning.parking.genericError', { errorCode: 'NO_PLATE' })}
              onConfirm={Alert.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ),
          true
        )
        return
      }
      if (floorNumber === undefined) {
        Alert.open(
          () => (
            <ValidationContent
              title={i18n.t('screens.planning.parking.genericError', { errorCode: 'NO_FLOOR_NUMBERS' })}
              onConfirm={Alert.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ),
          true
        )
        return
      }

      setIsBooking(true)

      const body: VamosBookingRequest = {
        floorNumber: !!findPOI ? parseInt(findPOI.floor) : undefined,
        plate,
        startDate: `${i18n.t('screens.planning.secondForm.dateToSend', {
          date: reservationDate,
        })}T00:00:00.000Z`,
      }
      api.vamos
        .postVamosBooking(user.type, currentSite.id, body)
        .then((res) => {
          const tandemAlreadyBooked = !!res.tandem.tandemPlace && !!res.tandem.tandemBooked && !!res.tandem.tandemBooked
          refreshData()
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.planning.modal.confirmedRegistration', { count: 1 })}
                description={
                  tandemAlreadyBooked
                    ? i18n.t('screens.planning.parking.tandemAlreadyBooked', {
                        firstName: res.tandem.contactTandem?.firstName,
                        lastName: res.tandem.contactTandem?.lastName,
                      })
                    : undefined
                }
                onConfirm={() => {
                  Alert.close()
                }}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        })
        .catch((err) =>
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.planning.modal.errorRegistration')}
                description={
                  err.data.error
                    ? i18n.t('screens.planning.parking.genericError', { errorCode: err.data.error })
                    : undefined
                }
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        )
        .finally(() => setIsBooking(false))
    }
  }
  return (
    <MainContainer>
      {!!showedBanner && <Banner banner={showedBanner} />}
      <RowContainer>
        <Label>{i18n.t('screens.planning.firstForm.date')}</Label>
        <ChoiceContainer>
          <CliquableDiv
            tabIndex={0}
            onClick={() => setIsDatePickerVisible(true)}
            onKeyDown={(e) => onEnterKey(e, () => setIsDatePickerVisible(true))}>
            <ChoiceText>
              {utils.capitalize(i18n.t('screens.planning.reservationDate', { date: reservationDate }))}
            </ChoiceText>
            <IconContainer>
              <Icons name="chevron_right" color={Theme.colors.darkGrey} />
            </IconContainer>
          </CliquableDiv>
          {isDatePickerVisible && (
            <CustomDatePicker
              selectedDate={reservationDate}
              onChange={setReservationDate}
              minDate={MIN_DATE}
              maxDate={MAX_DATE}
              closePicker={() => setIsDatePickerVisible(false)}
              filterDate={filterWeekend}
              excludeDates={datesToAvoid}
            />
          )}
        </ChoiceContainer>
      </RowContainer>
      <BlueLine role="presentation" />
      <ButtonContainer>
        <Button
          label={i18n.t('screens.planning.modal.confirmReservation', {
            count: 1,
          })}
          onClick={onClickBook}
          color={Theme.colors.blue}
          textColor={Theme.colors.white}
          font={Theme.fonts.h3Bold}
          loading={isBooking}
        />
      </ButtonContainer>
    </MainContainer>
  )
}

export default VamosReservationForm

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  width: 350px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 300px;
  }
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.label}
  margin: 0px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex: 1;
`

const CliquableDiv = styled.div`
  cursor: pointer;
  display: flex;
`

const ChoiceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  flex: 3;
`

const ChoiceText = styled.p`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => props.theme.colors.blue};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`

const IconContainer = styled.div``

const BlueLine = styled.div`
  width: 60px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
  margin-bottom: 15px;
`

const ButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  margin-top: 10px;
`
