import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as featureStore from 'sites/store/featureStore'
import * as formStore from 'store/form/formStore'
import useReducer from 'store/useReducer'
import useI18n from 'i18n/useI18n'
import { supportedLang } from 'i18n/i18n'
import UserImage from 'authent365/utils/UserPicture'
import useNavigation from 'utils/navigation/useNavigation'
import { useLocation } from 'react-router-dom'

import Icons from 'components/icons/Icons'
import DropDown from 'components/dropdown/DropDownHeader'

import { logout } from 'authent365/utils/utils'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { LANG_LIST } from 'authent365/screens/LanguageChoice'

import FocusLock from 'react-focus-lock'

import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'
import { Language } from 'i18n/types'

interface Props {
  drawerOpened: boolean
  setDrawerOpened: (opened: boolean) => void
  userPicture?: string
}

const myPortalLink = 'https://engiegbs.service-now.com/myportal'

const Header = ({ drawerOpened, setDrawerOpened, userPicture }: Props) => {
  const [Theme] = useTheme()
  const user = useReducer(userStore.store, (r) => r.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const isAccount = useLocation().pathname.indexOf('/account') > -1
  const navigation: Navigation = useNavigation()
  const sortedLang = supportedLang.sort()

  const planVisible = useReducer(featureStore.store, (f) => f.features.find((f: Feature) => f.type === 'CARTOGRAPHY'))
  const confirmNavigation = useReducer(formStore.store, (s) => s.confirmNavigation)

  const path = navigation.getLocation().pathname

  const i18n = useI18n()

  const [visible, setVisible] = React.useState(false)
  const [langVisible, setLangVisible] = React.useState(false)
  const langTitleRef = React.useRef<any>()
  const menuTitleRef = React.useRef<any>()

  const logoutUser = () => {
    if (user) {
      logout(user.type)
      navigation.push('/login')
    }
  }

  const confirmLeave = (goToFunction: () => void, confirmNavigation?: boolean) => {
    if (confirmNavigation) {
      Modal.open(() => (
        <ValidationContent
          title={i18n.t('screens.incident.aboutToLeave')}
          description={i18n.t('screens.incident.wontSave')}
          onConfirm={() => {
            formStore.actions.reset()
            Modal.close()
            goToFunction()
          }}
          cancelButton
          font={Theme.fonts.label}
        />
      ))
    } else {
      goToFunction()
    }
  }

  const goToSites = () => confirmLeave(() => navigation.push('/sites', { origin: 'home' }), confirmNavigation)

  const goToMap = () => confirmLeave(() => navigation.push('/map', { origin: 'home' }), confirmNavigation)

  const toggleMainDropDown = () => setVisible((v) => !v)

  const toggleLanguageDropDown = () => setLangVisible((v) => !v)

  const changeLanguageTo = (lang: string) => {
    navigation.resetTo(lang)
    setLangVisible(false)
  }

  const goToMyPortal = () => {
    window.open(myPortalLink)
    setVisible(false)
  }

  const goToAccount = () => {
    navigation.push('/account')
    setVisible(false)
  }

  const onKeyEnter = (e: React.KeyboardEvent, onClick: () => void) => {
    if (e.key === 'Enter') {
      onClick()
    }
  }

  const renderDropdownTitle = () => (
    <Infos
      onClick={toggleMainDropDown}
      tabIndex={0}
      onKeyDown={(e) => onKeyEnter(e, toggleMainDropDown)}
      ref={menuTitleRef}>
      <WelcomeMessage>{i18n.t('screens.home.hello')}</WelcomeMessage>
      {user && (
        <>
          <Name>{user.firstName}</Name>
          <ImageContainer>
            {
              <UserImage
                userId={user.id}
                userFirstName={user.firstName}
                userLastName={user.lastName}
                userDisplayName={user.displayName}
                size="small"
                color={isAccount ? Theme.colors.white : undefined}
                textColor={isAccount ? Theme.colors.blue : undefined}
                picture={userPicture}
                noWS
              />
            }
          </ImageContainer>
        </>
      )}
    </Infos>
  )

  const renderLangTitle = () => (
    <LangClickable
      onClick={toggleLanguageDropDown}
      tabIndex={0}
      onKeyDown={(e) => onKeyEnter(e, toggleLanguageDropDown)}
      ref={langTitleRef}>
      <CurrentLang>{LANG_LIST[i18n.lang as Language]}</CurrentLang>
    </LangClickable>
  )

  const renderDropdownList = () => (
    <ProfileDropDownContainer>
      <FocusLock disabled={!visible} autoFocus={false} shards={[menuTitleRef]}>
        {site && (
          <DropDownOption
            accountPage={isAccount}
            onClick={() => confirmLeave(goToAccount, confirmNavigation)}
            tabIndex={0}
            onKeyDown={(e) => onKeyEnter(e, goToAccount)}
            aria-label={i18n.t('label.ariaLabel.header.toMyAccount')}>
            <DropDownText>{i18n.t('common.profile')}</DropDownText>
          </DropDownOption>
        )}
        {user?.type !== 'EXTERNAL' && (
          <DropDownOption
            accountPage={isAccount}
            onClick={() => confirmLeave(goToMyPortal, confirmNavigation)}
            tabIndex={0}
            onKeyDown={(e) => onKeyEnter(e, goToMyPortal)}
            aria-label={i18n.t('label.ariaLabel.header.toMyPortal')}>
            <DropDownText>{i18n.t('common.myPortal')}</DropDownText>
            <DropDownIconContainer>
              <Icons name="arrow_out" size={20} />
            </DropDownIconContainer>
          </DropDownOption>
        )}
        <DropDownOption
          accountPage={isAccount}
          onClick={logoutUser}
          tabIndex={0}
          onKeyDown={(e) => onKeyEnter(e, logoutUser)}
          aria-label={i18n.t('label.ariaLabel.header.toMyPortal')}>
          <DropDownText>{i18n.t('common.logout')}</DropDownText>
        </DropDownOption>
      </FocusLock>
    </ProfileDropDownContainer>
  )

  const renderLangList = () => (
    <LangDropDownContainer>
      <FocusLock disabled={!langVisible} autoFocus={false} shards={[langTitleRef]}>
        {sortedLang.map((k) => (
          <DropDownOption
            accountPage={isAccount}
            key={k}
            onClick={() => changeLanguageTo(`/${k}${path.slice(3)}`)}
            tabIndex={0}
            onKeyDown={(e) => onKeyEnter(e, () => changeLanguageTo(`/${k}${path.slice(3)}`))}
            aria-label={i18n.t('label.ariaLabel.header.chooseLanguage', { language: LANG_LIST[k], lng: k })}>
            <DropDownText lang={k}>{LANG_LIST[k]}</DropDownText>
          </DropDownOption>
        ))}
      </FocusLock>
    </LangDropDownContainer>
  )

  return (
    <HeaderContainer>
      {user && (
        <MainContainer>
          <OpenDrawerButtonContainer>
            <DrawerIconContainer onClick={() => setDrawerOpened(!drawerOpened)}>
              <Icons name="burger-menu" size={25} color={Theme.colors.white} />
            </DrawerIconContainer>
          </OpenDrawerButtonContainer>
          <IconContainer>
            <NavigationIcon onClick={() => confirmLeave(() => navigation.push('/'), confirmNavigation)}>
              <Icons name="engie" size={90} ariaLabel={i18n.t('label.svg.engieLogo')} />
            </NavigationIcon>
          </IconContainer>
          <LivingAtContainer>
            <Icons name="logo" color={Theme.colors.white} size={79} height={60} />
          </LivingAtContainer>
          {site && (
            <SiteContainer>
              <SiteName
                onClick={goToSites}
                tabIndex={0}
                onKeyDown={(e) => onKeyEnter(e, goToSites)}
                aria-label={i18n.t('label.ariaLabel.header.toSites')}>
                {site.name}
              </SiteName>
              {!!site.webVisioglobeHash && planVisible && (
                <PlanContainer
                  onClick={goToMap}
                  tabIndex={0}
                  onKeyDown={(e) => onKeyEnter(e, goToMap)}
                  aria-label={i18n.t('label.ariaLabel.header.toMap')}>
                  <PlanTitle>{i18n.t('common.plans')}</PlanTitle>
                  <PinIconContainer>
                    <Icons name="pin" size={12} color={Theme.colors.white} />
                  </PinIconContainer>
                </PlanContainer>
              )}
            </SiteContainer>
          )}
          <DarkBlueContainer>
            <LangContainer accountPage={isAccount}>
              <DropDown
                visible={langVisible}
                setVisible={setLangVisible}
                TitleContent={renderLangTitle}
                ListContent={renderLangList}
              />
            </LangContainer>
            <DarkBlueSeparator accountPage={isAccount} />
            <InfoContainer accountPage={isAccount}>
              <DropDown
                visible={visible}
                setVisible={setVisible}
                TitleContent={renderDropdownTitle}
                ListContent={renderDropdownList}
                isInfo
              />
            </InfoContainer>
          </DarkBlueContainer>
          <DarkBlueCorner accountPage={isAccount} />
        </MainContainer>
      )}
    </HeaderContainer>
  )
}

export default Header

const MainContainer = styled('div')`
  display: flex;
  align-items: center;
  ${(props) => props.theme.fonts.labelBold}
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    justify-content: flex-end;
  }
`
const HeaderContainer = styled('div')`
  padding-left: 100px;
  height: 60px;
  background-color: ${(props) => props.theme.colors.blue};
  box-shadow: 0px 4px 14px ${(props) => props.theme.colors.shadow};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding-left: 0px;
  }
`
const OpenDrawerButtonContainer = styled('div')`
  display: none;
  flex: 1;
  align-items: center;
  height: 60px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: flex;
  }
`
const DrawerIconContainer = styled('button')`
  padding: 10px;
  margin-left: 15px;
`
const Infos = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 13px;
  cursor: pointer;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
    padding-left: 0px;
  }
`
const ImageContainer = styled('div')`
  margin: 10px;
`
const Name = styled('p')`
  ${(props) => props.theme.fonts.h1Bold}
  margin: 0px 0px 0px 10px;
  color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    display: none;
  }
`
const WelcomeMessage = styled('p')`
  ${(props) => props.theme.fonts.thinTitle};
  line-height: 0px;
  margin-bottom: 28px;
  color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: none;
  }
`
const DarkBlueCorner = styled('div')<{ accountPage: boolean }>`
  width: 7%;
  height: 60px;
  background-color: ${(props) => (props.accountPage ? props.theme.colors.darkGrey : props.theme.colors.darkBlue)};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: none;
  }
`
const DarkBlueSeparator = styled('div')<{ accountPage: boolean }>`
  width: 5px;
  height: 60px;
  background-color: ${(props) => (props.accountPage ? props.theme.colors.darkGrey : props.theme.colors.darkBlue)};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: none;
  }
`
const InfoContainer = styled('div')<{ accountPage: boolean }>`
  position: relative;
  display: flex;
  z-index: 20;
  height: 60px;
  background-color: ${(props) => (props.accountPage ? props.theme.colors.darkGrey : props.theme.colors.darkBlue)};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    background-color: ${(props) => (props.accountPage ? props.theme.colors.darkGrey : props.theme.colors.darkBlue)};
    justify-content: flex-end;
  }
`
const SiteName = styled('p')`
  cursor: pointer;
  ${(props) => props.theme.fonts.bodyBold}
  color: ${(props) => props.theme.colors.white};
  font-size: 14px;
  margin: 5px 0px;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.darkGrey};
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
`
const SiteContainer = styled.div`
  display: flex;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: none;
  }
`
const IconContainer = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  height: 60px;
  margin-left: 8%;
  overflow: hidden;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    display: none;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: flex;
    margin-left: 0px;
    justify-content: center;
  }
`
const NavigationIcon = styled('a')`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
`
const PlanContainer = styled('div')`
  cursor: pointer;
  display: flex;
  margin-top: -5px;
  margin-bottom: 5px;
  margin-left: 45px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
`
const PlanTitle = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  color: ${(props) => props.theme.colors.white};
  line-height: 0px;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.darkGrey};
  }
`
const PinIconContainer = styled('div')`
  margin-top: 6px;
  margin-left: 4px;
`
const LangContainer = styled('div')<{ accountPage: boolean }>`
  position: relative;
  display: flex;
  z-index: 20;
  height: 60px;
  margin-left: 15px;
  background-color: ${(props) => (props.accountPage ? props.theme.colors.darkGrey : props.theme.colors.darkBlue)};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: absolute;
  }
`
const CurrentLang = styled('p')`
  ${(props) => props.theme.fonts.h1Bold}
  font-size: 18px;
  margin-right: 18px;
  padding-top: 2px;
  color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    font-size: 16px;
    width: 70px;
    margin: 0px;
  }
`
const LangClickable = styled('div')`
  flex: 1;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 18px;
  cursor: pointer;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0px 13px;
    padding-left: 0px;
  }
`
const LangDropDownContainer = styled('div')`
  margin-top: 2px;
  max-height: 200px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    background-color: ${(props) => props.theme.colors.blue};
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.white};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.colors.mediumDarkGrey};
  }

  scrollbar-color: ${(props) => props.theme.colors.white} transparent;
`
const ProfileDropDownContainer = styled('div')`
  margin-top: 2px;
`
const DarkBlueContainer = styled('div')`
  display: flex;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 140px;
    justify-content: flex-end;
    flex: 2;
  }
`
const LivingAtContainer = styled('div')`
  display: flex;
  margin-right: 30px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: none;
  }
`

// DROPDOWN

const DropDownOption = styled('div')<{ accountPage: boolean }>`
  cursor: pointer;
  display: flex;
  height: 50px;
  background-color: ${(props) => props.theme.colors.blue};
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${(props) => props.theme.colors.darkBlue};
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    background-color: ${(props) => props.theme.colors.darkBlue};
    &:hover {
      background-color: ${(props) => props.theme.colors.blue};
    }
  }
`
const DropDownText = styled('p')`
  margin: 0;
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    font-size: 15px;
  }
`
const DropDownIconContainer = styled('div')`
  margin-left: 10px;
`
