import React from 'react'
import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Button from 'components/button/Button'
import ChoiceList from 'components/picker/ChoiceList'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'

import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import useReducer from 'store/useReducer'

import api from '../api'
import parkingApi from 'parking/parkingApi'

import Logger from 'utils/Logger'

import { isAfter } from 'date-fns'
import { isSamePlaceBookedSharvy } from 'parking/utils'

interface Props {
  closeModal: () => void
  barclapBooking?: BarclapBooking
  parkingBooking?: ParkingSpotRequest
  zapfloorBookings: ZapfloorBooking[]
  zapfloorParkingBookings: ZapfloorBooking[]
  vamosParkingBooking?: VamosBooking
  sharvyParkingBookings: SharvyParkingBooking[]
  onDelete: () => void
}

const canBarclapBeDeleted = (booking: BarclapBooking) => isAfter(new Date(booking.end_time), new Date())

const canZapfloorBeDeleted = (booking: ZapfloorBooking) =>
  isAfter(new Date(booking.attributes.date_time_to), new Date())

const canCommutyBeDeleted = (booking: ParkingSpotRequest) => isAfter(new Date(booking.startTime), new Date())

const DeleteModal = ({
  closeModal,
  barclapBooking,
  zapfloorBookings,
  onDelete,
  parkingBooking,
  zapfloorParkingBookings,
  vamosParkingBooking,
  sharvyParkingBookings,
}: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const [loading, setLoading] = React.useState(false)

  const sameSharvyPlaceBooked = isSamePlaceBookedSharvy(sharvyParkingBookings)

  const allChoices: Choice[] = [
    ...(zapfloorBookings.length === 1
      ? [
          {
            key: 'zapfloor',
            text: i18n.t('screens.planning.delete.zapfloor'),
            disabled: !canZapfloorBeDeleted(zapfloorBookings[0]),
          },
        ]
      : zapfloorBookings.length === 2
      ? [
          {
            key: 'firstZapfloor',
            text: i18n.t('screens.planning.delete.firstZapfloor'),
            disabled: !canZapfloorBeDeleted(zapfloorBookings[0]),
          },
          {
            key: 'secondZapfloor',
            text: i18n.t('screens.planning.delete.secondZapfloor'),
            disabled: !canZapfloorBeDeleted(zapfloorBookings[1]),
          },
        ]
      : []),
    ...(zapfloorParkingBookings.length === 1
      ? [
          {
            key: 'zapfloorParking',
            text: i18n.t('screens.planning.delete.parking'),
            disabled: !canZapfloorBeDeleted(zapfloorParkingBookings[0]),
          },
        ]
      : zapfloorParkingBookings.length === 2
      ? [
          {
            key: 'firstZapfloorParking',
            text: i18n.t('screens.planning.delete.firstZapfloorParking'),
            disabled: !canZapfloorBeDeleted(zapfloorParkingBookings[0]),
          },
          {
            key: 'secondZapfloorParking',
            text: i18n.t('screens.planning.delete.secondZapfloorParking'),
            disabled: !canZapfloorBeDeleted(zapfloorParkingBookings[1]),
          },
        ]
      : []),
    ...(barclapBooking
      ? [
          {
            key: 'barclap',
            text: i18n.t('screens.planning.delete.barclap'),
            disabled: !canBarclapBeDeleted(barclapBooking),
          },
        ]
      : []),
    ...(parkingBooking
      ? [
          {
            key: 'parking',
            text: i18n.t('screens.planning.delete.parking'),
            disabled: !canCommutyBeDeleted(parkingBooking),
          },
        ]
      : []),
    ...(vamosParkingBooking
      ? [
          {
            key: 'vamos_parking',
            text: i18n.t('screens.planning.delete.parking'),
          },
        ]
      : []),
    ...(sharvyParkingBookings.length === 1
      ? [
          {
            key: 'sharvyParking',
            text: i18n.t('screens.planning.delete.parking'),
          },
        ]
      : sharvyParkingBookings.length === 2 && sameSharvyPlaceBooked
      ? [
          {
            key: 'sharvyParkingAllDay',
            text: i18n.t('screens.planning.delete.parking'),
          },
        ]
      : sharvyParkingBookings.length === 2
      ? [
          {
            key: 'firstSharvyParking',
            text: i18n.t('screens.planning.delete.firstZapfloorParking'),
          },
          {
            key: 'secondSharvyParking',
            text: i18n.t('screens.planning.delete.secondZapfloorParking'),
          },
        ]
      : []),
  ]

  const [activeChoices, setActiveChoices] = React.useState<Choice[]>([])

  const deleteBookings = () => {
    const barclap = !!activeChoices.find((choice) => choice.key === 'barclap')
    const firstZapfloor = !!activeChoices.find((choice) => choice.key === 'firstZapfloor' || choice.key === 'zapfloor')
    const secondZapfloor = !!activeChoices.find((choice) => choice.key === 'secondZapfloor')
    const firstZapfloorParking = !!activeChoices.find(
      (choice) => choice.key === 'firstZapfloorParking' || choice.key === 'zapfloorParking'
    )
    const secondZapfloorParking = !!activeChoices.find((choice) => choice.key === 'secondZapfloorParking')
    const parking = !!activeChoices.find((choice) => choice.key === 'parking')
    const vamosParking = !!activeChoices.find((choice) => choice.key === 'vamos_parking')
    const firstSharvyParking = !!activeChoices.find(
      (choice) =>
        choice.key === 'firstSharvyParking' || choice.key === 'sharvyParking' || choice.key === 'sharvyParkingAllDay'
    )
    const secondSharvyParking = !!activeChoices.find(
      (choice) => choice.key === 'secondSharvyParking' || choice.key === 'sharvyParkingAllDay'
    )

    if (user && currentSite) {
      const promises = [
        ...(firstZapfloor && zapfloorBookings.length > 0
          ? [api.zapfloor.deleteBooking(user.type, currentSite.id, zapfloorBookings[0].id)]
          : []),
        ...(secondZapfloor && zapfloorBookings.length > 1
          ? [api.zapfloor.deleteBooking(user.type, currentSite.id, zapfloorBookings[1].id)]
          : []),
        ...(firstZapfloorParking && zapfloorParkingBookings.length > 0
          ? [api.zapfloor.deleteBooking(user.type, currentSite.id, zapfloorParkingBookings[0].id)]
          : []),
        ...(secondZapfloorParking && zapfloorParkingBookings.length > 1
          ? [api.zapfloor.deleteBooking(user.type, currentSite.id, zapfloorParkingBookings[1].id)]
          : []),
        ...(parking && parkingBooking
          ? [parkingApi.deleteParkingBooking(currentSite.id, user.type, parkingBooking.id)]
          : []),
        ...(vamosParking && vamosParkingBooking
          ? [api.vamos.deleteVamosBookings(user.type, currentSite.id, vamosParkingBooking.bookingExternalId)]
          : []),
        ...(firstSharvyParking && secondSharvyParking
          ? [
              api.sharvy.deleteSharvyBookings(user.type, currentSite.id, {
                bookings: sharvyParkingBookings.map((booking) => ({
                  isMorning: booking.isMorning,
                  reservationDate: i18n.t('screens.planning.secondForm.dateToSend', {
                    date: new Date(booking.reservationDate || ''),
                  }),
                })),
              }),
            ]
          : firstSharvyParking
          ? [
              api.sharvy.deleteSharvyBookings(user.type, currentSite.id, {
                bookings: [
                  {
                    isMorning: sharvyParkingBookings[0].isMorning,
                    reservationDate: i18n.t('screens.planning.secondForm.dateToSend', {
                      date: new Date(sharvyParkingBookings[0].reservationDate || ''),
                    }),
                  },
                ],
              }),
            ]
          : secondSharvyParking
          ? [
              api.sharvy.deleteSharvyBookings(user.type, currentSite.id, {
                bookings: [
                  {
                    isMorning: sharvyParkingBookings[1].isMorning,
                    reservationDate: i18n.t('screens.planning.secondForm.dateToSend', {
                      date: new Date(sharvyParkingBookings[0].reservationDate || ''),
                    }),
                  },
                ],
              }),
            ]
          : []),
        ...(barclap && barclapBooking && currentSite.barclapId
          ? [
              api.barclap.deleteBooking(
                user.type,
                currentSite.id,
                currentSite.barclapId,
                barclapBooking.module_uuid,
                barclapBooking.id
              ),
            ]
          : []),
      ]

      setLoading(true)

      Promise.all(promises)
        .then(() => {
          closeModal()
          onDelete()
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t(
                  `screens.planning.booking.${promises.length > 1 ? 'successDeletePlural' : 'successDelete'}`
                )}
                onConfirm={Alert.close}
              />
            ),
            true
          )
        })
        .catch((err) => {
          Logger.error(err)
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.planning.delete.error')}
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        })
        .finally(() => setLoading(false))
    }
  }

  React.useEffect(() => {
    if (!!vamosParkingBooking) {
      const findAllDeskBooking =
        (!!activeChoices.find((choice) => choice.key === 'firstZapfloor') &&
          !!activeChoices.find((choice) => choice.key === 'secondZapfloor')) ||
        !!activeChoices.find((choice) => choice.key === 'zapfloor')
      const parkingNotActive = !activeChoices.find((choice) => choice.key === 'vamos_parking')
      const parkingDisable = !!activeChoices.find((choice) => choice.key === 'vamos_parking' && choice.disabled)
      if (findAllDeskBooking && parkingNotActive) {
        setActiveChoices([
          ...activeChoices,
          {
            key: 'vamos_parking',
            text: i18n.t('screens.planning.delete.parking'),
            disabled: true,
          },
        ])
      } else if (findAllDeskBooking && !parkingNotActive && !parkingDisable) {
        const updatedActiveChoices = activeChoices.map((choice) => {
          if (choice.key === 'vamos_parking') {
            return { ...choice, disabled: true }
          }
          return choice
        })
        setActiveChoices(updatedActiveChoices)
      } else if (!findAllDeskBooking && !parkingNotActive && parkingDisable) {
        const updatedActiveChoices = activeChoices.map((choice) => {
          if (choice.key === 'vamos_parking') {
            return { ...choice, disabled: false }
          }
          return choice
        })
        setActiveChoices(updatedActiveChoices)
      }
    }
  }, [activeChoices])

  return (
    <MainContainer>
      <HeaderTitle>{i18n.t('screens.planning.delete.title')}</HeaderTitle>

      <Subtitle>{i18n.t('screens.planning.delete.description')}</Subtitle>

      <ChoiceList
        allChoices={allChoices}
        activeChoices={activeChoices}
        setActiveChoices={setActiveChoices}
        multipleChoices
      />

      <ButtonContainer>
        <Button
          font={theme.fonts.h3Bold}
          onClick={deleteBookings}
          label={i18n.t('screens.planning.delete.deleteSelection')}
          loading={loading}
          color={theme.colors.blue}
          textColor={theme.colors.white}
          disabled={activeChoices.length === 0}
        />
        <Separator />
        <Button
          font={theme.fonts.h3Bold}
          onClick={closeModal}
          label={i18n.t('common.cancel')}
          disabled={loading}
          color={theme.colors.white}
          textColor={theme.colors.blue}
          shadow
        />
      </ButtonContainer>
    </MainContainer>
  )
}

export default DeleteModal

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 15px;
  }
  min-width: 200px;
  max-width: 400px;
`

const HeaderTitle = styled.p`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 0 0 20px;
`

const Subtitle = styled.p`
  ${(props) => props.theme.fonts.bodyBold};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const Separator = styled.div`
  height: 20px;
`
