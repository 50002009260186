import React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'
import Shortcut from 'components/button/Shortcut'
import Modal from 'components/modal/Modal'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import ReservationForm from './reservation/ReservationForm'
import LocationSelectionForm from './reservation/LocationSelectionForm'
import ReferenceWeek from './myPlanning/ReferenceWeek'
import RecapReferenceWeek from './myPlanning/RecapReferenceWeek'
import FavoriteZone from './myPlanning/FavoriteZone'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import DayPlanning from './myPlanning/DayPlanning'
import VamosReservationForm from './reservation/VamosReservationForm'
import SharvyReservationForm from './reservation/SharvyReservationForm'
import Banner from 'components/banner/Banner'

import api from './api'
import apiParking from 'parking/parkingApi'
import restaurantsApi from 'restaurants/api/restaurantsApi'
import mapApi from 'map/mapApi'
import preRegistrationApi from 'account/accountApi'
import * as bannersStore from 'banners/store'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as planningStore from 'planning/planningStore'
import * as featureStore from 'sites/store/featureStore'

import useNavigation from 'utils/navigation/useNavigation'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys, analyticsActionKeys } from 'utils/analytics'
import Logger from 'utils/Logger'
import {
  getDayOfWeek,
  getDatesWeekSelected,
  dateCanBeDisplayed,
  getBookingReservationTime,
  filteredBookings,
  getDatesInInterval,
  getDayTimezone,
  getMaxDate,
} from './utils'

import useI18n from 'i18n/useI18n'
import * as dateUtils from 'date-fns'
import { setHours, setMinutes, isBefore, isSameDay, compareAsc, startOfDay, isWeekend } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import Loader from 'react-loader-spinner'
import { refreshSSOToken } from './ssoToken'
import config from 'core/src/config'
import QRCode from 'react-qr-code'

type Status = 'loading' | 'ok' | 'error'

interface Props {
  search?: string
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const now = dateUtils.isWeekend(new Date()) ? dateUtils.addBusinessDays(new Date(), 1) : new Date()

const MAX_DAYS = 30

const PlanningScreen = ({ search }: Props) => {
  const [Theme] = useTheme()
  const navigation = useNavigation()

  const features = useReducer(featureStore.store, (f) => f.features)
  const badges = useReducer(userStore.store, (s) => s.badges)
  const maxDaysInFuture = useReducer(planningStore.store, (s) => s.maxDaysInFuture)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())

  const isMobile = React.useMemo(() => windowDimensions.width <= windowSizeBreakpoints.phone, [
    windowDimensions,
    windowSizeBreakpoints,
  ])

  const query = new URLSearchParams(search)
  const date = query.get('date')

  const deskZapfloorVisitor = React.useMemo(
    () => !!features.find((feature) => feature.type === 'ZAPFLOOR_VISITOR_DESK'),
    [features]
  )
  const parkingZapfloorVisitor = React.useMemo(
    () => !!features.find((feature) => feature.type === 'ZAPFLOOR_VISITOR_PARKING'),
    [features]
  )

  const [zapfloorStatus, setZapfloorStatus] = React.useState<Status>('ok')
  const [barclapStatus, setBarclapStatus] = React.useState<Status>('ok')
  const [parkingStatus, setParkingStatus] = React.useState<Status>('ok')
  const [vamosStatus, setVamosStatus] = React.useState<Status>('ok')
  const [sharvyStatus, setSharvyStatus] = React.useState<Status>('ok')
  const [poisStatus, setPoisStatus] = React.useState<Status>('ok')
  const [visitorDeskInfos, setVisitorDeskInfos] = React.useState<UserResponse>()
  const screenStatus = React.useMemo(
    () =>
      zapfloorStatus === 'loading' ||
      barclapStatus === 'loading' ||
      parkingStatus === 'loading' ||
      vamosStatus === 'loading' ||
      poisStatus === 'loading'
        ? 'loading'
        : zapfloorStatus === 'error' || poisStatus === 'error'
        ? 'error'
        : 'ok',
    [zapfloorStatus, barclapStatus, parkingStatus, vamosStatus, poisStatus, sharvyStatus]
  )

  const [today, setToday] = React.useState<Date>(date && dateCanBeDisplayed(new Date(date)) ? new Date(date) : now)
  const [firstDay, setFirstDay] = React.useState<Date>(now)
  const [lastDay, setLastDay] = React.useState<Date>(now)
  const [isReserving, setIsReserving] = React.useState(false)
  const [isReservingParkingZapfloor, setIsReservingParkingZapfloor] = React.useState(false)
  const [isReservingVamosParking, setIsReservingVamosParking] = React.useState(false)
  const [isReservingSharvyParking, setIsReservingSharvyParking] = React.useState(false)
  const [isProfilesLoading, setIsProfilesLoading] = React.useState(false)
  const [profiles, setProfiles] = React.useState<ZapfloorProfil[]>([])
  const [selectedProfil, setSelectedProfil] = React.useState<ZapfloorProfil>()
  const [reservationDate, setReservationDate] = React.useState<Date>(now)
  const [selectedUser, setSelectedUser] = React.useState<UserResponse>()
  const [selectedVisitor, setSelectedVisitor] = React.useState<PersonalVisit>()
  const [selectedReservationTime, setSelectedReservatonTime] = React.useState<ReservationTime>('ALL_DAY')
  const [bookings, setBookings] = React.useState<ZapfloorBooking[]>([])
  const [weekZonesInfo, setWeekZonesInfos] = React.useState<{ [key: string]: FavoriteZone[] }>({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
  })
  const [zapfloorPois, setZapfloorPois] = React.useState<AreaLight[]>([])

  const [currentWeekDays, setCurrentWeekDays] = React.useState<Date[]>([])
  const [barclapBookings, setBarclapBookings] = React.useState<BarclapBooking[]>([])
  const [parkingBookings, setParkingBookings] = React.useState<ParkingSpotRequest[]>([])
  const [disableVisitor, setDisableVisitor] = React.useState(true)

  const [vamosUserInfos, setVamosUserInfos] = React.useState<VamosDataUser>()
  const [vamosParkingBookings, setVamosParkingBookings] = React.useState<VamosBooking[]>([])

  const [sharvyInfos, setSharvyInfos] = React.useState<SharvyDataUser>()

  const [restaurants, setRestaurants] = React.useState<Restaurant[]>([])

  const [userIsUnknown, setUserIsUnknown] = React.useState(false)

  const [visitorStatus, setVisitorStatus] = React.useState<'loading' | 'error' | 'ok'>('ok')
  const [visits, setVisits] = React.useState<PersonalVisit[]>([])

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const favZone = useReducer(planningStore.store, (s) => s.favoriteLocation)
  const favZoneCurrentSite = React.useMemo(
    () => (!!currentSite && !!favZone && favZone[currentSite.id] ? favZone[currentSite.id] : undefined),
    [favZone, currentSite]
  )
  const referenceWeek = useReducer(planningStore.store, (s) => s.referenceWeek)
  const referenceWeekCurrentSite = React.useMemo(
    () =>
      !!currentSite && !!referenceWeek && referenceWeek[currentSite.id] ? referenceWeek[currentSite.id] : undefined,
    [currentSite, referenceWeek]
  )

  const badgeSite = React.useMemo(
    () => (!!currentSite ? badges.find((badge) => badge.siteId === currentSite.id) : undefined),
    [badges, currentSite]
  )

  const currentSiteHasZapfloorParking = React.useMemo(
    () => !!features.find((feature) => feature.type === 'ZAPFLOOR_PARKING'),
    [features]
  )

  const currentSiteHasVamosParking = React.useMemo(() => features.find((feature) => feature.type === 'VAMOS_PARKING'), [
    features,
  ])

  const currentSiteHasSharvyParking = features.find((feature) => feature.type === 'SHARVY_PARKING')

  const currentSiteHasParking = features.find((feature) => feature.type === 'PARKING')

  const showedBanner = React.useMemo(() => banners.find((banner) => !!banner.feature && banner.feature === 'SITE'), [
    banners,
  ])

  const vamosParkingAvailableDates = React.useMemo(() => {
    if (bookings.length === 0) {
      return false
    }
    const allDatesList = bookings.reduce((acc, cur) => {
      if (!acc.includes(getDayTimezone(cur.attributes.date_from))) {
        acc.push(getDayTimezone(cur.attributes.date_from))
      }
      return acc
    }, [] as Date[])
    return allDatesList.length !== vamosParkingBookings.length
  }, [vamosParkingBookings, bookings])

  const sharvyParkingAvailableDates = React.useMemo(() => {
    if (!!currentSite) {
      if (!!sharvyInfos) {
        if (!sharvyInfos.bookings) {
          return true
        }
        const maxDate = getMaxDate(now, MAX_DAYS, maxDaysInFuture, currentSite.maxDeskBookingDays)
        const availableDates = getDatesInInterval(now, maxDate, [1, 2, 3, 4, 5])
        const filteredDates = availableDates.filter((date) => {
          const findBookingsDate = sharvyInfos.bookings!.filter((booking) =>
            isSameDay(date, getDayTimezone(booking.reservationDate || ''))
          )
          if (findBookingsDate.length > 1) {
            return false
          }
          return true
        })
        return filteredDates.length > 0
      }
      return false
    }
    return false
  }, [sharvyInfos, currentSite, maxDaysInFuture])

  const bookingsByDay = React.useMemo(
    () =>
      currentWeekDays.length === 5
        ? {
            [currentWeekDays[0].toString()]: filteredBookings(bookings, currentWeekDays[0]),
            [currentWeekDays[1].toString()]: filteredBookings(bookings, currentWeekDays[1]),
            [currentWeekDays[2].toString()]: filteredBookings(bookings, currentWeekDays[2]),
            [currentWeekDays[3].toString()]: filteredBookings(bookings, currentWeekDays[3]),
            [currentWeekDays[4].toString()]: filteredBookings(bookings, currentWeekDays[4]),
          }
        : {},

    [bookings, currentWeekDays]
  )

  const datesToAvoid = React.useMemo(() => {
    const filteredBookings = bookings.filter((booking) =>
      isReservingParkingZapfloor
        ? booking.attributes.description === 'Parking'
        : booking.attributes.description !== 'Parking'
    )

    return filteredBookings
      .filter((b) => {
        const allDayBookings = filteredBookings.filter((booking) =>
          isSameDay(getDayTimezone(booking.attributes.date_from), getDayTimezone(b.attributes.date_from))
        )
        return (
          allDayBookings.length > 1 ||
          getBookingReservationTime(b.attributes.time_from, b.attributes.time_to) === 'ALL_DAY'
        )
      })
      .map((b) => getDayTimezone(b.attributes.date_from))
  }, [bookings, isReserving, isReservingParkingZapfloor])

  const datesToAvoidVamos = React.useMemo(() => vamosParkingBookings.map((b) => getDayTimezone(b.startDateTime)), [
    vamosParkingBookings,
  ])

  const datesVisitorToAvoid = React.useMemo(() => {
    if (!!visits && !!selectedVisitor) {
      const findAllVisits = visits.filter((visit) => visit.multiDayGroupId === selectedVisitor.multiDayGroupId)
      const datesToAvoid = findAllVisits
        .filter(
          (visit) =>
            visit.zapfloorBookings &&
            visit.zapfloorBookings.find((booking) =>
              isReservingParkingZapfloor ? booking.bookingType === 'PARKING' : booking.bookingType === 'DESK'
            )
        )
        .map((visit) => getDayTimezone(visit.day))
      return datesToAvoid
    }
    return []
  }, [visits, selectedVisitor, isReservingParkingZapfloor])

  const availableVisitorDates = React.useMemo(() => {
    if (!!selectedVisitor) {
      const findAllVisits = visits.filter((visit) => visit.multiDayGroupId === selectedVisitor.multiDayGroupId)
      return findAllVisits
    }
    return []
  }, [selectedVisitor, visits])

  const userZapfloorId = useReducer(planningStore.store, (s) => s.zapfloorUserId)
  const userHasAccessGroups = useReducer(planningStore.store, (s) => s.userHasAccessGroups)

  const i18n = useI18n()

  const isTodayWeek = React.useMemo(() => dateUtils.isBefore(firstDay, now), [firstDay, now])
  const lastPlanningDay = React.useMemo(
    () => dateUtils.addDays(now, maxDaysInFuture || currentSite?.maxDeskBookingDays || MAX_DAYS),
    [now, currentSite, maxDaysInFuture]
  )
  const isLastWeek = React.useMemo(() => dateUtils.isSameWeek(lastPlanningDay, firstDay, { weekStartsOn: 1 }), [
    lastPlanningDay,
    firstDay,
  ])

  const minReservationDate = React.useMemo(
    () =>
      !!selectedVisitor && availableVisitorDates.length > 0 ? getDayTimezone(availableVisitorDates[0].day) : undefined,
    [selectedVisitor, availableVisitorDates[0]]
  )

  const maxReservationDate = React.useMemo(
    () =>
      !!selectedVisitor && availableVisitorDates.length > 0
        ? getDayTimezone(availableVisitorDates[availableVisitorDates.length - 1].day)
        : undefined,
    [selectedVisitor, availableVisitorDates[0]]
  )

  const formatDateToSend = (date: Date) => i18n.t('formats.utcDate', { date: date })

  const formRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!!user && !!currentSite && !!badgeSite) {
      const filterText = `visitor.newdesk.${badgeSite.departmentId}`
      api.zapfloor.getUsersSearch(user.type, currentSite?.id, filterText).then((res) => {
        if (res.length > 0) {
          setVisitorDeskInfos(res[0])
          setDisableVisitor(false)
        }
      })
    }
  }, [badgeSite])

  React.useEffect(() => {
    if (currentSite && user) {
      setPoisStatus('loading')
      mapApi
        .getAllAreasLight(currentSite.id, user.type)
        .then((res) => {
          setZapfloorPois(res.areas.filter((poi) => !!poi.zapfloorId))
          setPoisStatus('ok')
        })
        .catch((err) => {
          setPoisStatus('error')
          Logger.error(err)
        })
    }
  }, [user, currentSite])

  React.useEffect(() => {
    if (user && currentSite && currentSiteHasVamosParking) {
      setVamosStatus('loading')

      api.vamos
        .getDataUserVamos(user.type, currentSite.id)
        .then((res) => {
          setVamosUserInfos(res)
          fetchVamosParking()
        })
        .catch(() => setVamosStatus('error'))
    }
  }, [currentSiteHasVamosParking, user, currentSite])

  React.useEffect(() => {
    if (!!user && !!currentSite && currentSiteHasSharvyParking) {
      fetchSharvyInfos()
    }
  }, [user, currentSite, currentSiteHasSharvyParking])

  React.useEffect(() => {
    if (!!user && !!currentSite && (deskZapfloorVisitor || parkingZapfloorVisitor)) {
      setVisitorStatus('loading')
      preRegistrationApi
        .getVisits(currentSite.id, user.type)
        .then((res) => {
          setVisitorStatus('ok')
          const today = startOfDay(new Date())
          const removePastVisits = res.visits
            .filter((visit) => isBefore(today, getDayTimezone(visit.day)))
            .sort((v1, v2) => compareAsc(getDayTimezone(v1.day), getDayTimezone(v2.day)))
          setVisits(removePastVisits)
        })
        .catch(() => setVisitorStatus('error'))
    }
  }, [user, currentSite, deskZapfloorVisitor, parkingZapfloorVisitor, bookings])

  React.useEffect(() => {
    const firstDay = dateUtils.startOfWeek(today, { weekStartsOn: 1 })
    setFirstDay(firstDay)
    setLastDay(dateUtils.addDays(firstDay, 4))
  }, [today])

  React.useEffect(() => {
    if (!!user && !!currentSite) {
      if (!userZapfloorId || !userHasAccessGroups) {
        refreshSSOToken(user.type, currentSite.id, 'ZAPFLOOR').catch(() => {
          setUserIsUnknown(true)
        })
      }
    }
  }, [user, userZapfloorId, userHasAccessGroups])

  React.useEffect(() => {
    fetchBarclapBookings()
    fetchRestaurants()
    fetchParking()
    if (!!user && !!currentSite && !!mySites) {
      analytics.track({ screenName: analyticsKeys.zapfloor, userType: user.type, currentSite, mySites })
    }
  }, [user, currentSite, mySites])

  React.useEffect(() => {
    fetchZapfloorBookings()
  }, [user, currentSite, userZapfloorId, zapfloorPois])

  React.useEffect(() => {
    setCurrentWeekDays(getDatesWeekSelected(firstDay, lastDay))
  }, [firstDay, lastDay])

  React.useEffect(() => {
    setWeekZonesInfos({
      ['monday']: [],
      ['tuesday']: [],
      ['wednesday']: [],
      ['thursday']: [],
      ['friday']: [],
    })
  }, [today, bookings])

  const refreshAll = () => {
    if (isReservingVamosParking || isReservingSharvyParking) {
      setIsReservingVamosParking(false)
      setIsReservingSharvyParking(false)
      setIsReserving(false)
    }
    fetchZapfloorBookings()
    fetchBarclapBookings()
    fetchParking()
    fetchVamosParking()
    fetchSharvyInfos()
  }

  const fetchSharvyInfos = () => {
    if (!!user && currentSite && currentSiteHasSharvyParking) {
      setSharvyStatus('loading')
      api.sharvy
        .getUserInfos(user.type, currentSite.id)
        .then((res) => {
          setSharvyStatus('ok')
          setSharvyInfos(res)
        })
        .catch(() => setSharvyStatus('error'))
    }
  }

  const fetchVamosParking = () => {
    if (user && currentSite && currentSiteHasVamosParking) {
      setVamosStatus('loading')
      api.vamos
        .getVamosBookings(user.type, currentSite.id)
        .then((res) => {
          setVamosStatus('ok')
          const filteredBookings = res.vamosBookings?.filter(
            (b) =>
              isBefore(new Date(), setHours(setMinutes(getDayTimezone(b.startDateTime), 59), 23)) &&
              !isWeekend(getDayTimezone(b.startDateTime))
          )
          setVamosParkingBookings(filteredBookings || [])
        })
        .catch(() => setVamosStatus('error'))
    }
  }

  const fetchParking = () => {
    if (!!user && !!currentSite && currentSiteHasParking) {
      setParkingStatus('loading')
      apiParking
        .getParkingBookings(currentSite.id, user.type)
        .then((res) => {
          setParkingStatus('ok')
          setParkingBookings(res.requests)
        })
        .catch(() => setParkingStatus('error'))
    }
  }

  const fetchRestaurants = () => {
    if (!!user && !!currentSite && !!currentSite.barclapId) {
      restaurantsApi
        .getAllRestaurants(user.type, currentSite.id)
        .then((res) => {
          setRestaurants(res.restaurants.filter((r) => r.barclapId))
        })
        .catch((err) => Logger.error('Error when fetching restaurants : ', err))
    }
  }

  const fetchZapfloorBookings = () => {
    if (!!user && !!userZapfloorId && !!currentSite && !!currentSite.locationId) {
      setZapfloorStatus('loading')
      api.zapfloor
        .getBookingsList(
          user.type,
          currentSite.id,
          userZapfloorId,
          formatDateToSend(new Date()),
          currentSite.locationId!
        )
        .then((res) => {
          setBookings(res)
          setZapfloorStatus('ok')
        })
        .catch((err) => {
          setZapfloorStatus('error')
        })
    }
  }

  const fetchBarclapBookings = () => {
    if (!!user && !!currentSite && !!currentSite.barclapId) {
      setBarclapStatus('loading')
      api.barclap
        .getBookings(
          user.type,
          currentSite.id,
          currentSite.barclapId!,
          formatDateToSend(now),
          formatDateToSend(dateUtils.addBusinessDays(now, 5))
        )
        .then((res) => {
          setBarclapStatus('ok')
          setBarclapBookings(res.filter((r) => !Object.values(config.barclap).includes(r.module_uuid)))
        })
        .catch(() => setBarclapStatus('error'))
    }
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions())
  }

  const setReferenceWeek = () => {
    if (!!currentSite) {
      const newReferenceWeek: ReferenceWeek = {
        monday: weekZonesInfo['monday'],
        tuesday: weekZonesInfo['tuesday'],
        wednesday: weekZonesInfo['wednesday'],
        thursday: weekZonesInfo['thursday'],
        friday: weekZonesInfo['friday'],
      }
      if (!!referenceWeekCurrentSite) {
        Alert.open(
          () => (
            <ValidationContent
              title={i18n.t('screens.planning.referenceWeek.alertReferenceWeek')}
              onConfirm={() => {
                Alert.close()
                planningStore.actions.setReferenceWeek({
                  siteId: currentSite.id,
                  weekDaysInfos: newReferenceWeek,
                })
                analytics.track({
                  screenName: analyticsKeys.zapfloor,
                  userType: user?.type,
                  currentSite,
                  mySites,
                  action: analyticsActionKeys.planningSaveRefWeek,
                })
              }}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              cancelButton={true}
              onCancel={Alert.close}
            />
          ),
          true
        )
      } else {
        planningStore.actions.setReferenceWeek({
          siteId: currentSite.id,
          weekDaysInfos: newReferenceWeek,
        })
        analytics.track({
          screenName: analyticsKeys.zapfloor,
          userType: user?.type,
          currentSite,
          mySites,
          action: analyticsActionKeys.planningSaveRefWeek,
        })
      }
    }
  }

  const onDeleteReferenceWeek = () => {
    if (!!currentSite) {
      Alert.open(
        () => (
          <ValidationContent
            title={i18n.t('screens.planning.referenceWeek.deleteReferenceWeek')}
            onConfirm={() => {
              Alert.close()
              const resetReferenceWeek = {
                siteId: currentSite.id,
              }
              planningStore.actions.setReferenceWeek(resetReferenceWeek)
              Modal.close()
            }}
            ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            cancelButton={true}
            onCancel={Alert.close}
          />
        ),
        true
      )
    }
  }

  const onDeleteParking = (bookingId: string) => {
    if (!!user && !!currentSite) {
      Alert.open(
        () => (
          <ValidationContent
            title={i18n.t('screens.planning.booking.confirmDelete')}
            onConfirm={() => {
              setParkingStatus('loading')
              Alert.close()
              apiParking
                .deleteParkingBooking(currentSite.id, user.type, bookingId)
                .then(() => {
                  setParkingStatus('ok')
                  fetchParking()
                  Alert.open(
                    () => (
                      <ValidationContent
                        title={i18n.t('screens.planning.booking.successDelete')}
                        onConfirm={Alert.close}
                      />
                    ),
                    true
                  )
                })
                .catch(() => {
                  setParkingStatus('ok')
                  Alert.open(
                    () => (
                      <ValidationContent
                        title={i18n.t('screens.planning.booking.failureDelete')}
                        onConfirm={Alert.close}
                      />
                    ),
                    true
                  )
                })
            }}
            ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            cancelButton={true}
            onCancel={Alert.close}
          />
        ),
        true
      )
    }
  }

  const onDeleteBooking = (bookingId: string) => {
    if (!!user && !!userZapfloorId && currentSite) {
      Alert.open(
        () => (
          <ValidationContent
            title={i18n.t('screens.planning.booking.confirmDelete')}
            onConfirm={() => {
              setZapfloorStatus('loading')
              Alert.close()
              api.zapfloor
                .deleteBooking(user.type, currentSite.id, bookingId)
                .then(() => {
                  setZapfloorStatus('ok')
                  fetchZapfloorBookings()
                  setReferenceWeek()
                  if (isReservingVamosParking || isReservingSharvyParking) {
                    setIsReservingVamosParking(false)
                    setIsReservingSharvyParking(false)
                    setIsReserving(false)
                  }
                  Alert.open(
                    () => (
                      <ValidationContent
                        title={i18n.t('screens.planning.booking.successDelete')}
                        onConfirm={Alert.close}
                      />
                    ),
                    true
                  )
                })
                .catch(() => {
                  setZapfloorStatus('ok')
                  Alert.open(
                    () => (
                      <ValidationContent
                        title={i18n.t('screens.planning.booking.failureDelete')}
                        onConfirm={Alert.close}
                      />
                    ),
                    true
                  )
                })
            }}
            ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            cancelButton={true}
            onCancel={Alert.close}
          />
        ),
        true
      )
    }
  }

  const onDeleteSharvyBooking = (sharvyBooking: SharvyBookingRequest[]) => {
    if (!!user && currentSite) {
      const body: SharvyDeleteBookingsForm = {
        bookings: sharvyBooking.map((b) => ({
          isMorning: b.isMorning,
          reservationDate: b.reservationDate,
        })),
      }
      Alert.open(
        () => (
          <ValidationContent
            title={i18n.t('screens.planning.booking.confirmDelete')}
            onConfirm={() => {
              setZapfloorStatus('loading')
              setSharvyStatus('loading')
              Alert.close()
              api.sharvy
                .deleteSharvyBookings(user.type, currentSite.id, body)
                .then(() => {
                  setZapfloorStatus('ok')
                  setSharvyStatus('ok')
                  fetchSharvyInfos()
                  if (isReservingVamosParking || isReservingSharvyParking) {
                    setIsReservingVamosParking(false)
                    setIsReservingSharvyParking(false)
                    setIsReserving(false)
                  }
                  Alert.open(
                    () => (
                      <ValidationContent
                        title={i18n.t('screens.planning.booking.successDelete')}
                        onConfirm={Alert.close}
                      />
                    ),
                    true
                  )
                })
                .catch(() => {
                  setZapfloorStatus('ok')
                  setSharvyStatus('ok')
                  Alert.open(
                    () => (
                      <ValidationContent
                        title={i18n.t('screens.planning.booking.failureDelete')}
                        onConfirm={Alert.close}
                      />
                    ),
                    true
                  )
                })
            }}
            ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            cancelButton={true}
            onCancel={Alert.close}
          />
        ),
        true
      )
    }
  }

  const renderFavoriteZone = () => {
    if (
      !!favZoneCurrentSite &&
      !!favZoneCurrentSite.profilId &&
      !!favZoneCurrentSite.profilName &&
      !!favZoneCurrentSite.zoneId &&
      !!favZoneCurrentSite.zoneName
    ) {
      return Modal.open(() => (
        <FavoriteZone
          profilName={favZoneCurrentSite.profilName!}
          zoneName={favZoneCurrentSite.zoneName!}
          onDeleteFavorite={() => {
            if (!!currentSite) {
              planningStore.actions.setFavoriteLocation({
                siteId: currentSite.id,
                isParking: false,
              })
              Modal.close()
            }
          }}
          closeModal={Modal.close}
          isParking={favZoneCurrentSite.isParking}
        />
      ))
    }
    return null
  }

  const onApplyReferenceWeek = () => {
    if (!!referenceWeekCurrentSite && !!userZapfloorId && !!user) {
      Modal.open(() => (
        <RecapReferenceWeek
          referenceWeek={referenceWeekCurrentSite}
          onCancel={Modal.close}
          onClose={() => {
            fetchZapfloorBookings()
            Modal.close()
          }}
          userId={userZapfloorId}
          userType={user.type}
          currentWeekDays={currentWeekDays}
          zapfloorPois={zapfloorPois}
        />
      ))
    }
  }

  if (!!currentSite && !currentSite.locationId) {
    return (
      <LoaderContainer>
        <Title>{i18n.t('screens.planning.errorNoLocationId')}</Title>
      </LoaderContainer>
    )
  }

  if (userIsUnknown) {
    return (
      <LoaderContainer>
        <Title>{i18n.t('screens.planning.errors.userIsUnknown')}</Title>
      </LoaderContainer>
    )
  }

  if (screenStatus === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (!userHasAccessGroups) {
    return (
      <LoaderContainer>
        <Title>{i18n.t('screens.planning.errors.userHasNoAccessGroups')}</Title>
      </LoaderContainer>
    )
  }

  if (screenStatus === 'error') {
    return (
      <LoaderContainer>
        <Title>{i18n.t('screens.planning.errors.planning')}</Title>
      </LoaderContainer>
    )
  }

  const renderButton = (icon: IconName, label: string, onClick: () => void) => (
    <ShortcutContainer>
      <Shortcut
        color={Theme.colors.blue}
        onClick={onClick}
        iconName={icon}
        circleSize={30}
        iconSize={20}
        label={label}
        thirdParty
        useMargin={false}
        useFilter
        font={Theme.fonts.labelBold}
        ariaLabel={label}
      />
    </ShortcutContainer>
  )

  const renderActionButtons = () => (
    <ButtonsContainer>
      {!!favZoneCurrentSite &&
        !!favZoneCurrentSite.profilId &&
        !!favZoneCurrentSite.zoneId &&
        !!favZoneCurrentSite.profilName &&
        !!favZoneCurrentSite.zoneName &&
        renderButton('heart-filled', i18n.t('screens.planning.favoriteZone.seeFavZone'), () => renderFavoriteZone())}
      {!!referenceWeekCurrentSite &&
        renderButton('eye', i18n.t('screens.planning.referenceWeek.seeReferenceWeek'), () => {
          Modal.open(() => (
            <ReferenceWeek
              referenceWeek={referenceWeekCurrentSite}
              onDelete={onDeleteReferenceWeek}
              onApply={onApplyReferenceWeek}
            />
          ))
        })}
      {!!referenceWeekCurrentSite &&
        renderButton('check', i18n.t('screens.planning.referenceWeek.applyReferenceWeek'), () =>
          onApplyReferenceWeek()
        )}
      {renderButton('locker', i18n.t('screens.planning.referenceWeek.setReferenceWeek'), () => setReferenceWeek())}
      {renderButton('desk', i18n.t('screens.planning.bookingDesk.bookingTitle'), () => {
        setIsReserving(true)
        setIsReservingParkingZapfloor(false)
        setIsReservingVamosParking(false)
        setIsReservingSharvyParking(false)
        goToForm()
        setReservationDate(firstDay)
      })}
      {(currentSiteHasZapfloorParking ||
        (currentSiteHasSharvyParking &&
          !!sharvyInfos &&
          !!sharvyInfos.hasParkingAccess &&
          !!sharvyInfos.parkingInfo &&
          !!sharvyInfos.parkingInfo.workingDays &&
          sharvyInfos.parkingInfo.workingDays.length > 0 &&
          sharvyParkingAvailableDates) ||
        (currentSiteHasVamosParking && vamosParkingAvailableDates && !!vamosUserInfos && vamosUserInfos.available)) &&
        renderButton('parking', i18n.t('screens.planning.bookingDesk.parkingTitle'), () => {
          setIsReserving(false)
          setIsReservingParkingZapfloor(!!currentSiteHasZapfloorParking || false)
          setIsReservingVamosParking(!!currentSiteHasVamosParking || false)
          setIsReservingSharvyParking(!!currentSiteHasSharvyParking || false)
          setSelectedProfil(undefined)
          goToForm()
          setReservationDate(firstDay)
        })}
      {/*
          TODO : à décommenter lorsqu'il y aura la partie sur la pause déjeuner

          renderButton('menu', i18n.t('screens.planning.barclap.seeMenu'), () => {
          // TODO
        })

          */}
      {!!userZapfloorId &&
        !!currentSite &&
        !!currentSite.qrCode &&
        renderButton('qrcode', i18n.t('screens.planning.qrCode'), () => {
          Modal.open(() => (
            <QRCodeContainer>
              <QRCode value={userZapfloorId} size={Math.min(400, windowDimensions.width - 40)} />
            </QRCodeContainer>
          ))
        })}
    </ButtonsContainer>
  )

  const renderActionButtonsMobile = () => (
    <ButtonsContainerMobile>
      {!!favZoneCurrentSite &&
        !!favZoneCurrentSite.profilId &&
        !!favZoneCurrentSite.zoneId &&
        !!favZoneCurrentSite.profilName &&
        !!favZoneCurrentSite.zoneName &&
        renderButtonMobile(i18n.t('screens.planning.favoriteZone.seeFavZone'), () => renderFavoriteZone())}
      {!!referenceWeekCurrentSite &&
        renderButtonMobile(i18n.t('screens.planning.referenceWeek.seeReferenceWeek'), () => {
          Modal.open(() => (
            <ReferenceWeek
              referenceWeek={referenceWeekCurrentSite}
              onDelete={onDeleteReferenceWeek}
              onApply={onApplyReferenceWeek}
            />
          ))
        })}
      {!!referenceWeekCurrentSite &&
        renderButtonMobile(i18n.t('screens.planning.referenceWeek.applyReferenceWeek'), () => onApplyReferenceWeek())}
      {renderButtonMobile(i18n.t('screens.planning.referenceWeek.setReferenceWeek'), () => setReferenceWeek())}
      {renderButtonMobile(i18n.t('screens.planning.bookingDesk.bookingTitle'), () => {
        setIsReserving(true)
        setReservationDate(firstDay)
        setIsReservingParkingZapfloor(false)
        setIsReservingVamosParking(false)
        setIsReservingSharvyParking(false)
        Modal.close()
      })}
      {!!userZapfloorId &&
        renderButtonMobile(i18n.t('screens.planning.qrCode'), () => {
          Modal.open(() => (
            <QRCodeContainer>
              <QRCode value={userZapfloorId} size={Math.min(400, windowDimensions.width - 40)} />
            </QRCodeContainer>
          ))
        })}
      {(currentSiteHasZapfloorParking ||
        (currentSiteHasSharvyParking &&
          !!sharvyInfos &&
          !!sharvyInfos.hasParkingAccess &&
          !!sharvyInfos.parkingInfo &&
          !!sharvyInfos.parkingInfo.workingDays &&
          sharvyInfos.parkingInfo.workingDays.length > 0 &&
          sharvyParkingAvailableDates) ||
        (currentSiteHasVamosParking && vamosParkingAvailableDates && !!vamosUserInfos && vamosUserInfos.available)) &&
        renderButtonMobile(i18n.t('screens.planning.bookingDesk.parkingTitle'), () => {
          setIsReserving(false)
          setIsReservingParkingZapfloor(!!currentSiteHasZapfloorParking || false)
          setIsReservingVamosParking(!!currentSiteHasVamosParking || false)
          setIsReservingSharvyParking(!!currentSiteHasSharvyParking || false)
          goToForm()
          setSelectedProfil(undefined)
          setReservationDate(firstDay)
          Modal.close()
        })}
      {/*
          TODO : à décommenter lorsqu'il y aura la partie sur la pause déjeuner

          renderButton('menu', i18n.t('screens.planning.barclap.seeMenu'), () => {
          // TODO
        })

          */}
      <Button
        color={Theme.colors.white}
        onClick={Modal.close}
        label={i18n.t('common.close')}
        font={Theme.fonts.h3Bold}
        ariaLabel={i18n.t('common.close')}
        textColor={Theme.colors.blue}
        shadow
      />
    </ButtonsContainerMobile>
  )

  const renderButtonMobile = (label: string, onClick: () => void) => (
    <MenuButtonContainer>
      <Button
        color={Theme.colors.blue}
        onClick={onClick}
        label={label}
        font={Theme.fonts.h3Bold}
        ariaLabel={label}
        textColor={Theme.colors.white}
      />
    </MenuButtonContainer>
  )

  const goToForm = () => {
    setIsReserving(true)
    if (!isMobile && formRef.current) {
      formRef.current.focus()
    }
  }

  const goToPlanning = () => setIsReserving(false)

  const renderDays = () => (
    <WeekPlanning>
      {Object.entries(bookingsByDay).map((data) => {
        const dayString = data[0]
        const day = new Date(data[0])
        const bookingsDay = data[1]
        const barclapBooking = barclapBookings.filter((b) => isSameDay(getDayTimezone(b.date), day))[0]
        const parkingBooking = parkingBookings.filter(
          (p) =>
            !!currentSite &&
            !!currentSite.timezone &&
            isSameDay(utcToZonedTime(new Date(p.startTime), currentSite.timezone), day)
        )[0]
        const vamosParkingBooking = vamosParkingBookings.find((booking) =>
          isSameDay(getDayTimezone(booking.startDateTime), day)
        )
        if (dateUtils.isAfter(day, lastPlanningDay)) {
          return null
        }
        return (
          <DayPlanning
            key={dayString}
            apiStatus={screenStatus}
            weekDate={day}
            bookings={bookingsDay}
            fetchBarclapBookings={fetchBarclapBookings}
            barclapBooking={barclapBooking}
            parkingBooking={parkingBooking}
            parkingMode={isReservingParkingZapfloor}
            getZoneInfos={(zoneBookingsInfos) => {
              const dayOfWeek = getDayOfWeek(day)
              const newWeekZonesInfo = weekZonesInfo
              weekZonesInfo[dayOfWeek] = zoneBookingsInfos.map((infos) => {
                const hotDeskId =
                  infos.booking?.attributes.hot_desk_items.length > 0
                    ? infos.booking.attributes.hot_desk_items[0].hot_desk_id
                    : undefined
                const desk = zapfloorPois.find((poi) => poi.zapfloorId === hotDeskId)
                return {
                  zoneId: infos.zone.attributes.unit_id,
                  zoneName: infos.zone.attributes.unit_name,
                  profilId: infos.zone.attributes.access_group_id,
                  profilName: infos.zone.attributes.access_group_name,
                  reservationTime: getBookingReservationTime(
                    infos.booking.attributes.time_from,
                    infos.booking.attributes.time_to
                  ),
                  hotDeskId: !!desk ? desk.zapfloorId : undefined,
                  hotDeskName: !!desk ? desk.name : undefined,
                  isParking: infos.zone.attributes.description.toLowerCase().includes('parking'),
                }
              })
              setWeekZonesInfos(newWeekZonesInfo)
            }}
            onDeleteBooking={onDeleteBooking}
            onDeleteParking={onDeleteParking}
            refreshData={refreshAll}
            // Si la date de l'item avec une heure à 23h59 est avant la date actuelle
            // on ne veut plus l'afficher dans mon planning mais on
            // veut quand même appeler le WS pour récupérer les infos
            // de la réservation si il y en a une
            noDisplay={isBefore(setHours(setMinutes(day, 59), 23), new Date())}
            setIsReserving={(isRerserving, isReservingParking, isReservingVamos, isReservingSharvy) => {
              setIsReserving(isRerserving)
              setIsReservingParkingZapfloor(isReservingParking || false)
              setIsReservingVamosParking(isReservingVamos || false)
              setIsReservingSharvyParking(isReservingSharvy || false)
              if (isReservingParking || isReservingVamos || isReservingSharvy) {
                setSelectedProfil(undefined)
              }

              goToForm()
            }}
            setReservationDate={setReservationDate}
            setSelectedUser={() => setSelectedUser(undefined)}
            setSelectedVisitor={() => setSelectedVisitor(undefined)}
            restaurants={restaurants}
            poisInfos={zapfloorPois}
            vamosParkingBooking={vamosParkingBooking}
            vamosUserInfos={vamosUserInfos}
            sharvyInfos={sharvyInfos}
            onDeleteSharvyBooking={onDeleteSharvyBooking}
          />
        )
      })}
    </WeekPlanning>
  )

  if (isMobile) {
    return (
      <MainContainer>
        <TopContainer>
          {!!showedBanner && (
            <Banner banner={showedBanner} marginBottom={20} marginTop={20} marginLeft={20} marginRight={20} />
          )}
          <MobileReservationSelector>
            <ReservationIconContainer selected={!isReserving} onClick={goToPlanning}>
              <Icons
                size={25}
                name="agenda"
                ariaLabel={i18n.t('screens.planning.iconsAction.viewMyPlanning')}
                color={!isReserving ? Theme.colors.white : Theme.colors.darkGrey}
              />
            </ReservationIconContainer>
            <ReservationIconContainer selected={isReserving} onClick={goToForm}>
              <Icons
                size={25}
                name="roombooking"
                ariaLabel={i18n.t('screens.planning.iconsAction.viewReservation')}
                color={isReserving ? Theme.colors.white : Theme.colors.darkGrey}
              />
            </ReservationIconContainer>
          </MobileReservationSelector>
          {!isReserving ? (
            <>
              <WeekSwitchcontainer isMobile>
                <IconContainer
                  isMobile
                  onClick={() => {
                    if (!isTodayWeek) {
                      setToday(dateUtils.addWeeks(today, -1))
                    }
                  }}>
                  <Icons
                    size={20}
                    name="chevron_left"
                    ariaLabel={i18n.t('screens.planning.iconsAction.seePreviousWeek')}
                    color={isTodayWeek ? Theme.colors.mediumDarkGrey : Theme.colors.blue}
                  />
                </IconContainer>
                <DateContainer>
                  {dateUtils.getYear(firstDay) !== dateUtils.getYear(lastDay)
                    ? i18n.t('screens.planning.weekDate2', { startDate: firstDay, endDate: lastDay })
                    : i18n.t('screens.planning.weekDate', { startDate: firstDay, endDate: lastDay })}
                </DateContainer>
                <IconContainer
                  onClick={() => {
                    if (!isLastWeek) {
                      setToday(dateUtils.addWeeks(today, 1))
                    }
                  }}>
                  <Icons
                    size={20}
                    name="chevron_right"
                    ariaLabel={i18n.t('screens.planning.iconsAction.seeNextWeek')}
                    color={isLastWeek ? Theme.colors.mediumDarkGrey : Theme.colors.blue}
                  />
                </IconContainer>
              </WeekSwitchcontainer>

              {renderDays()}
            </>
          ) : isReservingSharvyParking && !!sharvyInfos ? (
            <PlanningReservationContainer>
              <ButtonContainer>
                <Title>{i18n.t('screens.planning.formTitle')}</Title>
              </ButtonContainer>
              <CardsContainer>
                <FormContainer>
                  <SharvyReservationForm
                    reservationDate={reservationDate}
                    setReservationDate={setReservationDate}
                    refreshData={() => {
                      refreshAll()
                      setIsReserving(false)
                      setIsReservingParkingZapfloor(false)
                      setIsReservingVamosParking(false)
                      setIsReservingSharvyParking(false)
                    }}
                    sharvyInfos={sharvyInfos}
                  />
                </FormContainer>
              </CardsContainer>
            </PlanningReservationContainer>
          ) : isReservingVamosParking && vamosUserInfos ? (
            <PlanningReservationContainer>
              <ButtonContainer>
                <Title>{i18n.t('screens.planning.formTitle')}</Title>
              </ButtonContainer>
              <CardsContainer>
                <FormContainer>
                  <VamosReservationForm
                    datesToAvoid={datesToAvoidVamos}
                    reservationDate={reservationDate}
                    setReservationDate={setReservationDate}
                    plate={vamosUserInfos.plate}
                    refreshData={() => {
                      refreshAll()
                      setIsReserving(false)
                      setIsReservingParkingZapfloor(false)
                      setIsReservingVamosParking(false)
                      setIsReservingSharvyParking(false)
                    }}
                    zapfloorBookings={bookings}
                    vamosParkingBookings={vamosParkingBookings}
                    poisInfos={zapfloorPois}
                  />
                </FormContainer>
              </CardsContainer>
            </PlanningReservationContainer>
          ) : (
            <PlanningReservationContainer>
              <ButtonContainer>
                <Title>{i18n.t('screens.planning.formTitle')}</Title>
              </ButtonContainer>
              <FormContainer>
                <ReservationForm
                  setProfiles={setProfiles}
                  selectedTeam={selectedProfil}
                  setSelectedTeam={setSelectedProfil}
                  reservationDate={reservationDate}
                  setReservationDate={setReservationDate}
                  profiles={profiles}
                  selectedUser={selectedUser}
                  setSelectedUser={(user) => {
                    setSelectedUser(user)
                    setSelectedVisitor(undefined)
                  }}
                  datesToAvoid={selectedVisitor ? datesVisitorToAvoid : datesToAvoid}
                  setReservationTime={setSelectedReservatonTime}
                  selectedReservationTime={selectedReservationTime}
                  setIsProfilesLoading={setIsProfilesLoading}
                  navigation={navigation}
                  parkingMode={isReservingParkingZapfloor}
                  setSelectedVisitor={(visitor) => {
                    setSelectedVisitor(visitor)
                    setSelectedUser(undefined)
                  }}
                  selectedVisitor={selectedVisitor}
                  visitorStatus={visitorStatus}
                  visits={visits}
                  minDate={minReservationDate}
                  maxDate={maxReservationDate}
                  disableVisitor={disableVisitor}
                  setReservationType={(type) => {
                    setIsReserving(type === 'DESK')
                    setIsReservingParkingZapfloor(type === 'PARKING')
                    goToForm()
                    setReservationDate(firstDay)
                  }}
                  reservationType={isReservingParkingZapfloor ? 'PARKING' : 'DESK'}
                />
              </FormContainer>
            </PlanningReservationContainer>
          )}
        </TopContainer>

        {!isReserving ? (
          <ButtonMenu onClick={() => Modal.open(() => renderActionButtonsMobile())}>
            <Icons
              size={25}
              name="plus"
              ariaLabel={i18n.t('screens.planning.iconsAction.viewMenu')}
              color={Theme.colors.white}
            />
          </ButtonMenu>
        ) : (
          <BottomContainer>
            {!!selectedProfil && !!userZapfloorId && (
              <FormContainer>
                <LocationSelectionForm
                  isProfilesLoading={isProfilesLoading}
                  reservationDate={reservationDate}
                  userId={(!!selectedUser && selectedUser.id) || userZapfloorId}
                  profil={selectedProfil}
                  selectedReservationTime={selectedReservationTime}
                  refreshData={() => {
                    fetchZapfloorBookings()
                    goToPlanning()
                    setSelectedVisitor(undefined)
                  }}
                  zapfloorPois={zapfloorPois}
                  teamId={selectedProfil.attributes.access_group_id}
                  parkingMode={isReservingParkingZapfloor}
                  visitorMode={!!selectedVisitor}
                  visitorInfos={visitorDeskInfos}
                  selectedVisitor={selectedVisitor}
                  visits={visits}
                />
              </FormContainer>
            )}
          </BottomContainer>
        )}
      </MainContainer>
    )
  }

  return (
    <>
      <Breadcrumb screen="planning" path="planning" navigation={navigation} />

      <MainContainer>
        <TopContainer>
          {!!showedBanner && <Banner banner={showedBanner} marginBottom={20} />}
          <WeekSwitchcontainer>
            <DateContainer>
              {dateUtils.getYear(firstDay) !== dateUtils.getYear(lastDay)
                ? i18n.t('screens.planning.weekDate2', { startDate: firstDay, endDate: lastDay })
                : i18n.t('screens.planning.weekDate', { startDate: firstDay, endDate: lastDay })}
            </DateContainer>
            <ArrowsContainer>
              <IconContainer
                onClick={() => {
                  if (!isTodayWeek) {
                    setToday(dateUtils.addWeeks(today, -1))
                  }
                }}>
                <Icons
                  size={20}
                  name="chevron_left"
                  ariaLabel={i18n.t('screens.planning.iconsAction.seePreviousWeek')}
                  color={isTodayWeek ? Theme.colors.mediumDarkGrey : Theme.colors.blue}
                />
              </IconContainer>
              <IconContainer
                onClick={() => {
                  if (!isLastWeek) {
                    setToday(dateUtils.addWeeks(today, 1))
                  }
                }}>
                <Icons
                  size={20}
                  name="chevron_right"
                  ariaLabel={i18n.t('screens.planning.iconsAction.seeNextWeek')}
                  color={isLastWeek ? Theme.colors.mediumDarkGrey : Theme.colors.blue}
                />
              </IconContainer>
            </ArrowsContainer>
          </WeekSwitchcontainer>

          {renderActionButtons()}

          {renderDays()}
        </TopContainer>

        <BottomContainer ref={formRef} tabIndex={-1}>
          {!isReserving ? (
            <ButtonContainer>
              <Button
                font={Theme.fonts.h3Bold}
                label={i18n.t('screens.planning.formTitle')}
                onClick={() => {
                  setIsReserving(true)
                  goToForm()
                }}
                shadow
                width={300}
              />
            </ButtonContainer>
          ) : isReservingSharvyParking && !!sharvyInfos ? (
            <PlanningReservationContainer>
              <ButtonContainer>
                <Title>{i18n.t('screens.planning.formTitle')}</Title>
              </ButtonContainer>
              <CardsContainer>
                <FormContainer>
                  <SharvyReservationForm
                    reservationDate={reservationDate}
                    setReservationDate={setReservationDate}
                    refreshData={() => {
                      refreshAll()
                      setIsReserving(false)
                      setIsReservingParkingZapfloor(false)
                      setIsReservingVamosParking(false)
                      setIsReservingSharvyParking(false)
                    }}
                    sharvyInfos={sharvyInfos}
                  />
                </FormContainer>
              </CardsContainer>
            </PlanningReservationContainer>
          ) : isReservingVamosParking && vamosUserInfos ? (
            <PlanningReservationContainer>
              <ButtonContainer>
                <Title>{i18n.t('screens.planning.formTitle')}</Title>
              </ButtonContainer>
              <CardsContainer>
                <FormContainer>
                  <VamosReservationForm
                    datesToAvoid={datesToAvoidVamos}
                    reservationDate={reservationDate}
                    setReservationDate={setReservationDate}
                    plate={vamosUserInfos.plate}
                    refreshData={() => {
                      refreshAll()
                      setIsReserving(false)
                      setIsReservingParkingZapfloor(false)
                      setIsReservingVamosParking(false)
                      setIsReservingSharvyParking(false)
                    }}
                    zapfloorBookings={bookings}
                    vamosParkingBookings={vamosParkingBookings}
                    poisInfos={zapfloorPois}
                  />
                </FormContainer>
              </CardsContainer>
            </PlanningReservationContainer>
          ) : (
            <PlanningReservationContainer>
              <ButtonContainer>
                <Title>{i18n.t('screens.planning.formTitle')}</Title>
              </ButtonContainer>
              <CardsContainer>
                <FormContainer>
                  <ReservationForm
                    setProfiles={setProfiles}
                    selectedTeam={selectedProfil}
                    setSelectedTeam={setSelectedProfil}
                    reservationDate={reservationDate}
                    setReservationDate={setReservationDate}
                    profiles={profiles}
                    selectedUser={selectedUser}
                    setSelectedUser={(user) => {
                      setSelectedUser(user)
                      setSelectedVisitor(undefined)
                    }}
                    datesToAvoid={selectedVisitor ? datesVisitorToAvoid : datesToAvoid}
                    setReservationTime={setSelectedReservatonTime}
                    selectedReservationTime={selectedReservationTime}
                    setIsProfilesLoading={setIsProfilesLoading}
                    zapfloorPois={zapfloorPois}
                    navigation={navigation}
                    parkingMode={isReservingParkingZapfloor}
                    setSelectedVisitor={(visitor) => {
                      setSelectedVisitor(visitor)
                      setSelectedUser(undefined)
                    }}
                    selectedVisitor={selectedVisitor}
                    visitorStatus={visitorStatus}
                    visits={visits}
                    minDate={minReservationDate}
                    maxDate={maxReservationDate}
                    disableVisitor={disableVisitor}
                    setReservationType={(type) => {
                      setIsReserving(type === 'DESK')
                      setIsReservingParkingZapfloor(type === 'PARKING')
                      goToForm()
                    }}
                    reservationType={isReservingParkingZapfloor ? 'PARKING' : 'DESK'}
                  />
                </FormContainer>

                {!!selectedProfil && !!userZapfloorId && (
                  <FormContainer>
                    <LocationSelectionForm
                      isProfilesLoading={isProfilesLoading}
                      reservationDate={reservationDate}
                      userId={(!!selectedUser && selectedUser.id) || userZapfloorId}
                      profil={selectedProfil}
                      selectedReservationTime={selectedReservationTime}
                      refreshData={() => {
                        fetchZapfloorBookings()
                        goToPlanning()
                        setSelectedVisitor(undefined)
                      }}
                      zapfloorPois={zapfloorPois}
                      teamId={selectedProfil.attributes.access_group_id}
                      parkingMode={isReservingParkingZapfloor}
                      visitorMode={!!selectedVisitor}
                      visitorInfos={visitorDeskInfos}
                      selectedVisitor={selectedVisitor}
                      visits={visits}
                    />
                  </FormContainer>
                )}
              </CardsContainer>
            </PlanningReservationContainer>
          )}
        </BottomContainer>
      </MainContainer>
    </>
  )
}
export default PlanningScreen

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
  overflow: hidden;
`
const TopContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px;
  width: calc(100% - 100px);
  min-height: calc(50vh - 30px);
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding: 20px;
    width: calc(100% - 40px);
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 10px;
    width: calc(100% - 20px);
    align-items: center;
  }
`

const MobileReservationSelector = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-left: 40px;
`

const ButtonMenu = styled.button`
  background-color: ${(props) => props.theme.colors.blue};
  width: 50px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  position: fixed;
  bottom: 30px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

const ReservationIconContainer = styled.button<{ selected: boolean }>`
  background-color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.white)};
  width: 50px;
  height: 50px;
  border-radius: 25px;
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WeekSwitchcontainer = styled.div<{ isMobile?: boolean }>`
  display: flex;
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  ${(props) => props.isMobile && 'justify-content: center;text-align: center;margin-left: 15px;'}
`

const DateContainer = styled.div`
  font-size: 18px;
  min-width: 280px;
  white-space: nowrap;
`

const ArrowsContainer = styled.div`
  display: flex;
`

const IconContainer = styled.button<{ isMobile?: boolean }>`
  ${(props) => !props.isMobile && 'margin-right: 15px;'}
  cursor: pointer;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 32px 0px;
  overflow-x: auto;
  max-width: calc(100vw);
`

const ButtonsContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const MenuButtonContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 10px;
`

const ButtonContainer = styled.div`
  height: 60px;
`

const WeekPlanning = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    justify-content: center;
  }
`

const FormContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  margin: 10px;
  padding: 20px;
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    justify-content: center;
  }
`

const BottomContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 25px 5% 40px;
  min-height: calc(50vh - 30px);
  width: 100%;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding-left: 20px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding-left: 10px;
    align-items: center;
  }
`

const PlanningReservationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: inherit;
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const Title = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
`

const ShortcutContainer = styled('div')`
  margin: 0px 25px;
  max-width: 125px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    max-width: 80px;
  }
`
const QRCodeContainer = styled('div')`
  width: 400px;
  max-width: calc(100vw - 40px);
`
