import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import useBreakpoint from 'utils/useBreakpoints'
import useNavigation from 'utils/navigation/useNavigation'

import graphApi from 'roombooking/api/graphRoomApi'
import { DirectoryAttendee, Attendee, AllTimes } from './types'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as roomStore from './roomStore'

import utils from './utils'
import dateUtils from 'utils/date'

import CustomDatePicker from 'components/picker/CustomDatePicker'
import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import TimePickerInput from 'components/picker/TimePickerInput'
import Button from 'components/button/Button'
import ValidationContent from 'components/modal/ValidationContent'
import AttendeesModal from './modal/AttendeesModal'
import InteractiveToast from 'components/toast/InteractiveToast'

import {
  isSameDay,
  differenceInMinutes,
  differenceInHours,
  addHours,
  addMinutes,
  subHours,
  subMinutes,
  min,
  max,
  setHours,
  setMinutes,
  addDays,
  isBefore,
  isAfter,
} from 'date-fns'

type TimePickerType = 'start' | 'end' | 'duration' | ''

const minutesInHour = 60

interface Props {
  roomInfo: RoomWithPlanning
  formTimes?: AllTimes
}

const INITIAL_DURATION: TimePickerDuration = { hours: 0, minutes: 30 }

const MAX_DAYS_IN_FUTURE = 7 * 4 // Réservation possible jusqu'à 4 semaines

const MIN_DURATION: TimePickerDuration = { hours: 0, minutes: 15 }
const MAX_DURATION: TimePickerDuration = { hours: 8, minutes: 0 }
const MAX_END = (d: Date) => setHours(setMinutes(d, 45), 23)

const BookingForm = ({ roomInfo, formTimes }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()
  const [, currentBreakpoint] = useBreakpoint()
  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)

  const now = dateUtils.roundToNextQuarter(new Date())
  const default_end = addMinutes(now, 30)

  const defaultFormTimes = {
    startDate: now,
    endDate: default_end,
    duration: INITIAL_DURATION,
    pList: [],
    meetingSubject: '',
    isOnlineMeeting: false,
  }

  const {
    startDate: initialStartDate,
    endDate: initialEndDate,
    duration: initialDuration,
    pList,
    meetingSubject,
    isOnlineMeeting,
  } = formTimes || defaultFormTimes

  const [startDate, setStartDate] = React.useState(initialStartDate)
  const [endDate, setEndDate] = React.useState(initialEndDate)

  const [duration, setDuration] = React.useState<TimePickerDuration>(initialDuration)
  const [participantsList, setParticipantsList] = React.useState<DirectoryAttendee[]>(pList)
  const [subject, setSubject] = React.useState(meetingSubject)
  const [currentTimePicker, setCurrentTimePicker] = React.useState<TimePickerType>('')
  const [datePickerToggle, setDatePickerToggle] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const isToday = isSameDay(startDate, new Date())

  const minEndDate = React.useMemo(() => addMinutes(startDate, MIN_DURATION.minutes), [startDate])

  const attendeesList = React.useMemo(
    () => [
      ...participantsList.map(
        (p) =>
          ({ type: 'required', emailAddress: { name: p.givenName + ' ' + p.surname, address: p.mail } } as Attendee)
      ),
    ],
    [participantsList]
  )

  // UseEffect pour update les différents time-picker

  React.useEffect(() => {
    if (currentTimePicker === 'start') {
      const newEndDate = min([MAX_END(startDate), addHours(addMinutes(startDate, duration.minutes), duration.hours)])
      setEndDate(newEndDate)
      const newDuration: TimePickerDuration = {
        hours: differenceInHours(newEndDate, startDate),
        minutes: differenceInMinutes(newEndDate, startDate) % minutesInHour,
      }
      setDuration(newDuration)
    }
  }, [startDate])

  React.useEffect(() => {
    if (currentTimePicker === 'end') {
      const newStartDate = min([
        max([now, startDate, subHours(subMinutes(endDate, MAX_DURATION.minutes), MAX_DURATION.hours)]),
        endDate,
      ])
      const newDuration: TimePickerDuration = {
        hours: differenceInHours(endDate, newStartDate),
        minutes: differenceInMinutes(endDate, newStartDate) % minutesInHour,
      }
      setStartDate(newStartDate)
      setDuration(newDuration)
    }
  }, [endDate])

  React.useEffect(() => {
    if (currentTimePicker === 'duration') {
      setEndDate(addMinutes(addHours(startDate, duration.hours), duration.minutes))
    }
  }, [duration])

  // Les fonctions pour la réservation

  const openModal = (title: string, showCancel: boolean, onConfirm?: () => void, confirmTitle?: string) => {
    Modal.open(() => (
      <ValidationContent
        title={title}
        onConfirm={() => {
          Modal.close()
          if (!!onConfirm) {
            onConfirm()
          }
        }}
        cancelButton={showCancel}
        confirmButtonTitle={confirmTitle}
        font={Theme.fonts.label}
      />
    ))
  }

  const confirmReservation = (
    name: string,
    o365Id: string,
    start: string,
    end: string,
    attendees: Attendee[],
    subject: string
  ) => {
    const bookingStart = new Date(start)
    const bookingEnd = new Date(end)

    const alreadyBooked = !!roomInfo.reservations.find(
      (r) =>
        isSameDay(new Date(r.from), bookingStart) &&
        ((isAfter(bookingStart, new Date(r.from)) && isBefore(bookingStart, new Date(r.to))) ||
          (isAfter(bookingEnd, new Date(r.from)) && isBefore(bookingEnd, new Date(r.to))))
    )

    if (!alreadyBooked) {
      setIsLoading(true)
      makeReservation(name, o365Id, start, end, attendees, subject)
    } else {
      openModal(i18n.t('screens.room.alreadyBooked'), false)
    }
  }

  const makeReservation = (
    name: string,
    o365Id: string,
    start: string,
    end: string,
    attendees: Attendee[],
    subject: string
  ) => {
    if (user && site) {
      graphApi
        .bookRoom(user.type, {
          location: { displayName: o365Id, locationEmailAddress: o365Id },
          subject: subject || i18n.t('screens.room.defaultSubject'),
          start: { dateTime: start, timeZone: site.timezone },
          end: { dateTime: end, timeZone: site.timezone },
          attendees: [{ type: 'required', emailAddress: { name: name, address: o365Id } }, ...attendees],
          isOnlineMeeting: true,
        })
        .then((res) => {
          InteractiveToast.close()
          roomStore.actions.setGraphIdBooking(res.id)
          roomStore.actions.setGraphEmailBooking(o365Id)
          roomStore.actions.setFormTimes({
            duration,
            endDate,
            startDate,
            isOnlineMeeting,
            meetingSubject: subject,
            pList: participantsList,
          })
          InteractiveToast.open(
            i18n.t('screens.room.toast.waitingTitle'),
            i18n.t('screens.room.toast.waitingDescription'),
            'info-circle',
            Theme.colors.toastInfoText,
            Theme.colors.toastInfoBackground,
            currentBreakpoint === 'phone' ? 'bottom' : 'top-right',
            navigation
          )
        })
        .catch((err) => {
          Logger.error(err)
          openModal(
            i18n.t('screens.room.reservationFailed'),
            true,
            () => makeReservation(name, o365Id, start, end, attendees, subject),
            i18n.t('common.retry')
          )
        })
        .finally(() => setIsLoading(false))
    }
  }

  const openDatePicker = () => setDatePickerToggle(true)

  const openAttendeesModal = () => {
    Modal.open(() => <AttendeesModal attendees={participantsList} setAttendees={setParticipantsList} />)
  }

  const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick()
      e.preventDefault()
    }
  }

  // Les fonctions render

  const renderTimeInput = (
    value: TimePickerDuration,
    onchange: (val: TimePickerDuration) => void,
    timePickerType: TimePickerType,
    ariaLabel: string,
    title?: string,
    maxDuration?: TimePickerDuration, // in minutes
    minDuration?: TimePickerDuration, // in minutes
    isDuration?: boolean
  ) => {
    const openPicker = () =>
      Modal.open(() => {
        setCurrentTimePicker(timePickerType)
        return (
          <TimePickerInput
            value={value}
            onChange={onchange}
            close={Modal.close}
            title={title}
            maxDuration={maxDuration}
            minDuration={minDuration}
          />
        )
      })

    return (
      <TimeInput onClick={openPicker} aria-label={ariaLabel}>
        {isDuration
          ? utils.durationDisplay(i18n, value.hours, value.minutes)
          : i18n.t('screens.room.reservationTime', {
              hours: value.hours.toLocaleString(undefined, { minimumIntegerDigits: 2 }),
              minutes: value.minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 }),
            })}
      </TimeInput>
    )
  }

  return (
    <MainContainer>
      <CenterContainer>
        <BookingTitle>{i18n.t('screens.room.details.bookRoom')}</BookingTitle>
      </CenterContainer>

      <InputCategoryContainer>
        <InputCategoryTitle htmlFor="RoomBookingDate">{i18n.t('screens.room.form.date')}</InputCategoryTitle>

        <PointerContainer>
          <SearchContentChevronContainer
            id="RoomBookingDate"
            onClick={openDatePicker}
            tabIndex={0}
            onKeyDown={(e) => onEnterKey(e, openDatePicker)}>
            <VisitDate>
              {i18n.t(`screens.room.${isToday ? 'today' : 'reservationDate'}`, { date: startDate })}
            </VisitDate>
            <Icons name="chevron_right" size={15} color={Theme.colors.darkGrey} />
          </SearchContentChevronContainer>
          {datePickerToggle && (
            <CustomDatePicker
              selectedDate={startDate}
              onChange={setStartDate}
              minDate={now}
              maxDate={addDays(now, site?.nbDaysPlanning || MAX_DAYS_IN_FUTURE)}
              closePicker={() => setDatePickerToggle(false)}
            />
          )}
        </PointerContainer>
      </InputCategoryContainer>

      <BlueLineBreak role="presentation" />

      <InputCategoryContainer>
        <InputCategoryTitle htmlFor="RoomBookingStartTime">{i18n.t('screens.room.form.start')}</InputCategoryTitle>
        <PointerContainer id="RoomBookingStartTime">
          {renderTimeInput(
            { hours: startDate.getHours(), minutes: startDate.getMinutes() },
            (val) => setStartDate(setMinutes(setHours(startDate, val.hours), val.minutes)),
            'start',
            i18n.t('label.ariaLabel.room.chooseStartTime'),
            i18n.t('screens.room.startingTime'),
            { hours: 23, minutes: 30 },
            isToday ? { hours: now.getHours(), minutes: now.getMinutes() } : { hours: 0, minutes: 0 }
          )}
        </PointerContainer>
      </InputCategoryContainer>

      <BlueLineBreak role="presentation" />

      <InputCategoryContainer>
        <InputCategoryTitle htmlFor="RoomBookingEndTime">{i18n.t('screens.room.form.end')}</InputCategoryTitle>
        <PointerContainer id="RoomBookingEndTime">
          {renderTimeInput(
            { hours: endDate.getHours(), minutes: endDate.getMinutes() },
            (val) => setEndDate(setMinutes(setHours(endDate, val.hours), val.minutes)),
            'end',
            i18n.t('label.ariaLabel.room.chooseEndTime'),
            i18n.t('screens.room.endingTime'),
            undefined,
            { hours: minEndDate.getHours(), minutes: minEndDate.getMinutes() }
          )}
        </PointerContainer>
      </InputCategoryContainer>

      <BlueLineBreak role="presentation" />

      <InputCategoryContainer>
        <InputCategoryTitle htmlFor="RoomBookingSubject">{i18n.t('screens.room.form.subject')}</InputCategoryTitle>
        <InputField
          id="RoomBookingSubject"
          value={subject}
          placeholder={i18n.t('screens.room.meetingSubject')}
          onChange={(e) => setSubject(e.target.value)}
        />
      </InputCategoryContainer>

      <BlueLineBreak role="presentation" />

      <InputCategoryContainer>
        <InputCategoryTitle htmlFor="RoomBookingParticipants">
          {i18n.t(`screens.room.form.participants${site?.id === '4' ? 'AndResources' : ''}`)}
        </InputCategoryTitle>
        <PointerContainer>
          <SearchContentChevronContainer id="RoomBookingParticipants" onClick={openAttendeesModal}>
            <CapacityText>{participantsList.length}</CapacityText>
            <Icons name="chevron_right" size={15} color={Theme.colors.darkGrey} />
          </SearchContentChevronContainer>
        </PointerContainer>
      </InputCategoryContainer>

      <CenterContainer>
        <ButtonContainer>
          <Button
            label={i18n.t('screens.room.details.confirmBooking')}
            onClick={() => {
              if (roomInfo.office365Id) {
                confirmReservation(
                  roomInfo.name,
                  roomInfo.office365Id,
                  addMinutes(startDate, -new Date().getTimezoneOffset()).toISOString().replace('Z', ''),
                  addMinutes(endDate, -new Date().getTimezoneOffset()).toISOString().replace('Z', ''),
                  attendeesList,
                  subject
                )
              }
            }}
            verticalPadding={14}
            font={Theme.fonts.h3Bold}
            loading={isLoading}
            disabled={isLoading}
          />
        </ButtonContainer>
      </CenterContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const InputCategoryContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: 20px 0px;
`
const SearchContentChevronContainer = styled('button')`
  display: flex;
  align-items: center;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const PointerContainer = styled('div')`
  display: flex;
`
const CenterContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ButtonContainer = styled('div')`
  display: flex;
  margin-bottom: 10px;
  width: 100%;
`

// TEXTES

const BookingTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
`
const InputCategoryTitle = styled('label')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const VisitDate = styled('p')`
  text-transform: capitalize;
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
`
const TimeInput = styled('p')`
  cursor: pointer;
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const CapacityText = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
`
const InputField = styled('input')`
  width: 80%;
  border: 0;
  outline: 0;
  ${(props) => props.theme.fonts.bodyBold}
  color: ${(props) => props.theme.colors.blue};
  background: transparent;
  text-align: right;
  padding: 0px;
  ::placeholder {
    font-weight: 300;
  }
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  border-radius: 2px;
`

export default BookingForm
