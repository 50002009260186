import screens from 'core/src/layout/Screens'

import analytics, { analyticsKeys } from 'utils/analytics'
import { I18n } from 'i18n/types'

import * as iframeStore from 'iframe/iframeStore'

import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'

const getScreenNameFromFeatureType = (featureType: string) => {
  switch (featureType) {
    case 'SITE':
      return 'PLANNING'
    case 'PARKING_COMMUTY':
      return 'parking'
    case 'SHARVY_PARKING':
      return 'sharvyParking'
    default:
      return featureType
  }
}

const processThirdPartyApp = (navigation: Navigation, features: Feature[], siteStore: SiteState, user?: UserSelf) =>
  features.reduce((acc, cur) => {
    acc.push({
      id: cur.id,
      name: cur.name,
      icon: cur.icon as IconName,
      position: cur.position,
      onClick:
        cur.webUrlType === 'EXTERNAL'
          ? () => {
              analytics.track({
                screenName: analyticsKeys.thirdPartyApp,
                userType: user?.type || 'EXTERNAL',
                currentSite: siteStore.site,
                mySites: siteStore.mySites,
                label: cur.webUrl,
                thirdPartyLabel: cur.name,
              })
              window.open(cur.webUrl)
            }
          : cur.webUrlType === 'INTERNAL'
          ? () => {
              analytics.track({
                screenName: analyticsKeys.thirdPartyApp,
                userType: user?.type || 'EXTERNAL',
                currentSite: siteStore.site,
                mySites: siteStore.mySites,
                label: cur.webUrl,
                thirdPartyLabel: cur.name,
              })
              window.location.replace(cur.webUrl || '')
            }
          : () => {
              iframeStore.actions.setIFrameLink(cur.webUrl || '')
              iframeStore.actions.setCurrentIFrame(cur.name)
              analytics.track({
                screenName: analyticsKeys.thirdPartyApp,
                userType: user?.type || 'EXTERNAL',
                currentSite: siteStore.site,
                mySites: siteStore.mySites,
                label: cur.webUrl,
                thirdPartyLabel: cur.name,
              })
              navigation.push('/iframe')
            },
      shortcut: cur.shortcutByDefault,
      url: cur.webUrl,
      disabledTime: !!cur.webUrl && cur.webUrl.includes('&disabled=') ? cur.webUrl.split('&disabled=')[1] : undefined,
    })
    return acc
  }, [] as ProcessedFeatures[])

const processDrawerFeature = (navigation: Navigation, features: Feature[], origin?: string) => {
  const featuresTypes = features.map((f: Feature) => getScreenNameFromFeatureType(f.type))
  const featuredItems: ScreenDefinition[] = Object.keys(screens)
    .map((key) => screens[key])
    .filter((screen) => screen.drawer && featuresTypes.indexOf(screen.name) !== -1)

  return features.reduce((acc, cur) => {
    const correspondingItem = featuredItems.find((s) => s.name === getScreenNameFromFeatureType(cur.type))
    if (correspondingItem) {
      acc.push({
        name: correspondingItem.name,
        icon: correspondingItem.icon as IconName,
        position: cur.position,
        onClick: () =>
          navigation.push(correspondingItem.path, {
            origin,
            openModal: cur.type === 'INCIDENT' && origin === 'raccourcis',
          }),
        shortcut: cur.shortcutByDefault,
        path: correspondingItem.path,
      })
    }
    return acc
  }, [] as ProcessedFeatures[])
}

const processParkingFeature = (feature: Feature, siteStore: SiteState, i18n: I18n, parkingURL?: string) => {
  if (feature) {
    return [
      {
        id: feature.id,
        name: feature.name,
        icon: 'parking',
        position: feature.position,
        onClick: () =>
          siteStore.site?.id === '1'
            ? window.open(parkingURL)
            : Modal.open(() =>
                ValidationContent({ title: i18n.t('errors.parking.unavailable'), onConfirm: () => Modal.close() })
              ),
        shortcut: feature.shortcutByDefault,
      },
    ] as ProcessedFeatures[]
  } else {
    return []
  }
}

const processOtherServices = (navigation: Navigation) => {
  return [
    {
      id: '0',
      name: 'other_services',
      icon: 'burger-menu',
      position: 100,
      onClick: () => navigation.push('/otherServices'),
      shortcut: false,
      path: '/otherServices',
    },
  ] as ProcessedFeatures[]
}

const processFeatures = (
  navigation: Navigation,
  features: Feature[],
  siteStore: SiteState,
  i18n: I18n,
  user?: UserSelf,
  parkingURL?: string,
  origin?: 'drawer' | 'raccourcis'
) => {
  // Si venue sur site et parking sharvy activé, ne pas afficher parking sharvy
  const hideSharvy = !!features.find((f) => f.type === 'SHARVY_PARKING') && !!features.find((f) => f.type === 'SITE')
  const filteredFeatures =
    origin === 'drawer' ? features.filter((f) => f.presentLeftMenu) : features.filter((f) => f.presentListShortcut)
  const itemsName: ScreenName[] = Object.keys(screens)
    .map((key) => screens[key])
    .filter((screen) => screen.drawer)
    .filter((screen) => (hideSharvy ? screen.name !== 'sharvyParking' : true))
    .map((s) => s.name)

  const displayOtherServices =
    origin === 'drawer' && features.find((f) => f.presentOtherService && f.type !== 'DOMOTIC' && f.type !== 'RELEASE')

  const thirdPartyFeatures = filteredFeatures.filter((f) => f.type === 'THIRD_PART' && !!f.webUrl)
  const mpcFeatures = filteredFeatures.filter(
    (f) => itemsName.indexOf(getScreenNameFromFeatureType(f.type) as ScreenName) !== -1
  )
  const parkingFeature = filteredFeatures.find((f) => f.type === 'PARKING')

  const allProcessedFeatures = [
    ...processDrawerFeature(navigation, mpcFeatures, origin),
    ...processThirdPartyApp(navigation, thirdPartyFeatures, siteStore, user),
    ...(!!parkingFeature ? processParkingFeature(parkingFeature, siteStore, i18n, parkingURL) : []),
    ...(displayOtherServices ? processOtherServices(navigation) : []),
  ]

  return allProcessedFeatures
}

export { processThirdPartyApp, processDrawerFeature, processFeatures, getScreenNameFromFeatureType }
