import { subHours, isAfter, parseISO } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

export const todayVisitCanBeModified = (visit: PersonalVisit, timeZone: string) => {
  if (!visit.arrivalTime) {
    return false
  }
  const arrivalDateTimeString = `${visit.day}T${visit.arrivalTime}`
  const arrivalDateTime = parseISO(arrivalDateTimeString)

  const arrivalDateTimeUTC = zonedTimeToUtc(arrivalDateTime, timeZone)

  const oneHourBeforeArrivalUTC = subHours(arrivalDateTimeUTC, 1)

  const currentDateUTC = new Date()

  return !isAfter(currentDateUTC, oneHourBeforeArrivalUTC)
}
