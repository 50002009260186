import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import api from '../api/restaurantsApi'
import accountApi from 'account/accountApi'

import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import Logger from 'utils/Logger'
import analytics, { analyticsKeys } from 'utils/analytics'
import useNavigation from 'utils/navigation/useNavigation'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import Modal from 'components/modal/Modal'
import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'
import MyMRSReloadModal from './MyMRSReloadModal'
import MyMRSReceiptPdf from './MyMRSReceiptPdf'

import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'
import Loader from 'react-loader-spinner'
import { useLocation } from 'react-router-dom'

type Status = 'loading' | 'error' | 'success'

const MyMRSScreen = () => {
  const [theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()

  const query = new URLSearchParams(useLocation().search)
  const reloadParam = query.get('reload')

  const user = useReducer(userStore.store, (s) => s.user)

  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const badges = useReducer(userStore.store, (s) => s.badges)
  const lastBadgesRefresh = useReducer(userStore.store, (s) => s.lastBadgesRefresh)

  const currentBadge = React.useMemo(
    () => (!!currentSite ? badges.find((b) => b.siteId === currentSite.id) : undefined),
    [currentSite, badges]
  )
  const solde = React.useMemo(() => currentBadge?.solde, [currentBadge])

  const [history, setHistory] = React.useState<MrsTransaction[]>([])
  const [fetchHistoryStatus, setFetchHistoryStatus] = React.useState<Status>('loading')
  const [fetchBadgesStatus, setFetchBadgesStatus] = React.useState<Status>('loading')

  React.useEffect(() => {
    if (reloadParam) {
      openReloadModal()
    }
  }, [reloadParam])

  React.useEffect(() => {
    fetchHistory()
    fetchBadges()
  }, [user, currentSite])

  React.useEffect(() => {
    if (currentSite && user) {
      analytics.track({ screenName: analyticsKeys.myMRS, userType: user.type, currentSite, mySites })
    }
  }, [])

  Object.getOwnPropertyNames(languages).forEach((lang) => {
    numbro.registerLanguage(languages[lang])
  })
  numbro.setLanguage('fr-FR')

  const fetchBadges = () => {
    if (user && currentSite) {
      setFetchBadgesStatus('loading')
      accountApi
        .getBadges(user.type)
        .then((result) => {
          userStore.actions.setBadges(result.badges)
          setFetchBadgesStatus('success')
        })
        .catch((err) => {
          Logger.error(err)
          setFetchBadgesStatus('error')
        })
    }
  }

  const fetchHistory = () => {
    if (user && currentSite) {
      setFetchHistoryStatus('loading')
      api
        .getMrsHistory(user.type, currentSite.id)
        .then((res) => {
          setFetchHistoryStatus('success')
          setHistory(res.entries)
        })
        .catch((err) => {
          setFetchHistoryStatus('error')
          Logger.error(err)
        })
    }
  }

  const openPdfReceipt = (item: MrsTransaction) => {
    Modal.open(() => <MyMRSReceiptPdf item={item} close={Modal.close} />)
  }

  const renderHistoryLine = (item: MrsTransaction, index: number, array: MrsTransaction[]) => {
    const statusConfirmed = item.typeKeyValue !== 'RELOAD_PENDING' && item.typeKeyValue !== 'RELOAD_REJECTED'
    return (
      <React.Fragment>
        <HistoryLineContainer>
          <HistoryLineInfosContainer>
            <HistoryLineTitle>
              {i18n.t([`screens.mymrs.label.${item.type}`, 'screens.mymrs.label.default'])}
            </HistoryLineTitle>
            <HistoryLineDate>{i18n.t('screens.mymrs.historyLineDate', { date: new Date(item.date) })}</HistoryLineDate>
            {!statusConfirmed && (
              <HistoryLineStatus>
                <HistoryLineStatusDot />
                <HistoryLineStatusText>{i18n.t(`screens.mymrs.status.${item.typeKeyValue}`)}</HistoryLineStatusText>
              </HistoryLineStatus>
            )}
            {item.typeKeyValue === 'TICKET' && (
              <Button
                label={i18n.t('screens.mymrs.showTicket')}
                onClick={() => openPdfReceipt(item)}
                verticalPadding={5}
                horizontalPadding={10}
              />
            )}
          </HistoryLineInfosContainer>
          <HistoryLineAmount
            color={
              statusConfirmed
                ? Number(item.amount) >= 0
                  ? theme.colors.turquoise
                  : theme.colors.red
                : theme.colors.middleGrey
            }>
            {numbro(item.amount).formatCurrency({ mantissa: 2, forceSign: true })}
          </HistoryLineAmount>
        </HistoryLineContainer>
        {index !== array.length - 1 && <Separator role="presentation" />}
      </React.Fragment>
    )
  }

  const openReloadModal = () => Modal.open(() => <MyMRSReloadModal closeModal={Modal.close} />)

  return (
    <>
      <TitleHelmet title={i18n.t('screens.account.title')} />
      <Breadcrumb screen="catering" path="catering" details={i18n.t('screens.mymrs.title')} navigation={navigation} />
      <MainContainer>
        <TopContainer>
          <Title>{i18n.t('screens.catering.services.solde')}</Title>
          {fetchBadgesStatus === 'loading' ? (
            <LoaderContainer>
              <Loader type="TailSpin" color={theme.colors.blue} height={40} width={40} />
            </LoaderContainer>
          ) : fetchBadgesStatus === 'error' || solde === undefined || solde === null ? (
            <LoaderContainer>
              <ErrorText>{i18n.t('screens.mymrs.errorBadge')}</ErrorText>
            </LoaderContainer>
          ) : (
            <SoldeContainer>
              <Icon name="restaurant" color={theme.colors.blue} size={30} />
              <SoldeInfosContainer>
                <SoldeValue>{numbro(solde).formatCurrency({ mantissa: 2 })}</SoldeValue>
                <SoldeLabel>
                  {i18n.t('screens.catering.services.lastSoldeRefresh', {
                    date: lastBadgesRefresh ? new Date(lastBadgesRefresh) : new Date(),
                  })}
                </SoldeLabel>
              </SoldeInfosContainer>
            </SoldeContainer>
          )}
          <ReloadButtonContainer>
            <Button
              label={i18n.t('screens.mymrs.reloadAccount')}
              font={theme.fonts.bodyBold}
              disabled={fetchBadgesStatus !== 'success' || solde === undefined || solde === null}
              onClick={openReloadModal}
              width={300}
            />
          </ReloadButtonContainer>
        </TopContainer>
        <BottomContainer>
          <HistoryTitle>{i18n.t('screens.mymrs.history')}</HistoryTitle>
          {fetchHistoryStatus === 'loading' ? (
            <LoaderContainer>
              <Loader type="TailSpin" color={theme.colors.blue} height={40} width={40} />
            </LoaderContainer>
          ) : fetchHistoryStatus === 'error' || history.length === 0 ? (
            <LoaderContainer>
              <ErrorText>
                {i18n.t(`screens.mymrs.${fetchHistoryStatus === 'error' ? 'error' : 'empty'}History`)}
              </ErrorText>
            </LoaderContainer>
          ) : (
            <HistoryContainer>{history.map(renderHistoryLine)}</HistoryContainer>
          )}
        </BottomContainer>
      </MainContainer>
    </>
  )
}

export default MyMRSScreen

const MainContainer = styled('div')`
  min-height: calc(100vh - 165px);
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
`

const TopContainer = styled('div')`
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  gap: 40px 0px;
  background-color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    padding: 30px 60px;
    gap: 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-left: 20px 24px;
    gap: 20px;
  }
`

const Title = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 0px;
`

const SoldeContainer = styled('div')`
  display: flex;
  gap: 0px 20px;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    gap: 0px 10px;
  }
`

const SoldeInfosContainer = styled('div')``

const SoldeValue = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 0px;
`

const SoldeLabel = styled('label')`
  ${(props) => props.theme.fonts.label};
`

const ReloadButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  max-width: 600px;
`

const BottomContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 50px 100px;
  gap: 20px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    padding: 30px 60px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-left: 20px 24px;
    gap: 10px;
  }
`

const HistoryTitle = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 0px;
`

const HistoryContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  gap: 20px 0px;
  flex: 1;
  max-width: 645px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: auto;
    flex: 1;
  }
`

const HistoryLineContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const HistoryLineInfosContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
`

const HistoryLineTitle = styled('h4')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0px;
`

const HistoryLineDate = styled('label')`
  ${(props) => props.theme.fonts.label};
`

const HistoryLineAmount = styled('text')<{ color: string }>`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.color};
`

const LoaderContainer = styled('div')`
  padding: 40px;
  display: flex;
  flex: 1;
`

const Separator = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  border-radius: 2px;
`

const ErrorText = styled('label')`
  ${(props) => props.theme.fonts.body};
`

const HistoryLineStatus = styled('div')`
  display: flex;
`

const HistoryLineStatusDot = styled('div')`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.orange};
  margin: 5px;
`

const HistoryLineStatusText = styled('label')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.orange};
`
