import sanitize from 'utils/Sanitizer'

const filterUserList = (userList: UserResponse[], searchText: string) =>
  userList
    .filter((user) => {
      const { firstname, lastname, email } = user.attributes
      return (
        firstname.toLowerCase().includes(searchText.toLowerCase()) ||
        lastname.toLowerCase().includes(searchText.toLowerCase()) ||
        email.toLowerCase().includes(searchText.toLowerCase())
      )
    })
    .sort((a, b) => a.attributes.lastname.localeCompare(b.attributes.lastname))

const filterVisitorList = (visitorList: PersonalVisit[], searchText: string) => {
  const sanitizeSearch = sanitize(searchText)
  return visitorList
    .filter(
      (visit) =>
        sanitize(visit.visitorFirstName).includes(sanitizeSearch) ||
        sanitize(visit.visitorLastName).includes(sanitizeSearch) ||
        sanitize(`${visit.visitorFirstName} ${visit.visitorLastName}`).includes(sanitizeSearch) ||
        sanitize(`${visit.visitorLastName} ${visit.visitorFirstName}`).includes(sanitizeSearch)
    )
    .sort((a, b) => a.visitorLastName.localeCompare(b.visitorLastName))
}

const filterPeopleListAndSortByFav = (peopleList: People[], searchText: string, favorites: string[]) =>
  peopleList
    .filter((people) => {
      const { firstname, lastname, email } = people
      return (
        firstname.toLowerCase().includes(searchText.toLowerCase()) ||
        lastname.toLowerCase().includes(searchText.toLowerCase()) ||
        email.toLowerCase().includes(searchText.toLowerCase())
      )
    })
    .sort((a, b) =>
      favorites.includes(a.id)
        ? favorites.includes(b.id)
          ? a.lastname.localeCompare(b.lastname)
          : -1
        : favorites.includes(b.id)
        ? 1
        : a.lastname.localeCompare(b.lastname)
    )

export { filterUserList, filterPeopleListAndSortByFav, filterVisitorList }
