import { createEvent, createStore } from 'effector'

export const actions = {
  setBanners: createEvent<Banner[]>('setBanners'),
}

interface BannersState {
  banners: Banner[]
}

const initialState: BannersState = {
  banners: [],
}

export const store = createStore<BannersState>(initialState, { name: 'Banners' }).on(
  actions.setBanners,
  (s, banners) => ({ ...s, banners })
)
