import * as React from 'react'

import './react-datepicker.css'
import useI18n from 'i18n/useI18n'
import { locales } from 'i18n/i18n'

import useReducer from 'store/useReducer'
import * as ThemeStore from 'theme/store'

import DatePicker from 'react-datepicker'
import FocusLock from 'react-focus-lock'

import { eachDayOfInterval } from 'date-fns'

interface Props {
  selectedDate: Date
  onChange: (d: Date) => void
  customInput?: JSX.Element
  minDate?: Date
  maxDate?: Date
  closePicker: () => void
  filterDate?: (d: Date) => boolean
  excludeDates?: Date[]
  excludeDays?: number[]
}

const CustomDatePicker = ({
  selectedDate,
  onChange,
  customInput,
  minDate,
  maxDate,
  closePicker,
  filterDate,
  excludeDates,
  excludeDays,
}: Props) => {
  const i18n = useI18n()
  const theme = useReducer(ThemeStore.store, (s) => s.theme)

  const onKeyDown: React.KeyboardEventHandler = (evt) => {
    if (evt.key === 'Escape') {
      closePicker()
    }
  }

  const allExcludedDays = React.useMemo(() => {
    if (!!excludeDays && !!minDate && !!maxDate) {
      const allDays = eachDayOfInterval({ start: minDate, end: maxDate })
      return allDays.filter((d) => excludeDays.includes(d.getDay()))
    }
    return []
  }, [excludeDays, minDate, maxDate])

  return (
    <FocusLock autoFocus={true} returnFocus>
      <DatePicker
        onKeyDown={onKeyDown}
        selected={selectedDate}
        onChange={onChange}
        customInput={customInput}
        minDate={minDate}
        maxDate={maxDate}
        locale={locales[i18n.lang]}
        excludeDates={[...(excludeDates || []), ...allExcludedDays]}
        withPortal
        inline
        onSelect={closePicker}
        preventOpenOnFocus
        tabIndex={-1}
        filterDate={filterDate}
        onClickOutside={closePicker}
        calendarClassName={theme === 'accessible' ? 'contrast' : undefined}
      />
    </FocusLock>
  )
}

export default CustomDatePicker
