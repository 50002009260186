import * as React from 'react'
import useI18n from 'i18n/useI18n'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Logger from 'utils/Logger'

import * as userStore from 'store/user/user'
import useReducer from 'store/useReducer'

import api from './accountApi'

import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import Toast from 'components/toast/Toast'
import ToastContent from 'components/toast/ToastContent'

const AlertContent = (
  passwordBody: PasswordUpdateForm,
  CloseModal: () => void,
  setPasswordError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const user = useReducer(userStore.store, (s) => s.user)

  const onConfirm = () => {
    if (user) {
      api
        .updatePassword(user.type, passwordBody)
        .then(() => {
          Alert.close()
          CloseModal()
          setPasswordError(false)
          Toast.open(() => ToastContent(i18n.t('screens.login.passwordChanged'), Theme.colors.darkGrey))
        })
        .catch((err) => {
          Logger.error(err)
          Alert.close()
          setPasswordError(true)
          Toast.open(() => ToastContent(i18n.t('screens.account.wrongPassword'), Theme.colors.darkGrey), 'top')
        })
    }
  }

  return (
    <ContentModal>
      <TitleModal>{i18n.t('screens.account.confirmChange')}</TitleModal>
      <ButtonContainer>
        <Button
          label={i18n.t('common.ok')}
          onClick={onConfirm}
          color={Theme.colors.blue}
          textColor={Theme.colors.white}
          font={Theme.fonts.h3Bold}
          ariaLabel={i18n.t('label.ariaLabel.confirmPasswordChange')}
        />
      </ButtonContainer>
      <ButtonContainer>
        <Button
          label={i18n.t('common.cancel')}
          onClick={Alert.close}
          color={Theme.colors.white}
          textColor={Theme.colors.blue}
          shadow
          font={Theme.fonts.h3Bold}
          ariaLabel={i18n.t('label.ariaLabel.cancelPasswordChange')}
        />
      </ButtonContainer>
    </ContentModal>
  )
}

export default AlertContent

// CONTAINERS

const ContentModal = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px;
  margin-top: 25px;
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 330px;
  margin-top: 10px;
`

// TEXTS

const TitleModal = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  width: 350px;
`
